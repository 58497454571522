import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Drawer,
  Card,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import logoutimg from "../../assets/images/pages/logout.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logosm from "../../assets/images/home/logo-lg.png";
import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { GoChevronRight } from "react-icons/go";
import { IoMdMenu } from "react-icons/io";
import { IoChatbubblesOutline, IoClose } from "react-icons/io5";
import { getNotificationListApi, logoutApi } from "../../services/ApiServices";
import { IMAGE_URL } from "../../services/Api";
import noImg from "../../assets/images/no-image.png";
import { messaging, onMessage } from "../../firebase";
import Popover from "../Popover/Popover";
import { ThreeDots } from "react-loader-spinner";
import { Bell } from "lucide-react";
import ReactTimeAgo from "react-time-ago";
import InfiniteScroll from "react-infinite-scroll-component";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { Socket } from "../../Socket/Socket";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
const chat_details = JSON.parse(localStorage.getItem("user_chat_info"));

const handleLeftroom = () => {
  console.log("chat_details >>>>", chat_details);

  if (chat_details) {
    Socket.emit("left_room", {
      chat_id: chat_details.chat_id,
      user_id: chat_details.user_id,
    });
  }
  localStorage.removeItem("user_chat_info");
};

const ProfileMenu = (props) => {
  const token = localStorage.getItem("user_token");

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const openDeleteModal = (e) => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (e) => {
    setShowDeleteModal(false);
  };

  const handleLogOut = () => {
    setLoader(true);
    if (token) {
      setLoader(true);
      logoutApi()
        .then((res) => {
          navigate("/signin");
          localStorage.removeItem("user_token");
          localStorage.removeItem("user_info");
          localStorage.removeItem("is_user_verification");
          localStorage.removeItem("user_chat_info");
          setLoader(false);
        })
        .catch((err) => {
          console.log("err >>>", err);

          const status = err?.response?.status;
          if (status === 401) {
            navigate("/signin");
            localStorage.removeItem("user_token");
            localStorage.removeItem("user_info");
            localStorage.removeItem("is_user_verification");
            localStorage.removeItem("user_chat_info");
            setLoader(false);
          }
        });
    } else {
      setLoader(false);
      navigate("/signin");
      localStorage.removeItem("user_token");
      localStorage.removeItem("user_info");
      localStorage.removeItem("is_user_verification");
      localStorage.removeItem("fixer_chat_info");
    }
  };

  return (
    <>
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button
            variant="text"
            color="blue-gray"
            className="mx-1 flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto hover:bg-none active:bg-white hover:bg-white outline-none"
            onClick={(e) => handleLeftroom()}
          >
            <Avatar
              variant="circular"
              size="sm"
              alt="tania andrew"
              className="border border-gray-900 p-0.5"
              crossOrigin="anonymous"
              src={
                props.user_info.profilePic
                  ? IMAGE_URL + props.user_info.profilePic
                  : noImg
              }
            />
          </Button>
        </MenuHandler>
        <MenuList className="p-1">
          <MenuItem
            onClick={(e) => {
              navigate("/profile");
            }}
            className={`py-3 px-4 flex items-center gap-2 rounded justify-between items-center text-primary font-medium hover:text-primary`}
          >
            <Typography as="span" variant="small" className="font-normal">
              My Profile
            </Typography>
            <GoChevronRight className="" />
          </MenuItem>
          <MenuItem
            onClick={(e) => openDeleteModal()}
            className={`py-3 px-4 flex items-center gap-2 rounded items-center text-red-700`}
          >
            <img src={logoutimg} alt="" className="w-5 h-5" />
            <Typography
              as="span"
              variant="small"
              className="font-normal text text-red-700"
            >
              Log Out
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>

      <Transition appear show={showDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => closeDeleteModal(e)}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="rounded-none w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <div className="py-3">
                    <div className="py-2">
                      <h3 className="text-center text-2xl font-semibold leading-6 text-gray-900">
                        Log Out?
                      </h3>
                      <Typography className="text-center py-2 text-sm font-normal text-[#7E7E7E]">
                        Are you certain you wish to proceed with logging out?
                      </Typography>
                    </div>
                  </div>
                  <div className="flex justify-center py-3">
                    <Button
                      type="button"
                      className="capitalize inline-flex justify-center rounded-xl px-8 py-3 text-sm font-normal focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={(e) => closeDeleteModal(e)}
                      variant="text"
                    >
                      Close
                    </Button>
                    <Button
                      type="button"
                      className="ml-3 capitalize inline-flex justify-center rounded-xl border border-transparent bg-primary px-8 py-3 text-sm font-normal text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={(e) => handleLogOut(e)}
                    >
                      Yes, Log Out{" "}
                      {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                    </Button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const NavList = () => {
  const location = useLocation();

  const handleRedirect = () => {
    window.location.href = "https://gofixitnow.online/fixer/";
  };

  return (
    <div className="2xl:block xl:block hidden">
      <List className="mt-4 mb-6 p-0 2xl:mt-0 2xl:mb-0 2xl:flex-row 2xl:p-1 xl:mt-0 xl:mb-0 xl:flex-row xl:p-1 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
        <Link
          to="/"
          className={`flex font-semibold text-base p-3 text-start transition-all outline-none flex items-center gap-2 pr-4 ${
            location.pathname === "/" ? "text-[#20448C]" : "text-[#0c0103]"
          }`}
          onClick={handleLeftroom}
        >
          Home
        </Link>
        <Link
          to="/services"
          className={`flex font-semibold text-base p-3 text-start transition-all outline-none flex items-center gap-2 pr-4 ${
            location.pathname === "/services" ||
            location.pathname.startsWith("/bookyourservice") ||
            location.pathname.startsWith("/servicesrequest")
              ? "text-[#20448C]"
              : "text-[#0c0103]"
          }`}
          onClick={handleLeftroom}
        >
          Services
        </Link>
        <Link
          to="/mybooking"
          className={`font-semibold text-base p-3 text-start transition-all outline-none flex items-center gap-2 pr-4 ${
            location.pathname === "/mybooking"
              ? "text-[#20448C]"
              : "text-[#0c0103]"
          }`}
          onClick={handleLeftroom}
        >
          My Booking
        </Link>
        <div
          className={`cursor-pointer text-black font-semibold text-base p-3 text-start transition-all outline-none flex items-center gap-2 pr-4`}
          onClick={(e) => {
            handleRedirect();
            handleLeftroom();
          }}
        >
          Become a Fixer
        </div>
      </List>
    </div>
  );
};

const TopBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [noData, setNodata] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [newMessage, setNewMessage] = useState(0);
  const [notificatinCount, setNotificatinCount] = useState(0);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const handleRedirect = () => {
    window.location.href = "https://gofixitnow.online/fixer/";
  };

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsDrawerOpen(false)
    );
  }, []);

  const getNotificationList = (page_no) => {
    setNodata(true);
    const params = {
      pageNo: page_no,
    };

    getNotificationListApi(params)
      .then((res) => {
        const data = res.data.info;
        const total_page = res.data.totalPage;

        if (page_no != 1) {
          const notification_arr = [...data, ...notificationList];
          setNotificationList(notification_arr);
        } else {
          setNotificationList(data);
        }
        setPageNo(page_no);
        setTotalPage(total_page);
        setHasMoreData(page_no < total_page);
        setNodata(false);
      })
      .catch((err) => {
        console.log("err >>>", err);
        setNodata(false);
      });
  };

  const viewMores = () => {
    if (pageNo <= totalPage) {
      getNotificationList(pageNo + 1);
    } else {
      setHasMoreData(false);
    }
  };

  useEffect(() => {
    onMessage(messaging, (payload) => {
      const notification_count = payload.data.unreadnotificationCount;
      setNotificatinCount(notification_count);
    });

    navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data && event.data.type === "background-message") {
        const { payload } = event.data;
        console.log("payload ????", payload);
      }
    });
  }, []);

  useEffect(() => {
    Socket.on("connect", () => {
      console.log(Socket.connected, Socket.id);
    });

    if (!Socket.connected) {
      Socket.emit("socket_register", {
        user_id: user_info.userId,
      });
    }

    Socket.on("unread_counts", (data) => updatecount(data));
  }, [Socket]);

  const updatecount = (data) => {
    console.log("message >>", data);
    setNewMessage(data.unreadMessageCount);
  };

  const notificationCount = (data) => {
    console.log("unread >>", data);
    setNotificatinCount(data.count);
  };

  return (
    <>
      <nav className="bg-white w-full fixed z-[10] top-0 border-b border-primary ">
        <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:py-3.5 xl:py-3.5 py-2.5">
          <div className="flex items-center justify-between">
            <Typography
              as="a"
              href="/"
              variant="h6"
              className="2xl:block xl:block hidden flex-shrink-0 lg:flex-1"
              onClick={(e) => handleLeftroom()}
            >
              <img
                className="2xl:h-12 xl:h-11 h-11 w-auto"
                src={logosm}
                alt="Workflow"
              />
            </Typography>
            <div className="flex items-center">
              <IconButton
                variant="text"
                color="blue-gray"
                className="2xl:hidden xl:hidden block p-2 my-2"
                onClick={openDrawer}
              >
                {isDrawerOpen ? (
                  <IoClose className="h-6 w-6" strokeWidth={2} />
                ) : (
                  <IoMdMenu className="h-6 w-6" strokeWidth={2} />
                )}
              </IconButton>
            </div>
            <div className="gap-3 flex item-center">
              <div className="gap-2 2xl:flex xl:flex lg:flex items-center">
                <div className="2xl:block xl:block hidden lg:flex 2xl:flex xl:flex">
                  <NavList />
                </div>
              </div>
              <Tooltip content="Message">
                <div className="cursor-pointer relative flex">
                  {newMessage !== 0 && (
                    <div className="z-10 absolute top-1 right-0 flex items-center justify-center h-4 w-4 -mt-0.5 -mr-0.5 text-xs font-medium text-white rounded-full bg-primary">
                      {newMessage}
                    </div>
                  )}
                  <Link
                    variant="text"
                    color="blue-gray"
                    className={`my-2 text-black font-xs p-1.5 text-start leading-3 transition-all outline-none flex items-center ${
                      location.pathname === "/messages"
                        ? "border-2 border-[#EAEAEA] rounded-full"
                        : ""
                    }`}
                    to="/messages"
                  >
                    <IoChatbubblesOutline className="2xl:h-6 2xl:w-6 h-5 w-5 text-black" />
                  </Link>
                </div>
              </Tooltip>
              <div>
                <Popover className="relative z-20">
                  <>
                    <Popover.Button
                      className="outline-0 relative p-2 my-2 rounded-full border-2 border-[#EAEAEA] cursor-pointer"
                      onClick={() => {
                        setNotificationList([]);
                        setPageNo(1);
                        setHasMoreData(true);
                        getNotificationList(1);
                        handleLeftroom();
                      }}
                    >
                      <div className="cursor-pointer flex relative">
                        {notificatinCount > 0 ? (
                          <div className="z-10 absolute -top-1 right-0 flex items-center justify-center h-4 w-4 -mt-0.5 -mr-0.5 text-xs font-medium text-white rounded-full bg-primary">
                            {notificatinCount}
                          </div>
                        ) : (
                          ""
                        )}
                        <Bell className="2xl:h-6 2xl:w-6 h-5 w-5 text-black" />
                      </div>
                    </Popover.Button>
                    <Popover.Panel className="mt-4 2xl:w-[350px] xl:w-[350px] lg:w-[350px] md:w-[350px] w-[300px]">
                      <div className="flex items-center justify-between p-2">
                        <div className="font-medium text-lg">Notifications</div>
                      </div>
                      <div
                        id="notifyside"
                        className="overflow-y-auto h-[500px] overflow-x-hidden"
                      >
                        <div className="">
                          {noData === false && notificationList.length <= 0 ? (
                            <div className="text-center flex justify-center items-center h-[70%]">
                              <h5>No Data found</h5>
                            </div>
                          ) : (
                            <>
                              <div className="mt-4">
                                {notificationList.map((item, index) => {
                                  return (
                                    <div key={index} className="border-b">
                                      <div className="flex items-center gap-4 py-2 pl-2 pr-8">
                                        <Avatar
                                          size="md"
                                          variant="circular"
                                          alt="tania andrew"
                                          crossOrigin="anonymous"
                                          src={
                                            item?.notificationbydetails
                                              ?.profilePic
                                              ? IMAGE_URL +
                                                item?.notificationbydetails
                                                  ?.profilePic
                                              : noImg
                                          }
                                          className="min-w-12"
                                        />
                                        <div className="flex flex-col gap-1">
                                          <Typography
                                            color="blue-gray"
                                            className="font-medium leading-5 text-sm"
                                          >
                                            {item.notificationText}
                                          </Typography>
                                          <Typography className="flex items-center gap-1 text-sm font-medium text-[#9C9DA9]">
                                            <ReactTimeAgo
                                              date={
                                                item.createdAt &&
                                                !isNaN(
                                                  Date.parse(item.createdAt)
                                                )
                                                  ? Date.parse(item.createdAt)
                                                  : new Date()
                                              }
                                              locale="en-US"
                                            />
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          <div
                            className={`flex justify-center items-center ${
                              hasMoreData ? "py-8" : ""
                            }`}
                          >
                            <InfiniteScroll
                              dataLength={notificationList.length || 0}
                              next={viewMores}
                              hasMore={hasMoreData}
                              loader={
                                noData ? (
                                  <ThreeDots
                                    width={40}
                                    height={10}
                                    color="#2b426e"
                                    ariaLabel="circles-loading"
                                    wrapperStyle={{}}
                                    visible={true}
                                    wrapperClass=""
                                  />
                                ) : (
                                  ""
                                )
                              }
                              scrollableTarget="notifyside"
                            />
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </>
                </Popover>
              </div>
              <ProfileMenu user_info={user_info} />
            </div>
          </div>
        </div>
      </nav>

      <Drawer open={isDrawerOpen} onClose={closeDrawer} className="drawer">
        <Card color="transparent" shadow={false} className="h-screen p-4">
          <div className="mb-2 p-4 flex justify-between items-center">
            <img
              className="2xl:h-[56px] 2xl:w-[122px] xl:h-[56px] xl:w-[122px] h-12 w-auto"
              src={logosm}
              alt="Workflow"
            />
            <div onClick={(e) => setIsDrawerOpen(false)}>
              <IoClose className="h-6 w-6 stroke-2" />
            </div>
          </div>
          <List>
            <ListItem
              className={`my-1 font-medium text-base p-3 cursor-pointer text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                location.pathname === "/"
                  ? "gradient-button-blue text-white"
                  : "text-black"
              }`}
              onClick={(e) => {
                navigate("/");
                setIsDrawerOpen(false);
                handleLeftroom();
              }}
            >
              Home
            </ListItem>
            <ListItem
              className={`my-1 font-medium text-base p-3 cursor-pointer text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                location.pathname === "/services" ||
                location.pathname.startsWith("/bookyourservice") ||
                location.pathname.startsWith("/servicesrequest")
                  ? "gradient-button-blue text-white"
                  : "text-black"
              }`}
              onClick={(e) => {
                navigate("/services");
                setIsDrawerOpen(false);
                handleLeftroom();
              }}
            >
              Services
            </ListItem>
            <ListItem
              className={`my-1 font-medium text-base p-3 cursor-pointer text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 ${
                location.pathname === "/mybooking"
                  ? "gradient-button-blue text-white"
                  : "text-black"
              }`}
              onClick={(e) => {
                navigate("/mybooking");
                setIsDrawerOpen(false);
                handleLeftroom();
              }}
            >
              My Bookings
            </ListItem>
            <ListItem
              className={`my-1 text-black font-medium text-base cursor-pointer p-3 text-start leading-tight transition-all outline-none flex items-center gap-2 pr-4 `}
              onClick={(e) => {
                handleRedirect();
                handleLeftroom();
              }}
            >
              Become a Fixer
            </ListItem>
          </List>
        </Card>
      </Drawer>
    </>
  );
};

export default TopBar;
