import React, { useEffect, useState } from "react";
// import areaBg from "../../assets/images/home/area-bg.png";
import areaBg from "../../assets/images/home/area-bg2.png";
import { getAllCityApi } from "../../services/ApiServices";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import noPicture from "../../assets/images/no-picture.png";
import { IMAGE_URL } from "../../services/Api";

const AreaSection = () => {
  const [cityData, setCityData] = useState([]);
  const [isCityLoader, setIsCityLoader] = useState(false);

  const getCityList = () => {
    setIsCityLoader(true);

    getAllCityApi()
      .then((res) => {
        const data = res.data.info;
        const status = res.data.status;

        if (status === 1) {
          setCityData(data);
        } else {
          setCityData([]);
        }

        setIsCityLoader(false);
      })
      .catch((err) => {
        setIsCityLoader(false);
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getCityList();
  }, []);

  const area_settings = {
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rows: 2,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
          rows: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 4,
        },
      },
    ],
  };

  return (
    <>
      <section className="2xl:pt-12 xl:pt-12 lg:pt-10 md:pt-10 pt-11">
        <div
          className="h-full w-full inset-0 bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${areaBg})` }}
        >
          <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:pt-12 2xl:pb-10 xl:pt-12 lg:pt-10 pt-6 2xl:pb-16 xl:pb-16 pb-14">
            <h2 className="text-[#20448C] 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-xl font-semibold text-center">
              Areas We Cover
            </h2>
            {isCityLoader ? (
              <div className="pt-8">
                <div className="my-6">
                  <div className="max-w-full animate-pulse border-separate border-spacing-y-4">
                    <div className="grid grid-cols-12 gap-4">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((star) => (
                        <div
                          className="col-span-4 2xl:col-span-2 xl:col-span-2 lg:col-span-3 md:col-span-3"
                          key={star}
                        >
                          <div className="h-full w-full flex justify-center items-center">
                            <div className="rounded-full 2xl:h-44 2xl:w-44 xl:h-40 xl:w-40 lg:h-36 lg:w-36 md:w-32 md:h-32 w-24 h-24">
                              <div className="bg-gray-200 rounded-full h-full w-full object-center object-cover"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : !isCityLoader && cityData.length <= 0 ? (
              <div className="h-48 mt-8">
                <div className="flex justify-center items-center text-center h-full w-full">
                  <h6 className="font-medium text-lg">Area Not Found</h6>
                </div>
              </div>
            ) : (
              <Slider
                {...{
                  ...area_settings,
                  arrows: false,
                }}
                className="h-full area-slider pt-8"
              >
                {cityData.map((item, index) => {
                  return (
                    <div
                      className="flex items-center justify-center h-full"
                      key={index}
                    >
                      <div className="2xl:p-4 xl:p-4 lg:p-4 md:p-3.5 p-2.5 h-full">
                        <div className="flex items-center justify-center w-full">
                          <div className="2xl:border-8 xl:border-8 lg:border-[6px] md:border-[7px] border-[5px] border-[#008CDB] rounded-full 2xl:h-44 2xl:w-44 xl:h-40 xl:w-40  h-auto w-auto">
                            <img
                              src={
                                item.cityImage
                                  ? IMAGE_URL + item.cityImage
                                  : noPicture
                              }
                              alt="area"
                              className="rounded-full h-full w-full object-center object-cover"
                              crossOrigin="anonymous"
                            ></img>
                          </div>
                        </div>
                        <div className="mt-2 text-center">
                          <h5 className="text-[#3B3B3B] font-semibold 2xl:text-base xl:text-base lg:text-base md:text-base text-sm 2xl:whitespace-nowrap truncate">
                            {item.city}
                          </h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AreaSection;
