import van1 from "../assets/images/booking/van-1.png";
import van2 from "../assets/images/booking/van-2.png";
import van3 from "../assets/images/booking/van-3.png";
import van4 from "../assets/images/booking/van-4.png";
import helpImg1 from "../assets/images/booking/help-1.png";
import helpImg2 from "../assets/images/booking/help-2.png";
import helpImg3 from "../assets/images/booking/help-3.png";
import helpImg4 from "../assets/images/booking/help-4.png";

export const handymanTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£55", "£70"],
          ["£60", "£75"],
        ],
        rates: "£85",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£32.50", "£40"],
          ["£35", "£42.50"],
        ],
        rates: "£47.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£70",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£22.50", "£30"],
          ["£25", "£32.50"],
        ],
        rates: "£37.50",
      },
    ],
  },
];

export const electricianTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£80", "£95"],
          ["£85", "£100"],
        ],
        rates: "£110",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£45", "£52.50"],
          ["£47.50", "£55"],
        ],
        rates: "£60",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£65", "£80"],
          ["£70", "£85"],
        ],
        rates: "£95",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£35", "£42.50"],
          ["£37.50", "£45"],
        ],
        rates: "£50",
      },
    ],
  },
];

export const plumberTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£70", "£85"],
          ["£75", "£90"],
        ],
        rates: "£100",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£40", "£47.50"],
          ["£42.50", "£50"],
        ],
        rates: "£55",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£55", "£70"],
          ["£60", "£75"],
        ],
        rates: "£80",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£30", "£37.50"],
          ["£32.50", "£40"],
        ],
        rates: "£42.50",
      },
    ],
  },
];

export const painterTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const cleanerTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const wasteRemovalTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£60", "£75"],
          ["£65", "£80"],
        ],
        rates: "£95",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£35", "£42.50"],
          ["£37.50", "£45"],
        ],
        rates: "£52.50",
      },
    ],
  },
  {
    region: "Rest of the Uk",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£45", "£60"],
          ["£50", "£65"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£40",
      },
    ],
  },
];

export const gardenerTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£40", "£55"],
          ["£45", "£60"],
        ],
        rates: "£75",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£25", "£32.50"],
          ["£27.50", "£35"],
        ],
        rates: "£42.50",
      },
    ],
  },
  {
    region: "Rest of the UK",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£25", "£40"],
          ["£30", "£45"],
        ],
        rates: "£55",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£15", "£22.50"],
          ["£17.50", "£25"],
        ],
        rates: "£30",
      },
    ],
  },
];

export const ManWithAVanTables = [
  {
    region: "Greater London",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£50", "£65"],
          ["£55", "£70"],
        ],
        rates: "£85",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£30", "£37.50"],
          ["£32.50", "£40"],
        ],
        rates: "£47.50",
      },
    ],
  },
  {
    region: "Rest of the UK",
    rows: [
      {
        description: "Labour",
        times: [
          ["8am - 6pm", "After Hours"],
          ["8am - 6pm", "After Hours"],
        ],
        rates: "",
      },
      {
        description: "First Hour",
        times: [
          ["£35", "£50"],
          ["£40", "£55"],
        ],
        rates: "£65",
      },
      {
        description: "Subsequent half hours",
        times: [
          ["£20", "£27.50"],
          ["£22.50", "£30"],
        ],
        rates: "£35",
      },
    ],
  },
];

export const van_arr = [
  {
    id: 1,
    name: "Small Van",
    img: van1,
  },
  {
    id: 2,
    name: "Medium Van",
    img: van2,
  },
  {
    id: 3,
    name: "Large Van",
    img: van3,
  },
  {
    id: 4,
    name: "Gaint Van",
    img: van4,
  },
];

export const help_arr = [
  {
    id: 1,
    name: "No help needed",
    img: helpImg1,
  },
  {
    id: 2,
    name: "Driver helping",
    img: helpImg2,
  },
  {
    id: 3,
    name: "Driver helping + 1 helper",
    img: helpImg3,
  },
  {
    id: 4,
    name: "Driver helping + 2 helpers",
    img: helpImg4,
  },
];

export const customGoogleplaceStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isHovered || state.isFocused ? "1.5px solid #0f4c8b" : "none",
      borderRadius: "0.75rem",
      padding: "4px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#0f4c8b",
      },
      minHeight: "48px",
      width: "100%",
      textAlign: "start",
    }),
    input: (provided) => ({
      ...provided,
      color: "#333",
      fontSize: "16px",
      width: "100%",
      textAlign: "start",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#888",
      textAlign: "start",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f1f1f1" : "white",
      color: "#333",
      padding: "10px 15px",
      cursor: "pointer",
      textAlign: "start",
    }),
  };
