export const BASE_URL = "https://gofixitnow.online:3000";
export const IMAGE_URL = "https://gofixitnow.online:3000/";

export const LOGIN_API = BASE_URL + "/login";
export const LOGOUT_API = BASE_URL + "/logout";
export const SIGNUP_API = BASE_URL + "/usersignup";

//verification & otp
export const VERIFICATION_FOR_EMAIL_API = BASE_URL + "/verification_for_email";
export const SEND_OTP_API = BASE_URL + "/send_otp";

//list review api
export const LIST_REVIEW_API = BASE_URL + "/list_reviews";

//forget password
export const FORGET_PASSWORD_API = BASE_URL + "/forgot_password_verification";
export const RESET_PASSWORD_API = BASE_URL + "/reset_password";
export const ACCOUNT_SETUP_API = BASE_URL + "/account_setup";

//change password
export const CHANGE_PASSWORD_API = BASE_URL + "/change_password";

//service api
export const LIST_MAIN_SERVICE_API = BASE_URL + "/list_main_services";
export const LIST_SUB_SERVICE_API = BASE_URL + "/list_sub_services";
export const LIST_SERVICES_API = BASE_URL + "/list_services";
export const ADD_BOOKING_SERVICE_API = BASE_URL + "/add_bookings";

export const GET_PRICE_API = BASE_URL + "/getPricingByUsers";

//list my booking
export const LIST_BOOKING_SERVICE_API = BASE_URL + "/list_user_bookings";
export const GET_SERVICE_DETAILS_API = BASE_URL + "/get_booking_details";
export const CANCEL_BOOKING_API = BASE_URL + "/cancel_booking";
export const ADD_REVIEW_API = BASE_URL + "/add_review";

export const GET_PROFILE_API = BASE_URL + "/get_profile";
export const EDIT_PROFILE_API = BASE_URL + "/edit_profile";
export const CONTACT_US_API = BASE_URL + "/add_contact_us";
export const ADD_TO_FAVORITE_API = BASE_URL + "/favourite_booking";
export const LIST_FAVORITE_BOOKING_API = BASE_URL + "/list_favourite_booking";

export const GET_PRICEING_API = BASE_URL + "/getPricingForUserFixer";
export const PAYMENT_SUCCESS_API = BASE_URL + "/bookingPaymentSuccess";

//delete account
export const DELETE_ACCOUNT_API = BASE_URL + "/delete_account";

//payment
export const CREATE_PAYMENT_INTENT_API = BASE_URL + "/create_payment_intent";

//list country & city
export const LIST_COUNTRY_API = BASE_URL + "/list_country";

export const LIST_ALL_CITY_API = BASE_URL + "/allCities";

export const LIST_CITY_API = BASE_URL + "/list_city";
export const LIST_AREA_API = BASE_URL + "/allAreas";

//notification
export const LIST_NOTIFICATION_API = BASE_URL + "/list_notification";

//van_rental
export const ADD_VAN_RENTAL_API = BASE_URL + "/van_rental";

//charge api
export const GET_ADMIN_CHARGE_API = BASE_URL + "/getAdminCharges";

//chat module
export const GET_CHAT_LIST_API = BASE_URL + "/createdChatListForUser";
export const GET_CHAT_MESSAGE_LIST_API = BASE_URL + "/getMessages";
export const SEND_MESSAGE_API = BASE_URL + "/sendMessage";

//area listing
export const LIST_ALL_CITIES_API = BASE_URL + "/listCity";

//vehicle & helper
export const LIST_VEHICLE_AND_HELPER_API = BASE_URL + "/listVehicleHelper";
