import { Helmet } from "react-helmet";
import {
  cleanerTables,
  electricianTables,
  gardenerTables,
  handymanTables,
  ManWithAVanTables,
  painterTables,
  plumberTables,
  wasteRemovalTables,
} from "../../common/data";
import Footer from "../../components/home/Footer";
import "../../assets/css/cost-guid.css";
import NewHeader from "../../components/home/NewHeader";
import "../../assets/css/cost-guid.css";

const TableRow = ({ description, times, rates }) => (
  <tr>
    <td>{description}</td>
    {times.map((time, idx) => (
      <td key={idx} className="td-with-spans">
        <div>
          {time.map((span, spanIdx) => (
            <span key={spanIdx}>{span}</span>
          ))}
        </div>
      </td>
    ))}
    <td>{rates}</td>
  </tr>
);

const PriceTable = ({ region, rows }) => (
  <div className="table-wrapper">
    <table
      className={`${region.toLowerCase().replace(/\s+/g, "-")}-table w-full`}
    >
      <thead>
        <tr>
          <th>{region}</th>
          <th>Monday-Friday</th>
          <th>Saturday</th>
          <th>Sunday</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <TableRow key={index} {...row} />
        ))}
      </tbody>
    </table>
  </div>
);

const CostGuideBox = ({ title, tables, desc }) => (
  <div className="col-span-12 mb-md-5 mb-3">
    <div className="single-cost-guide-box">
      <div className="grid grid-cols-12">
        {desc ? (
          <div className="col-span-12 flex flex-col justify-center mb-md-4 mb-3 title-and-extra-details">
            <h3 className="text-center">{title}</h3>
            <small className="text-center">{desc}</small>
          </div>
        ) : (
          <div className="col-span-12">
            <h3 className="title text-3xl font-semibold text-center 2xl:mb-4 xl:mb-4 lg:mb-4 md:mb-4 mb-3">
              {title}
            </h3>
          </div>
        )}
        <div className="col-span-12 table-col overflow-auto">
          {tables.map((table, index) => (
            <PriceTable key={index} {...table} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

const CostGuides = () => {
  return (
    <>
      <Helmet>
        <title>Cost Guide - Go Fix It Now</title>
      </Helmet>
      <NewHeader />
      <div className="cost-guide-page">
        <section className="banner-section cost-guide-banner-section">
          <div className="container position-relative flex justify-center items-center">
            <h1 className="heading">Cost Guide</h1>
          </div>
        </section>
        <section className="cost-guide-1-section">
          <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
            <div className="grid grid-cols-12">
              <div className="col-span-12">
                <div className="single-point my-3">
                  <h2 className="title text-[2rem]">Service Cost Guides</h2>
                  <p className="">
                    Service Requests scheduled outside the hours of 8:00 am to
                    6:00 pm and Sundays will result in an extra call-out fee of
                    £50, which will be specified on the invoice. This fee
                    accounts for the Fixer&apos;s petrol costs and acknowledges
                    the inconvenience of the call-out. It is crucial to
                    compensate Fixers adequately when responding to requests
                    during times that are typically reserved for family,
                    friends, late night, or early morning hours.
                  </p>
                </div>
                <div className="single-point my-3">
                  <h2 className="title text-[2rem]">
                    Parking & Congestion Cost Guides
                  </h2>
                  <p className="">
                    Upon booking a service, kindly note that it is your
                    responsibility to arrange a parking spot for our service
                    providers. In the event that you are unable to secure
                    parking at the service location, our providers will locate
                    suitable parking nearby, with any associated fees being
                    passed on to you at cost. Should a congestion charge be
                    incurred during the service visit, this cost will be
                    transferred to the client.
                  </p>
                </div>
                <div className="single-point my-3">
                  <h2 className="title text-[2rem]">Shopping Cost Guides</h2>
                  <p className="">
                    If our fixers are required to purchase materials or
                    supplies, a fixed hourly rate of £30, excluding VAT, will be
                    levied for shopping services across all trades. This fee
                    will be incorporated into the final invoice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cost-guide-section">
          <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
            <div className="grid grid-cols-12 gap-6">
              <CostGuideBox title="Handyman Price" tables={handymanTables} />
              <CostGuideBox
                title="Electrician Price"
                tables={electricianTables}
              />
              <CostGuideBox title="Plumber Price" tables={plumberTables} />
              <CostGuideBox
                title="Painter & Decorator Price"
                tables={painterTables}
              />
              <CostGuideBox title="Cleaner Price" tables={cleanerTables} />
              <CostGuideBox
                title="Waste Removal Price"
                tables={wasteRemovalTables}
                desc="There is a charge of £55 to £75 for a quarter load, £110 to £130 for half load, £230 to £250 for full load. This charge is for disposing of waste safely."
              />
              <CostGuideBox title="Gardener Price" tables={gardenerTables} />
              <CostGuideBox
                title="Man with a Van - Transportation Price"
                tables={ManWithAVanTables}
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default CostGuides;
