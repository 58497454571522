import React, { useEffect, useState } from "react";
import logoLg from "../../assets/images/home/logo-lg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { IconButton, Drawer, Card } from "@material-tailwind/react";

const NewHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("user_token");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsDrawerOpen(false)
    );
  }, []);

  return (
    <>
      <header className="bg-white w-full fixed z-[10] top-0 py-4">
        <nav className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 h-full">
          <div className="2xl:block xl:block lg:block hidden">
            <div className="flex items-center justify-between md:px-0 px-1.5">
              <div className="flex-shrink-0 lg:flex-1">
                <a href="/">
                  <img
                    className="2xl:h-12 xl:h-12 lg:h-12 h-full w-auto"
                    src={logoLg}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="flex items-center">
                <div className="flex items-baseline space-x-3">
                  <a
                    href="/howitworks"
                    className={`font-medium px-1 py-2 rounded-md ${
                      location.pathname === "/howitworks"
                        ? "text-[#20448C]"
                        : "text-[#525252]"
                    }`}
                  >
                    How it work
                  </a>
                  <a
                    href="/costguide"
                    className={`font-medium px-1 py-2 rounded-md ${
                      location.pathname === "/costguide"
                        ? "text-[#20448C]"
                        : "text-[#525252]"
                    }`}
                  >
                    Cost Guide
                  </a>
                  <a
                    href="https://gofixitnow.online/fixer/"
                    className="text-[#525252] font-medium px-1 py-2 rounded-md"
                  >
                    Become a Fixer
                  </a>
                  <a
                    href="/signup"
                    className="bg-[#20448C] text-[#FFFFFF] rounded-md px-6 py-2 text-sm font-medium"
                  >
                    SignUp
                  </a>
                  <a
                    href="/signin"
                    className="bg-[#20448C] text-[#FFFFFF] rounded-md px-6 py-2 text-sm font-medium"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center h-full lg:mx-0 mx-2 2xl:hidden xl:hidden lg:hidden block">
            <div className="flex items-center">
              <IconButton
                variant="text"
                size="lg"
                onClick={openDrawer}
                className="p-0 w-8 h-8"
              >
                <Bars3Icon className="h-5 w-5" strokeWidth={2} />
              </IconButton>
            </div>
            <div className="">
              <a href="/">
                <img className="2xl:h-12 xl:h-12 lg:h-12 h-10" src={logoLg} alt="Workflow" />
              </a>
            </div>
          </div>
        </nav>
      </header>

      <Drawer open={isDrawerOpen} onClose={closeDrawer} className="drawer">
        <Card color="transparent" shadow={false} className="p-4">
          <div className="mb-2 py-2 px-3 flex justify-between items-center">
            <img
              className="2xl:h-[56px] 2xl:w-[122px] xl:h-[56px] xl:w-[122px] h-12 w-auto"
              src={logoLg}
              alt="Workflow"
            />
            <div>
              <IconButton
                variant="text"
                color="blue-gray"
                onClick={(e) => setIsDrawerOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
          <div className="px-3 py-1">
            <div
              className={`my-2 rounded-lg font-semibold px-1 py-2 text-base hover:text-[#20448C] ${
                location.pathname === "/howitworks"
                  ? "text-[#20448C]"
                  : "text-[#525252]"
              }`}
              onClick={(e) => navigate("/howitworks")}
            >
              How it work
            </div>
            <div
              className={`my-2 rounded-lg font-semibold px-1 py-2 text-base hover:text-[#20448C] ${
                location.pathname === "/costguide"
                  ? "text-[#20448C]"
                  : "text-[#525252]"
              }`}
              onClick={(e) => navigate("/costguide")}
            >
              Cost Guide
            </div>
            <div className="my-2 rounded-md text-[#525252] font-semibold px-1 py-2 text-base hover:text-[#20448C]">
              <a href="https://gofixitnow.online/fixer/">Become a Fixer</a>
            </div>
            <div
              className="my-3 rounded-lg gradient-button-blue my-1 text-white text-sm p-3 cursor-pointer text-center leading-tight transition-all outline-none pr-4"
              onClick={(e) => navigate("/signup")}
            >
              Sign up
            </div>
            <div
              className="my-2 rounded-lg gradient-button-blue text-white text-sm p-3 cursor-pointer text-center leading-tight transition-all outline-none pr-4"
              onClick={(e) => navigate("/signin")}
            >
              Log in
            </div>
          </div>
        </Card>
      </Drawer>
    </>
  );
};

export default NewHeader;
