import React, { useEffect, useRef, useState } from "react";
import { listReviewApi } from "../../services/ApiServices";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import Slider from "react-slick";
import { IMAGE_URL } from "../../services/Api";
import noImage from "../../assets/images/no-image.png";

const ClientsReview = () => {
  const reviewRef = useRef(null);
  const [reviewList, setReviewList] = useState([]);
  const [isReviewLodader, setIsReviewLodader] = useState(false);

  const review_settings = {
    dots: true,
    speed: 1000,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplaySpeed: 2000,
    cssEase: "linear",
    rows: 2,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const listReviewInfo = () => {
    setIsReviewLodader(true);
    listReviewApi()
      .then((res) => {
        const data = res.data.info;
        setReviewList(data);
        setIsReviewLodader(false);
      })
      .catch((err) => {
        setIsReviewLodader(false);
        console.log("err ???", err);
      });
  };

  useEffect(() => {
    listReviewInfo();
  }, []);

  return (
    <>
      <section className="2xl:pt-12 xl:pt-12 pt-10 pb-20 bg-[#008CDB33]">
        <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
          <h2 className="text-[#20448C] 2xl:text-4xl xl:text-3xl lg:text-2xl  md:text-2xl text-xl font-medium text-center">
            See what our clients say about our fixers
          </h2>
          {isReviewLodader ? (
            <div className="animate-pulse border-separate border-spacing-y-4 gap-4">
              <div className="my-6">
                <div className="grid grid-cols-12 2xl:gap-5 xl:gap-5 lg:gap-4 gap-3">
                  {[1, 2, 3].map((star) => (
                    <div
                      className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-6"
                      key={star}
                    >
                      <div className="flex justify-center items-center w-full">
                        <div className="bg-gray-300 rounded-lg 2xl:h-[170px] xl:h-[170px] lg:h-[160px] h-[150px] w-full"></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : !isReviewLodader && reviewList.length <= 0 ? (
            <div className="h-48 mt-8">
              <div className="flex justify-center items-center h-full">
                <h6 className="font-medium">Reviews Not Found</h6>
              </div>
            </div>
          ) : (
            <div className="mt-8 relative">
              <button
                className="absolute top-1/2 -left-[8px] transform -translate-y-1/2 bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => reviewRef.current.slickPrev()}
              >
                <GrFormPrevious fontSize={20} />
              </button>
              <button
                className="absolute top-1/2 -right-[8px] transform -translate-y-1/2 bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => reviewRef.current.slickNext()}
              >
                <GrFormNext fontSize={20} />
              </button>
              <div className="px-5">
                <Slider
                  ref={reviewRef}
                  {...{
                    ...review_settings,
                    arrows: false,
                  }}
                  className="h-full client-review-slider"
                >
                  {reviewList.map((item, index) => {
                    const isProfilePicValid =
                      item?.review_by?.profilePic.startsWith("profilepics/");

                    return (
                      <div
                        className="h-full px-5 2xl:py-5 xl:py-5 py-2 font-roboto"
                        key={index}
                      >
                        <div className="p-4 bg-white rounded-lg drop-shadow-[0_4px_6px_rgba(0,0,0,0.06)] h-full">
                          <div className="flex items-center gap-2">
                            <img
                              alt=""
                              src={
                                isProfilePicValid
                                  ? IMAGE_URL + item?.review_by?.profilePic
                                  : noImage
                              }
                              className="rounded-full 2xl:h-16 2xl:w-16 xl:h-16 xl:w-16 h-14 w-14 object-cover"
                              crossOrigin="anonymous"
                            ></img>
                            <div>
                              <h5 className="text-[#2E2E2E] font-semibold">
                                {`${item?.review_by?.firstName} ${item?.review_by?.lastName}`}
                              </h5>
                              <div className="flex items-center margin-right-left">
                                {[1, 2, 3, 4, 5].map((star) => (
                                  <span
                                    key={star}
                                    className="cursor-pointer"
                                    style={{
                                      cursor: "pointer",
                                      color:
                                        4 >= item.reviewStar
                                          ? "#FFC107"
                                          : "#D7D7D7",
                                      fontSize: "24px",
                                      padding: "0px",
                                    }}
                                  >
                                    {" "}
                                    ★{" "}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="border-t border-[#C6C6C6] my-4"></div>
                          <div className="2xl:h-16 xl:h-16 lg:h-16 h-14">
                            <h5 className="tracking-wider text-[#6C6C6C] 2xl:text-base xl:text-base lg:text-base text-sm line-clamp-2">
                              {item.reviewText || "-"}
                            </h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ClientsReview;
