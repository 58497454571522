import React, { useRef } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import Slider from "react-slick";
import service1 from "../../assets/images/home/popularservices/service1.png";
import service2 from "../../assets/images/home/popularservices/service2.png";
import service3 from "../../assets/images/home/popularservices/service3.png";
import service4 from "../../assets/images/home/popularservices/service4.png";
import { useNavigate } from "react-router-dom";

const popular_service_arr = [
  {
    serviceId: 1,
    serviceName: "Flatpack Assembly",
    price: "From £30",
    serviceImg: service1,
  },
  {
    serviceId: 2,
    serviceName: "TV mounting",
    price: "From £30",
    serviceImg: service2,
  },
  {
    serviceId: 3,
    serviceName: "Install A EV Charger",
    price: "From £280",
    serviceImg: service3,
  },
  {
    serviceId: 4,
    serviceName: "Boiler Repairs",
    price: "From £50",
    serviceImg: service4,
  },
];

const PopularServices = () => {
  const navigate = useNavigate();
  const serviceRef = useRef(null);

  var popular_service_settings = {
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const handleBook = (e) => {
    navigate("/services");
  };

  return (
    <>
      <section className="py-6">
        <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
          <div className="flex items-center justify-between">
            <h2 className="text-[#20448C] 2xl:text-4xl xl:text-3xl lg:text-2xl  md:text-2xl text-xl font-medium">
              Popular Services
            </h2>
            <div className="flex items-center gap-4">
              <button
                className="bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => serviceRef.current.slickPrev()}
              >
                <GrFormPrevious fontSize={25} />
              </button>
              <button
                className="bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => serviceRef.current.slickNext()}
              >
                <GrFormNext fontSize={25} />
              </button>
            </div>
          </div>
          <div className="my-5">
            <Slider
              ref={serviceRef}
              {...{
                ...popular_service_settings,
                arrows: false,
              }}
              className="popular-service-settings"
            >
              {popular_service_arr.map((item, index) => {
                return (
                  <div
                    className="pr-3 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 col-span-6"
                    key={index}
                  >
                    <div className="">
                      <div className="bg-[#0C4AD8] rounded-t-md 2xl:p-4 xl:p-4 lg:p-4 p-3">
                        <h6 className="font-normal 2xl:text-lg xl:text-lg md:text-base text-sm text-[#E5E2FF] whitespace-nowrap">
                          {item.serviceName}
                        </h6>
                        <h4 className="font-semibold 2xl:text-xl xl:text-xl lg:text-xl text-lg text-white mt-1">
                          {item.price}
                        </h4>
                      </div>
                      <div className="relative">
                        <img
                          alt=""
                          src={item.serviceImg}
                          className="2xl:h-[360px] xl:h-[300px] lg:h-[300px] md:h-[250px] h-[200px] w-full object-cover object-center rounded-b-md"
                        ></img>
                        <button
                          style={{ boxShadow: "0px 4px 6px #00000040" }}
                          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-[#0C4AD8] text-white 2xl:px-8 xl:px-6 lg:px-5 md:px-4 px-3 2xl:py-3 xl:py-3 lg:py-3 py-2 rounded-xl 2xl:text-base xl:text-base lg:text-base md:text-base text-sm whitespace-nowrap"
                          onClick={(e) => handleBook()}
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularServices;
