import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:mt-24 xl:mt-24 lg:mt-22 mt-20 py-8 mx-auto">
        <div className="w-full flex justify-center">
          <div className="p-4 rounded-lg w-[100%]">
            <h3 className="text-lg font-semibold">Privacy Policy</h3>
            <div className="py-2.5">
              <h4 className="font-medium text-base">1. Introduction</h4>
              <p className="py-3 text-gray leading-8 text-sm">
                This document, the Global Privacy Policy, serves to outline the
                precise manner in which Go Fix It Now Inc. and its affiliated
                entities will collect, utilize, and uphold your Personal
                Information on the Go Fix It Now Platform. Additionally, it will
                elucidate your legal entitlements concerning said information.
                By making use of the Go Fix It Now Platform, you assert your
                comprehension and acknowledgment of this Privacy Policy, along
                with our Global Terms of Service. Collectively referred to as
                the "Agreement," these documents regulate the utilization of the
                Go Fix It Now Platform. It is important to note that Go Fix It
                Now will gather, employ, and safeguard information by the
                provisions outlined in the Agreement.
              </p>
            </div>
            <div className="py-2.5">
              <h4 className="font-medium text-base">2. General Terms</h4>
              <p className="py-3 text-gray leading-7 text-sm">
                Go Fix It Now, hereinafter referred to as "Go Fix It Now," or
                simply "we," is a company that provides a community platform for
                users, hereinafter referred to as "Users," "Clients," or "you,"
                through our website (www.gofixitnow.com and local variants,
                including www.gofixitnow.co.uk), hereinafter referred to as the
                "Site(s)," as well as through our mobile applications on iOS and
                Android, hereinafter referred to as the "Apps." Collectively,
                these platforms, services, information, and communications are
                referred to as the "Go Fix It Now Platform." The term "Terms of
                Service" refers to our comprehensive Global Terms of Service,
                which can be accessed through the following link: [insert link].
                Please note that this Privacy Policy is an integral part of the
                Terms of Service and is incorporated therein.
              </p>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  3. Information Collection
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  Go Fix It Now collects precise personally identifiable
                  information from individuals, encompassing data that can
                  discern, pertain to, depict, or reasonably connect to them.
                  This information, referred to as "Personal Information,"
                  includes a range of data points. Examples of Personal
                  Information collected by Go Fix It Now include, but are not
                  limited to:
                </p>
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Contact Information:{" "}
                </b>
                Full Name: [First Name] [Last Name] Postal Address: [Complete
                Postal Address] Telephone Number: [Phone Number] Email Address:
                [Email Address] Please provide the above-mentioned details to
                ensure effective communication and easy accessibility.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  We require your Billing Data:{" "}
                </b>
                , which encompasses your payment instrument number (e.g., credit
                or debit card number), expiration date, and security code, to
                effectively process your payments.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Identity Information:{" "}
                </b>
                If you are a Fixer, we have the expertise to collect essential
                Personal Information to ensure the utmost professionalism and
                compliance. This includes, but is not limited to, your date of
                birth, address, and national identification number, if
                applicable. Additionally, we may request the result of basic
                criminal record checks, which can be provided by you or our
                trusted Third-Party Agents, by the applicable laws in your
                jurisdiction. Moreover, we may require any additional
                information necessary to validate your identity, further
                emphasizing our commitment to maintaining a serious and
                knowledgeable demeanour.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Financial Information:{" "}
                </b>
                To facilitate the establishment of payment for our valued Fixers
                and ensure timely remuneration, we may request the provision of
                financial information, including bank account numbers, NI
                Numbers, taxpayer identification numbers, and other pertinent
                payment details. The collection and utilization of such
                Financial Information are crucial for the effective operation of
                the Go Fix It Now Platform and the seamless disbursement of
                payments to our esteemed Fixers. We undertake these measures as
                a necessary step to safeguard our legitimate interests in
                delivering our platform and services, as well as fulfilling our
                contractual obligations to our valued Fixers.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Promotional Information:{" "}
                </b>
                please follow the instructions provided in our communication or
                contact our customer support team. We understand that your
                privacy is important, and we respect your decision to not
                participate in certain offerings of the Go Fix It Now Platform,
                such as newsletters, surveys, contests, and similar activities.
                Rest assured that your participation in these offerings is
                completely optional, and you are under no obligation to provide
                us with your data about them. If you choose to take advantage of
                the Go Fix It Now Platform offerings, we will responsibly
                utilize your data. This may involve sending you newsletters and
                other communications that are specifically tailored to your
                preferences and interests, based on the information we have
                gathered about you. Additionally, we will employ your data to
                effectively operate and manage surveys, contests, or similar
                initiatives, as part of our legitimate interest in promoting our
                business and the Go Fix It Now Platform. We understand the
                importance of choice, and if you wish to opt out of receiving
                marketing communications from us, we provide clear instructions
                within our communications or you can reach out to our dedicated
                customer support team for assistance. Your satisfaction and
                privacy are of utmost importance to us, and we are committed to
                ensuring that your experience with the Go Fix It Now Platform is
                tailored to your preferences and conducted professionally and
                respectfully.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Employment Information:{" "}
                </b>
                To thoroughly evaluate your job application for available
                positions, we meticulously gather pertinent data including
                employment and education records, transcripts, writing samples,
                and references, as required. This comprehensive approach enables
                us to assess your qualifications with utmost precision and
                professionalism.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Content Information:{" "}
                </b>
                Please consider submitting your inquiries regarding the Go Fix
                It Now Platform using an email or chat message, should you
                prefer. The personal information provided in these
                communications will be utilized to facilitate our prompt and
                efficient response to your query. Additionally, it is important
                to note that any content shared within your exchanges with
                fellow Users on the Go Fix It Now Platform, particularly through
                our chat functionality, will be duly collected.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                When registering an account with us to utilize the services
                offered through the Go Fix It Now Platform, you must provide us
                with certain information. This information enables us to
                effectively create and manage your Go Fix It Now account. As a
                Client, we will require your first and last name, email address,
                and postal code. Additionally, we will collect supplementary
                details to assist in processing your booking request, such as
                information about the task you need, the desired time, date, and
                location of the task, as well as Billing Data. In the case of
                being a Tasker, we will collect your first and last name, email
                address, mobile phone number, and postal code. This information
                is essential for the creation and administration of your Go Fix
                It Now account, as well as facilitating seamless communication
                between you and your Clients through the Go Fix It Now Platform.
                Furthermore, we will also gather information regarding your
                Fixers.
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  4. Information Go Fix It Now Collects Automatically
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  We gather specific information when you utilize the TaskRabbit
                  Platform. The types of information that we automatically
                  collect and have collected, even within the past 12 months,
                  can be categorized as follows:
                </p>
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Service Use Data:{" "}
                </b>
                We collect various types of data to enhance your experience on
                the Go Fix It Now Platform. This includes information about the
                features you utilize, the pages you visit, the emails and
                advertisements you view, the sections of our platform that you
                engage with, the timing of your browsing activities, as well as
                the referring and exiting pages you access. Such data allows us
                to optimize our services and tailor them to your preferences..
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Device Data:{" "}
                </b>
                When accessing and utilizing the Go Fix It Now Platform, it is
                important to be aware that we collect certain information
                automatically through the use of cookies or other technologies.
                This information includes details about the device or browser
                you are using, such as the type of device or browser, the
                operating system it runs on, your internet service provider,
                regional and language settings, as well as device identifiers
                like your IP address and Ad ID. The purpose of collecting this
                information is to enhance the functionality and security of the
                Go Fix It Now Platform. By analysing Device Information, we can
                monitor the geographic regions from which Users navigate our
                platform, allowing us to better tailor our services and address
                any potential security or fraud concerns. We would like to
                emphasize that the use of any IP-masking technologies or similar
                tools, including the TOR network, is strictly prohibited on the
                Go Fix It Now Platform. Employing such technologies may result
                in certain features or functionalities of our platform becoming
                unavailable. At Go Fix It Now, we prioritize the privacy and
                security of our Users, and we handle all collected information
                with the utmost care and by applicable laws and regulations. If
                you have any concerns or questions regarding our data collection
                practices, please do not hesitate to reach out to our dedicated
                support team. Thank you for your understanding and cooperation
                in maintaining a secure and efficient platform for all Go Fix It
                Now Users.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Location Data:{" "}
                </b>
                When accessing the Go Fix It Now Platform through our native
                mobile application, we employ GPS technology (or similar
                technology) to ascertain your present location, with your
                consent as mandated by relevant laws. This enables us to
                identify the city in which you are situated and present a
                pertinent location map. Rest assured, any location data obtained
                through this method will not be shared with other Users.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                Our Cookie Policy provides a comprehensive overview of the
                diverse categories of cookies and similar technologies employed
                by the TaskRabbit Platform, along with the rationale behind
                their usage. Please refer to this policy for detailed
                information
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  5. Go Fix It Now Use of Information
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  We engage in the collection and utilization of information for
                  business and commercial purposes, adhering to the practices
                  outlined in this Privacy Policy. Our objectives for gathering
                  and employing this information are as follows:
                </p>
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                The purpose of operating and providing access to the TaskRabbit
                Platform is to connect Users and facilitate the posting,
                selection, completion, and payment for tasks by Fixers. This is
                done to fulfill our contractual obligations with Users. We also
                utilize your data for billing and fraud prevention, as it is in
                our best interest to ensure a safe and secure environment for
                Clients and Fixers to conduct business, and comply with legal
                requirements. To ensure the safety of our Users both online and
                offline, we may conduct identification and criminal background
                checks on Users, if permitted by local law. This is done to
                advance our legitimate interests and serve the public interest
                of preventing unlawful acts, protecting against dishonesty, and
                maintaining the integrity of the Go Fix It Now Platform,
                particularly considering that Fixers often interact with Clients
                and may enter their homes. We analyze the usage of the Go Fix It
                Now Platform to improve our Go Fix It Now Platform, as it is
                necessary for our legitimate interest in growing our business.
                Additionally, we may contact you via email, SMS, push
                notifications, or other means to provide transactional
                information, administrative notices, marketing notifications,
                offers, and communications relevant to your use of the Go Fix It
                Now Platform. We will obtain your consent when required by
                applicable law, and this communication is necessary for our
                legitimate interests in proper engagement with our Users and
                promoting our business. Customer support is provided to fulfill
                our contractual obligations with Users. We conduct internal
                market research to improve the Go Fix It Now Platform and grow
                our business, as it is in our legitimate interest to do so.
                Troubleshooting problems is also done to ensure a safe and
                secure environment for Users to meet. We assist Users in
                resolving complaints and disputes, as it is necessary for our
                legitimate interests in fostering good relations among Users.
                Enforcing our Terms of Service is also done to protect our
                legitimate interests and ensure compliance with our Agreement.
                We facilitate the provision of insurance and/or protection
                offerings to Fixers through Third-Party Agents. Finally, any
                other uses of data are outlined in the Agreement.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Interest-Based Advertising:{" "}
                </b>
                Advertisements are a fundamental aspect of the online user
                experience, and Go Fix It Now holds the belief that targeted
                advertising significantly enhances this experience. To achieve
                this, Go Fix It Now, along with affiliated third parties, may
                utilize cookies and other advanced technologies to strategically
                place ads in locations where interested users are most likely to
                encounter them. In addition to traditional banner ads, Go Fix It
                Now may also promote products, companies, and events that we
                believe would be of interest to you, leveraging the email
                address and/or phone number you have provided. To facilitate
                this, Tracking Technologies may be incorporated into the Go Fix
                It Now Platform, including our website and emails, as well as
                into our ads displayed on other websites and services. It is
                important to note that certain Tracking Technologies can monitor
                your activities across various platforms and services over some
                time, enabling the association of different devices you may use.
                This allows us to deliver pertinent ads and/or other content to
                you through a practice known as "Interest-Based Advertising".
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Mobile Phone Numbers:{" "}
                </b>
                Go Fix It Now may utilize your mobile phone number to initiate
                telephone calls or send recurring text messages to you,
                employing an automated telephone dialling system or pre-recorded
                voice, to provide you with notifications about Fixers. This may
                include marketing communications, subject to your consent as
                required by relevant legislation, as well as administering the
                Go Fix It Now Platform. For further details regarding our policy
                or to learn how to opt-out, please consult the "Your Rights and
                Choices" section below or Section 9 of our Terms of Service.
                Please note that you may be responsible for standard SMS charges
                and charges based on per-minute usage as imposed by your mobile
                carrier. Additionally, TaskRabbit may communicate with you
                through push notifications, subject to your consent by
                applicable laws. However, you have the option to opt out of
                receiving such notifications on your mobile device. Please be
                aware that data rates may apply.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Call recordings:{" "}
                </b>
                Please be advised that calls made to or received from Go Fix It
                Now or its Third Party Agents are subject to monitoring and
                recording for quality control and training purposes. It is
                important to note that these calls may contain Personal
                Information.
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  6. Information Sharing
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  We only disclose the information we gather about you by this
                  Privacy Policy or as outlined during the collection or sharing
                  process. This includes the following:
                </p>
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Third Party Agents:{" "}
                </b>
                We disclose various types of information, including Identity
                Information, to external entities who handle information on our
                behalf to conduct our business operations. Through contractual
                agreements, we strictly prohibit our Third-Party Agents from
                retaining, utilizing, or divulging any information about you for
                any purposes other than those necessary to fulfil their service
                obligations to us. However, we may permit them to utilize
                non-identifying information (such as aggregated or de-identified
                data) for any lawful purpose, as long as it adheres to the
                constraints imposed by relevant legislation.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                To effectively administer the Go Fix It Now Platform, which
                includes the processing of your transactions, it may be
                necessary for us to disclose your Personal Information to our
                trusted Third Party Agents. These agents, including our
                representatives, vendors, and service providers, will utilize
                this information to furnish us or our Users with the necessary
                services. Rest assured, every effort will be made to ensure the
                utmost security and confidentiality of your data throughout this
                process.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Other Users:{" "}
                </b>
                Go Fix It Now, as a facilitator of communication between Clients
                and Fixers, retains the authority to disclose a User's contact
                details, such as their name, phone number, email address, or
                postal address, to other Users or the authorized representative
                of the recipient User. This disclosure serves two purposes:
                firstly, to assist in the resolution of any investigations or
                disputes that may arise from interactions between Users on the
                Go Fix It Now Platform, and secondly, to facilitate the
                successful completion of a Task. This exchange of information is
                integral to the efficient functioning of the Go Fix It Now
                Platform.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Legal Obligations:{" "}
                </b>
                Go Fix It Now and our Third Party Agents possess the authority
                to divulge your Personal Information or User Generated Content
                to courts, law enforcement agencies, governmental or public
                authorities, tax authorities, or authorized third parties. This
                disclosure will exclusively transpire when mandated or permitted
                by law or when reasonably essential to: (i) fulfil Go Fix It
                Now's legal or regulatory obligations; (ii) adhere to a court
                order, warrant, or subpoena; (iii) respond to a valid legal
                request associated with an investigation into alleged criminal
                or illegal conduct; or (iv) address any other undertaking that
                may potentially subject us to legal or regulatory
                accountability.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                And/or Go Fix It Now. This includes situations where we have
                reasonable grounds to believe that Personal Information is being
                used for fraudulent or illegal activities. We understand the
                importance of privacy and take our responsibility to protect
                Personal Information seriously. However, in certain
                circumstances, we may need to disclose such information to
                address potential harm or infringement on the rights of others.
                This decision will be made at our discretion, based on our
                expertise and understanding of the situation, and in compliance
                with applicable laws. Please note that we will only disclose
                Personal Information from private and public areas of the Go Fix
                It Now Platform when there is a valid court order, warrant, or
                subpoena, or when we have reasonable grounds to believe that
                such disclosure is necessary to prevent harm or protect the
                rights of Users, the general public, and/or Go Fix It Now. We
                value the trust you place in us and assure you that any
                disclosure of Personal Information will be done professionally
                and responsibly. Our commitment to maintaining the
                confidentiality and security of your information remains
                unwavering.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                part of our operational guidelines, we adhere to a policy of
                notifying Users before disclosing their information in response
                to law enforcement requests. However, there are certain
                circumstances in which we may be legally prohibited or
                constrained by a court order from providing such notice.
                Additionally, if we have reasonable grounds to suspect that a
                User's account has been compromised, leading to the notice
                reaching an unintended recipient, or if providing notice would
                be counterproductive or pose a safety risk, we may refrain from
                issuing the notice. Furthermore, in emergencies where prior
                notice is impractical, we may choose to provide the notice after
                the fact. Rest assured, our approach is guided by a commitment
                to professionalism, expertise, and the utmost regard for the
                security and well-being of our Users.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Merger or Acquisition:{" "}
                </b>
                Go Fix It Now retains the authority to disclose information in
                relation to, or throughout discussions concerning, any potential
                or ongoing consolidation, acquisition, divestiture, or any other
                form of asset transfer or business amalgamation, encompassing
                the entirety or a portion of our assets, or the relocation of
                our business operations to another entity.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Public Areas:{" "}
                </b>
                Your profile on the Go Fix It Now platform encompasses
                comprehensive details about you and your business. It comprises
                essential information, including photographs, your extensive
                experience in the industry, your valuable skills and expertise,
                your hourly pay rates, feedback and rating information, and
                other pertinent details. This collection of information is
                presented in your username-associated profile. It is crucial to
                note that the information contained within your User Profile is
                visible to all Users and the general public. This ensures
                transparency and accessibility for all parties involved.
                Additionally, if you opt to post a Job Request through the Go
                Fix It Now Platform, the contents of your listing will be made
                available exclusively to the Fixers you have specifically
                selected on the Go Fix It Now Platform. This ensures that the
                information is shared only with the relevant and appropriate
                individuals, maintaining privacy and efficiency.
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  7. Your Rights and Choices
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  You have the option to decline to receive promotional
                  communications from us, which includes promotional
                  communications associated with the Go Fix It Now Platform.
                  Furthermore, you may request the removal of your Personal
                  Information from our databases or deactivate your account. To
                  execute these actions, you can log in to the Site or App and
                  navigate to the "Account" tab, or you can reach out to us
                  directly.
                </p>
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Account Settings:{" "}
                </b>
                During the registration process, you have the option to indicate
                your preference for receiving marketing correspondence from Go
                Fix It Now. This information is securely stored in your Profile,
                allowing you to conveniently modify it whenever necessary. To
                access this feature, simply login to your account and navigate
                to the "Account" tab. From there, select the "Notifications"
                option to customize your preferred settings.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Push Notifications:{" "}
                </b>
                You have the opportunity to receive updates and relevant offers
                through push notifications on your app. These notifications can
                be easily managed and adjusted in the settings of your mobile
                device whenever you desire.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Corrections to Profile:{" "}
                </b>
                You are granted the privilege to access, modify, and rectify any
                inaccuracies in your Go Fix It Now Profile at your convenience.
                This can be achieved by logging into your account and selecting
                the "Account" tab. Within this section, you will be able to
                review, revise, and rectify your Account information as
                necessary.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                To uphold the integrity of the TaskRabbit Platform, certain
                aspects of your personal information must remain unalterable.
                Specifically, your age is a critical piece of data that cannot
                be modified independently. This measure is necessary to ensure
                compliance with our policy, as individuals who have not reached
                the age of majority in their respective jurisdiction are
                prohibited from registering as Users on our platform.
                Consequently, it is of utmost importance that we implement
                reasonable safeguards to maintain the accuracy of our Users'
                ages. Should you find it necessary to rectify any discrepancies
                in this regard, please do not hesitate to reach out to us at
                privacy@gofixitnow.co.uk. For further information on this
                matter, please refer to Section 11, which specifically addresses
                Children's Privacy.
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                <b className="font-medium text-base text-black">
                  Promotional Communications:{" "}
                </b>
                You have the option to decline receiving promotional
                communications from TaskRabbit by accessing the Account tab in
                your Profile. Furthermore, you can choose to unsubscribe from
                email marketing by clicking on the provided unsubscribe link in
                any message. To opt out of promotional communications sent via
                text message, simply follow the instructions included in those
                messages and text the word "STOP". It is important to note that
                opting out will only apply to the specific phone number used and
                will not impact future subscriptions. If you choose to opt out
                of certain communications, other subscription communications may
                still be received. Even if you decide not to receive promotional
                communications, Go Fix It Now will continue to send you
                non-promotional communications related to your account, Job
                Requests, transactions, servicing, or our ongoing business
                relationship. If you receive unsolicited promotional
                communications from a Go Fix It Now domain, please inform us
                promptly.
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  8. Go Fix It Now's Information Retention Policy
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  We maintain the retention of personal data for the duration of
                  your status as a User, as it is necessary to fulfill our
                  contractual obligations to you. Additionally, we may retain
                  such data for a longer duration if it aligns with our
                  legitimate interests and is required to comply with our legal
                  obligations. In certain cases, we may also retain data that
                  does not directly identify you, such as when it is associated
                  with a randomly generated identifier. This allows us to
                  utilize the data for research purposes and to enhance our
                  services. Rest assured, we implement appropriate measures to
                  ensure that you cannot be re-identified from this data.
                </p>
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  9. Go Fix It Now's Security of Collected Information
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  Your Go Fix It Now account is safeguarded by a password,
                  ensuring that only you and authorized Go Fix It Now personnel
                  possess the ability to access your account details. To uphold
                  this level of protection, it is imperative that you refrain
                  from sharing your password with any individual. Furthermore,
                  if you utilize a shared computer, it is highly recommended
                  that you log out of your Go Fix It Now account and close the
                  browser window before another user logging in. By adhering to
                  this practice, you will effectively shield your personal
                  information, submitted on public terminals, from being
                  disclosed to external entities.
                </p>
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                Go Fix It Now employs a comprehensive range of administrative,
                physical, and technical security measures to safeguard your
                personal information from potential risks such as loss, theft,
                misuse, unauthorized access, disclosure, alteration, and
                destruction. Our organization has a dedicated team responsible
                for upholding this Privacy Policy and other privacy-related
                initiatives. We conduct regular security reviews and ensure that
                all Go Fix It Now employees are well-informed about our
                stringent security protocols. However, it is important to note
                that despite our best efforts, the transmission of data over the
                Internet is not entirely foolproof, and therefore, we cannot
                provide a guarantee regarding the security of your personal
                information.
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  10. Notification of Changes
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  The Privacy Policy of Go Fix It Now is regularly assessed and
                  improved as deemed necessary. This Privacy Policy may be
                  subject to modifications as Go Fix It Now updates and expands
                  its platform. It is our commitment to inform you about any
                  significant changes through email. Additionally, we highly
                  recommend that you periodically review this Privacy Policy to
                  stay informed.
                </p>
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">
                  11. Children’s Privacy
                </h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  If you have any inquiries regarding this Privacy Policy or the
                  manner in which we or our ThirdParty Agents handle your
                  Personal Information, the operations of the Site, your
                  interactions with the Go Fix It Now Platform, or if you
                  encounter any technical issues, please feel free to reach out
                  to us through the provided contact information. We are
                  available to address your concerns and provide assistance.
                </p>
              </div>
            </div>
            <div>
              <div className="py-2.5">
                <h4 className="font-medium text-base">12. Contacting Us</h4>
                <p className="py-3 text-gray leading-7 text-sm">
                  If you have any inquiries regarding this Privacy Policy or how
                  we or our Third-Party Agents handle your Personal Information,
                  the operations of the Site, your interactions with the Go Fix
                  It Now Platform, or if you encounter any technical issues,
                  please feel free to reach out to us through the provided
                  contact information. We are available to address your concerns
                  and provide assistance.
                </p>
              </div>
              <div className="py-2.5 text-sm text-gray leading-7">
                The personnel at Go Fix It Now are committed to providing expert
                assistance and maintaining a professional atmosphere. We
                guarantee prompt responses to all inquiries received via mail or
                email from our esteemed Users regarding privacy matters. This
                includes addressing concerns about the specific categories of
                Personal Information stored within the Go Fix It Now databases,
                as well as accommodating requests to gain knowledge, delete, or
                rectify said Personal Information.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
