import { Disclosure } from "@headlessui/react";
import React, { useRef } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import Slider from "react-slick";
import { IMAGE_URL } from "../../services/Api";
import he from "he";
import noImage from "../../assets/images/no-image.png";
import { ChevronUpIcon } from "lucide-react";
import q1 from "../../assets/images/home/q1.png";
import q2 from "../../assets/images/home/q2.png";
import q3 from "../../assets/images/home/q3.png";
import q4 from "../../assets/images/home/q4.png";

const SameDayService = (props) => {
  const sliderRef = useRef(null);

  var service_settings = {
    dots: false,
    speed: 500,
    infinite: true,
    centerPadding: "20px",
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
  };

  return (
    <>
      <section className="2xl:py-10 xl:py-10 py-8">
        <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
          <h2 className="text-[#20448C] 2xl:text-4xl xl:text-3xl lg:text-2xl  md:text-2xl text-xl font-medium">
            Quick Guide on selecting a service
          </h2>
          <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 gap-3 pt-5">
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6">
              <div
                className="p-4 border border-[#BCBCBC] rounded h-full"
                style={{ boxShadow: "0px 4px 3px #00000030" }}
              >
                <div className="">
                  <img
                    src={q1}
                    alt=""
                    className="2xl:h-12 xl:h-12 lg:h-12 md:h-10 h-8"
                  ></img>
                </div>
                <h5 className="mt-3 2xl:text-base xl:text-base lg:text-base md:text-base text-sm">
                  Select the job category you require
                </h5>
              </div>
            </div>
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6">
              <div
                className="p-4 border border-[#BCBCBC] rounded h-full"
                style={{ boxShadow: "0px 4px 3px #00000030" }}
              >
                <div className="">
                  <img
                    src={q2}
                    alt=""
                    className="2xl:h-12 xl:h-12 lg:h-12 md:h-10 h-8"
                  ></img>
                </div>
                <h5 className="mt-3 2xl:text-base xl:text-base lg:text-base md:text-base text-sm">
                  Then select a services from the category
                </h5>
              </div>
            </div>
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6">
              <div
                className="p-4 border border-[#BCBCBC] rounded h-full"
                style={{ boxShadow: "0px 4px 3px #00000030" }}
              >
                <div className="">
                  <img
                    src={q3}
                    alt=""
                    className="2xl:h-12 xl:h-12 lg:h-12 md:h-10 h-8"
                  ></img>
                </div>
                <h5 className="mt-3 2xl:text-base xl:text-base lg:text-base md:text-base text-sm">
                  Describe the job and upload some pictures
                </h5>
              </div>
            </div>
            <div className="2xl:col-span-3 xl:col-span-3 lg:col-span-3 col-span-6">
              <div
                className="p-4 border border-[#BCBCBC] rounded h-full"
                style={{ boxShadow: "0px 4px 3px #00000030" }}
              >
                <div className="">
                  <img
                    src={q4}
                    alt=""
                    className="2xl:h-12 xl:h-12 lg:h-12 md:h-10 h-8"
                  ></img>
                </div>
                <h5 className="mt-3 2xl:text-base xl:text-base lg:text-base md:text-base text-sm">
                  Request for immediate service or select a date
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="2xl:py-8 ">
        <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
          <div className="flex items-center justify-between">
            <h2 className="text-[#20448C] 2xl:text-4xl xl:text-3xl text-2xl font-medium">
              Our Same days Services
            </h2>
            <div className="flex items-center gap-4 2xl:hidden xl:hidden lg:hidden md:hidden block">
              <button
                className="bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => sliderRef.current.slickPrev()}
              >
                <GrFormPrevious fontSize={25} />
              </button>
              <button
                className="bg-[#20448C] text-white p-1 rounded-full shadow-lg cursor-pointer"
                onClick={() => sliderRef.current.slickNext()}
              >
                <GrFormNext fontSize={25} />
              </button>
            </div>
          </div>
          <div className="my-5">
            {props.isCategoryLoader ? (
              <div className="animate-pulse border-separate border-spacing-y-4 gap-4">
                <div className="my-6 2xl:block xl:block lg:block md:block hidden">
                  <div className="grid grid-cols-12 2xl:gap-5 xl:gap-5 lg:gap-4 gap-3">
                    {[1, 2, 3, 4, 5, 6].map((star) => (
                      <div
                        className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-6"
                        key={star}
                      >
                        <div className="flex justify-center items-center w-full">
                          <div className="bg-gray-300 rounded-lg 2xl:h-[300px] xl:h-[300px] lg:h-[300px] md:h-[250px] h-[260px] w-full"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="my-6 2xl:hidden xl:hidden lg:hidden md:hidden block">
                  <div className="grid grid-cols-12 2xl:gap-5 xl:gap-5 lg:gap-4 gap-3">
                    {[1, 2].map((star) => (
                      <div
                        className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-6"
                        key={star}
                      >
                        <div className="flex justify-center items-center w-full">
                          <div className="bg-gray-300 rounded-lg h-[240px] w-full"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : !props.isCategoryLoader && props.serviceData.length <= 0 ? (
              <div className="h-48 mt-8">
                <div className="flex justify-center items-center text-center h-full w-full">
                  <h6 className="font-medium text-lg">Category Not Found</h6>
                </div>
              </div>
            ) : (
              <>
                <div className="2xl:block xl:block lg:block md:block hidden">
                  <div className="grid grid-cols-12 2xl:gap-5 xl:gap-5 lg:gap-4 gap-3">
                    {props.serviceData.map((item, index) => {
                      return (
                        <div
                          className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-6"
                          key={index}
                        >
                          <div className="relative">
                            <img
                              src={
                                item.serviceImage
                                  ? IMAGE_URL + item.serviceImage
                                  : noImage
                              }
                              alt=""
                              className="object-cover object-center 2xl:h-[350px] xl:h-[320px] lg:h-[300px] md:h-[250px] h-[260px] w-full rounded-lg"
                              crossOrigin="anonymous"
                            />
                            <Disclosure className="">
                              {({ open }) => (
                                <div
                                  className={`absolute bottom-0 left-0 w-full 2xl:p-5 xl:p-5 lg:p-4 p-2 rounded-b-md transition-all duration-300 ${
                                    open
                                      ? "bg-[#1976D2CC] h-full rounded-t-md flex items-center justify-center"
                                      : "bg-[#0E0E0ECC]"
                                  }`}
                                >
                                  <div className="w-full">
                                    <Disclosure.Button className="group flex items-center justify-center w-full gap-2 text-white">
                                      <h4 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg text-base md:truncate">
                                        {item.serviceName
                                          ? `${he.decode(
                                              he.decode(item.serviceName)
                                            )} Service`
                                          : "-"}
                                      </h4>
                                      <ChevronUpIcon
                                        className={`min-w-8 transform transition-transform duration-300 ${
                                          open ? "rotate-180" : "rotate-0"
                                        }`}
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="2xl:mt-4 xl:mt-4 lg:mt-4 mt-3 flex justify-center items-center">
                                      <h5 className="text-white text-center font-extralight text-sm 2xl:tracking-wider xl:tracking-wider lg:tracking-wider 2xl:line-clamp-none xl:line-clamp-none lg:line-clamp-none line-clamp-6">
                                        {item.serviceDescription}
                                      </h5>
                                    </Disclosure.Panel>
                                  </div>
                                </div>
                              )}
                            </Disclosure>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="2xl:hidden xl:hidden lg:hidden md:hidden block">
                  <div className="today-service-slider">
                    <Slider
                      ref={sliderRef}
                      {...{
                        ...service_settings,
                        arrows: false,
                      }}
                    >
                      {props.serviceData.map((item, index) => {
                        return (
                          <div className="px-1" key={index}>
                            <div className="relative">
                              <img
                                src={
                                  item.serviceImage
                                    ? IMAGE_URL + item.serviceImage
                                    : noImage
                                }
                                alt=""
                                className="object-cover object-center h-[250px] w-full rounded-lg"
                                crossOrigin="anonymous"
                              />
                              <Disclosure className="">
                                {({ open }) => (
                                  <div
                                    className={`absolute bottom-0 left-0 w-full px-2 py-2.5 rounded-b-md transition-all duration-300 ${
                                      open
                                        ? "bg-[#1976D2CC] h-full rounded-t-md flex items-center justify-center"
                                        : "bg-[#0E0E0ECC]"
                                    }`}
                                  >
                                    <div className="w-full">
                                      <Disclosure.Button className="group flex items-center justify-center w-full gap-2 text-white">
                                        <h4 className="font-normal text-xs whitespace-nowrap truncate">
                                          {item.serviceName
                                            ? `${he.decode(
                                                he.decode(item.serviceName)
                                              )} Service`
                                            : "-"}
                                        </h4>
                                        <ChevronUpIcon
                                          className={`min-w-6 w-6 transform transition-transform duration-300 ${
                                            open ? "rotate-180" : "rotate-0"
                                          }`}
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="mt-3 flex justify-center items-center">
                                        <h5 className="text-white text-center font-extralight text-xs line-clamp-6">
                                          {item.serviceDescription}
                                        </h5>
                                      </Disclosure.Panel>
                                    </div>
                                  </div>
                                )}
                              </Disclosure>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SameDayService;
