import React from "react";
import logolg from "../../assets/images/home/taskbudy.png";
import { Link } from "react-router-dom";
import appstoreimg from "../../assets/images/newlanding/appstore.png";
import playstoreimg from "../../assets/images/newlanding/playstore.png";
import linkedin from "../../assets/images/footer/linkedin.png";
import instagram from "../../assets/images/footer/instagram.png";
import facebook from "../../assets/images/footer/facebook.png";
import twitter from "../../assets/images/footer/twitter.png";

const Footer = () => {
  return (
    <footer className="bg-[#151517]">
      <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 pt-10 pb-8">
        <div className="grid grid-cols-12 2xl:gap-4 xl:gap-4 lg:gap-4 gap-6">
          <div className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-12 h-full">
            <div className="h-full flex items-center 2xl:justify-start xl:justify-start lgjustify-start justify-center">
              <img src={logolg} alt="" className="h-10"></img>
            </div>
          </div>
          <div className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-12 flex items-center 2xl:justify-start xl:justify-start lg:justify-start justify-center">
            <div>
              <h4 className="text-white text-xl font-medium">Quick Links</h4>
              <ul className="mt-4 2xl:text-base xl:text-base lg:text-base text-sm 2xl:text-start xl:text-start lg:text-start md:text-start text-center 2xl:text-[#BCBCBC] xl:text-[#BCBCBC] lg:text-[#BCBCBC] text-[#F5F5F5] font-light">
                <li className="2xl:mt-1 xl:mt-1 lg:mt-1 md:mt-1.5 mt-2.5">
                  <Link to="/">Home</Link>
                </li>
                <li className="2xl:mt-1 xl:mt-1 lg:mt-1 md:mt-1.5 mt-2.5">
                  <Link to="/services">Our Services</Link>
                </li>
                <li className="2xl:mt-1 xl:mt-1 lg:mt-1 md:mt-1.5 mt-2.5">
                  <a href="https://gofixitnow.online/fixer/signin">
                    Become a Fixer
                  </a>
                </li>
                <li className="2xl:mt-1 xl:mt-1 lg:mt-1 md:mt-1.5 mt-2.5">
                  <Link to="/termsandconditions">Terms & Conditions</Link>
                </li>
                <li className="2xl:mt-1 xl:mt-1 lg:mt-1 md:mt-1.5 mt-2.5">
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="2xl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-4 col-span-12 flex items-center justify-center">
            <div>
              <img
                src={appstoreimg}
                alt="app_store img"
                className="w-[175px]"
              />
              <img
                src={playstoreimg}
                alt="play_store img"
                className="w-[175px] mt-3"
              />
              <div className="flex items-center gap-3 mt-10">
                <img src={linkedin} alt="linkedin" className="h-10"></img>
                <img src={instagram} alt="instagram" className="h-10"></img>
                <img src={facebook} alt="facebook" className="h-10"></img>
                <img src={twitter} alt="twitter" className="h-10"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-center py-5"
        style={{
          fontFamily: "'Barlow', sans-serif",
          color: "#BCBCBC",
        }}
      >
        <h6>© Copyright | Privacy notice | Terms of use</h6>
      </div>
    </footer>
  );
};

export default Footer;
