import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Typography,
  Dialog,
  DialogBody,
  IconButton,
  Avatar,
  Spinner,
} from "@material-tailwind/react";
import { DayPicker } from "react-day-picker";
import { FaCircleChevronRight } from "react-icons/fa6";
import { FaCircleChevronLeft } from "react-icons/fa6";
import Lottie from "react-lottie";
import * as animationData from "../../assets/images/json/success.json";
import { MdClose, MdOutlineChevronLeft } from "react-icons/md";
import locationicon from "../../assets/images/pages/location.png";
import {
  addBookingServiceApi,
  getServiceDetailsApi,
  listAllCityApi,
  getPriceApi,
  getVehicleAndHelperApi,
  getAdminChargeApi,
} from "../../services/ApiServices";
import { IMAGE_URL } from "../../services/Api";
import noImage from "../../assets/images/no-image.png";
import noPicture from "../../assets/images/no-picture.png";
import he from "he";
import { useFormik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import moment from "moment";
import fileImg from "../../assets/images/icons/file.png";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { customGoogleplaceStyles } from "../../common/data";

const generateTimeOptions = () => {
  const interval = 30;
  const timeOptions = [];
  let id = 1;

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      const formattedHour = hour % 12 || 12;
      const formattedMinute =
        minute === 0 ? "00" : String(minute).padStart(2, "0");
      const ampm = hour < 12 ? "AM" : "PM";
      const timeString = `${formattedHour}:${formattedMinute} ${ampm}`;
      const timeValue = `${String(hour).padStart(
        2,
        "0"
      )}:${formattedMinute}:00`;

      timeOptions.push({
        id: id++,
        name: timeString,
        value: timeValue,
      });
    }
  }

  return timeOptions;
};

const BookAppointment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const today = new Date();
  const disabledDays = { before: today };
  const [selectTime, setSelectTime] = useState("");
  const [open, setOpen] = React.useState(false);
  const [serviceInfo, setServiceInfo] = useState([]);
  const [pending, setPending] = useState(true);
  const [loader, setLoader] = useState(false);
  const [cityData, setCityData] = useState([]);
  const todays = new Date();
  const date = moment(todays).format("YYYY-MM-DD");
  const time = moment(todays).format("hh:mm:ss");
  const time_arr = generateTimeOptions();
  const [vehicleData, setVehicleData] = useState([]);
  const [helperData, setHelperData] = useState([]);
  const [chargeInfo, setChargeInfo] = useState({});
  const [totalCharge, setTotalCharge] = useState(0);

  const handleOpen = () => setOpen(!open);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    navigate("/mybooking");
  };

  const getServiceDetails = () => {
    setPending(true);

    getServiceDetailsApi(id)
      .then((res) => {
        const data = res.data.info;
        setServiceInfo(data);
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getServiceDetails();
  }, []);

  const getAllCityInfo = () => {
    listAllCityApi()
      .then((res) => {
        const data = res.data.data;

        setCityData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const getVehicleInfo = () => {
    getVehicleAndHelperApi("vehicle")
      .then((res) => {
        const data = res.data.data;
        setVehicleData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const getHelperInfo = () => {
    getVehicleAndHelperApi("helper")
      .then((res) => {
        const data = res.data.data;
        setHelperData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const getChargetInfo = () => {
    getAdminChargeApi()
      .then((res) => {
        const data = res.data.data;
        setChargeInfo(data);
      })
      .catch((err) => {
        console.log("err >>>>", err);
      });
  };

  useEffect(() => {
    getAllCityInfo();
    getVehicleInfo();
    getHelperInfo();
    getChargetInfo();
  }, []);

  const city_options = cityData.map((item) => {
    return {
      label: item.city,
      value: item.cityId,
    };
  });

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.selection = "Values are undefined";
      return errors;
    }
    if (!values.selection) {
      errors.selection = "You must select either Now or Later";
    } else if (values.selection === "later") {
      if (!values.bookingDate) {
        errors.bookingDate = "Booking date is required";
      }
      if (!values.bookingTime) {
        errors.bookingTime = "Booking time is required";
      }
    }
    return errors;
  };

  const validationSchema = yup.object().shape({
    serviceType: yup.string().required("Please select a service type"),
    pickupAddress: yup.string().when("serviceType", {
      is: (value) => value === "Transport & Moving",
      then: (schema) =>
        schema.required(
          "Pick up address is required when service type is Transport & Moving"
        ),
    }),
    dropoffAddress: yup.string().when("serviceType", {
      is: (value) => value === "Transport & Moving",
      then: (schema) =>
        schema.required(
          "Drop off address is required when service type is Transport & Moving"
        ),
    }),
    vehicleId: yup
      .string()
      .nullable()
      .when("serviceType", {
        is: (value) => value === "Transport & Moving",
        then: (schema) => schema.required("Please specify if you need van"),
      }),
    vehicleFee: yup
      .number()
      .nullable()
      .when("van", {
        is: (value) => !!value,
        then: (schema) =>
          schema.required("Price is required for the selected van"),
      }),
    helperId: yup
      .string()
      .nullable()
      .when("serviceType", {
        is: (value) => value === "Transport & Moving",
        then: (schema) => schema.required("Please specify if you need help"),
      }),
    helperFee: yup
      .number()
      .nullable()
      .when("help", {
        is: (value) => !!value,
        then: (schema) =>
          schema.required("Price is required for the selected helper"),
      }),
    requiredJob: yup.string().required("Please Describe Your required job"),
    requiredMaterials: yup
      .string()
      .required("Enter a required materials")
      .max(500, "Materials Must be 500 characters or less"),
    workingImage: yup
      .mixed()
      .required("Working image is required")
      .test("fileFormat", "Only Image files are allowed", (value) => {
        if (value) {
          const supportedFormats = ["jpg", "jpeg", "png"];
          return supportedFormats.includes(
            value.name.split(".").pop().toLowerCase()
          );
        }
        return true;
      }),
    description: yup.string().required("Please Enter a brief description"),
    city: yup.object().shape({
      label: yup.string().required("City is required"),
      value: yup.string().required("City is required"),
    }),
    address: yup.string().required("Please Enter an address"),
    selection: yup.string().required("You must select either Now or Later"),
    bookingDate: yup.date().nullable(),
    bookingTime: yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceType: serviceInfo?.mainServicedata?.serviceName
        ? he.decode(he.decode(serviceInfo?.mainServicedata?.serviceName))
        : "",
      pickupAddress: "",
      pickupLat: "",
      pickupLng: "",
      dropoffAddress: "",
      dropoffLat: "",
      dropoffLng: "",
      vehicleId: serviceInfo?.vehicleId || "",
      vehicleFee: serviceInfo?.vehicleFee || "",
      helperId: serviceInfo?.helperId || "",
      helperFee: serviceInfo?.helperFee || "",
      requiredJob: "",
      requiredMaterials: "",
      workingImage: "",
      description: "",
      city: null,
      address: "",
      selection: "",
      bookingDate: null,
      bookingTime: "",
    },
    validationSchema: validationSchema,
    validate,
    onSubmit: (values, { resetForm }) => {
      const subservice = serviceInfo?.subServices
        .map((item) => item.serviceId)
        .toString();

      if (values.selection === "later") {
        if (validate()) {
          setLoader(true);

          const date = moment(values.bookingDate).format("YYYY-MM-DD");

          var formdata = new FormData();
          formdata.append("serviceId", serviceInfo.serviceId);
          formdata.append("subServiceId", subservice);
          formdata.append("requiredJob", values.requiredJob);
          formdata.append("requiredMaterials", values.requiredMaterials);
          formdata.append("description", values.description);
          formdata.append("address", values.description);
          formdata.append("workingImage", values.workingImage);
          formdata.append("bookingDate", date);
          formdata.append("bookingTime", values.bookingTime);
          formdata.append("cityId", values.city.value);
          formdata.append("price", values.price);
          formdata.append(
            "totalAmount",
            serviceInfo?.mainServicedata?.serviceName?.startsWith("Transport &")
              ? totalCharge
              : values.price
          );

          if (
            serviceInfo?.mainServicedata?.serviceName?.startsWith("Transport &")
          ) {
            formdata.append("pickupAddress", values.pickupAddress);
            formdata.append("pickupLat", values.pickupLat);
            formdata.append("pickupLong", values.pickupLng);
            formdata.append("dropoffAddress", values.dropoffAddress);
            formdata.append("dropoffLat", values.dropoffLat);
            formdata.append("dropoffLong", values.dropoffLng);
            formdata.append("vehicleId", values.vehicleId);
            formdata.append("vehicleFee", values.vehicleFee);
            formdata.append("helperId", values.helperId);
            formdata.append("helperFee", values.helperFee);
          }

          if (values.price) {
            addBookingServiceApi(formdata)
              .then((res) => {
                const message = res.data.message;
                handleOpen();
                toast.success(message || "Create your account Successfully!!!");
                setLoader(false);
                resetForm();
              })
              .catch((err) => {
                const message = err.response.data.message;
                setLoader(false);
                console.log("err >>>", err);
                toast.error(message || "Somthing went wrong!!");
              });
          }
        }
      } else {
        setLoader(true);

        var formdata = new FormData();
        formdata.append("serviceId", serviceInfo.serviceId);
        formdata.append("subServiceId", subservice);
        formdata.append("requiredJob", values.requiredJob);
        formdata.append("requiredMaterials", values.requiredMaterials);
        formdata.append("description", values.description);
        formdata.append("address", values.description);
        formdata.append("workingImage", values.workingImage);
        formdata.append("bookingDate", date);
        formdata.append("bookingTime", time);
        formdata.append("cityId", values.city.value);
        formdata.append("price", values.price);
        formdata.append(
          "totalAmount",
          serviceInfo?.mainServicedata?.serviceName?.startsWith("Transport &")
            ? totalCharge
            : values.price
        );

        if (
          serviceInfo?.mainServicedata?.serviceName?.startsWith("Transport &")
        ) {
          formdata.append("pickupAddress", values.pickupAddress);
          formdata.append("pickupLat", values.pickupLat);
          formdata.append("pickupLong", values.pickupLng);
          formdata.append("dropoffAddress", values.dropoffAddress);
          formdata.append("dropoffLat", values.dropoffLat);
          formdata.append("dropoffLong", values.dropoffLng);
          formdata.append("vehicleId", values.vehicleId);
          formdata.append("vehicleFee", values.vehicleFee);
          formdata.append("helperId", values.helperId);
          formdata.append("helperFee", values.helperFee);
        }

        addBookingServiceApi(formdata)
          .then((res) => {
            const message = res.data.message;
            handleOpen();

            toast.success(message || "Create your account Successfully!!!");
            setLoader(false);
            resetForm();
          })
          .catch((err) => {
            const message = err.response.data.message;
            setLoader(false);
            console.log("err >>>", err);
            toast.error(message || "Somthing went wrong!!");
          });
      }
    },
  });

  const isVehicleSelected = (id) => formik?.values?.vehicleId === id;
  const isHelperSelected = (id) => formik?.values?.helperId === id;

  function calculateDistance(lat1, lng1, lat2, lng2) {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 6371;
    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  const calculateTotalCharge = (distance) => {
    if (chargeInfo) {
      const hourlyRate = formik?.values?.vehicleFee || 0;
      const driverHelpCharge = Number(formik?.values?.helperFee) || 0;
      const mileageRate = chargeInfo.milestoneCharge || 0;

      const totalHourlyCharge = hourlyRate + driverHelpCharge;
      const mileageCharge = (distance / 10) * mileageRate;
      const total = totalHourlyCharge + mileageCharge;
      const final = total + formik?.values?.price;
      const final_price = Number(final).toFixed(2);

      setTotalCharge(final_price);
    }
  };

  useEffect(() => {
    if (
      formik?.values?.pickupLat &&
      formik?.values?.pickupLng &&
      formik?.values?.dropoffLat &&
      formik?.values?.dropoffLng &&
      formik?.values?.vehicleFee &&
      formik?.values?.helperFee &&
      formik?.values?.price
    ) {
      const calculatedDistance = calculateDistance(
        formik?.values?.pickupLat,
        formik?.values?.pickupLng,
        formik?.values?.dropoffLat,
        formik?.values?.dropoffLng
      );
      calculateTotalCharge(calculatedDistance);
    }
  }, [
    formik?.values?.pickupLat,
    formik?.values?.pickupLng,
    formik?.values?.dropoffLat,
    formik?.values?.dropoffLng,
    formik?.values?.vehicleFee,
    formik?.values?.helperFee,
    formik?.values?.price,
    chargeInfo,
  ]);

  const handleAddressChange = (fieldPrefix) => (value) => {
    if (value) {
      const placeId = value.value.place_id;
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          formik.setFieldValue(`${fieldPrefix}Address`, value.label);
          formik.setFieldValue(`${fieldPrefix}Lat`, lat);
          formik.setFieldValue(`${fieldPrefix}Lng`, lng);
        } else {
          console.error("Error fetching place details:", status);
        }
      });
    } else {
      formik.setFieldValue(`${fieldPrefix}Address`, "");
      formik.setFieldValue(`${fieldPrefix}Lat`, "");
      formik.setFieldValue(`${fieldPrefix}Lng`, "");
    }
  };

  const getPriceDetails = () => {
    if (
      formik?.values?.selection === "later" &&
      formik?.values?.bookingDate &&
      formik?.values?.bookingTime &&
      formik?.values?.city?.value
    ) {
      const date = moment(formik?.values?.bookingDate).format("YYYY-MM-DD");
      const formattedTime = formik?.values?.bookingTime;

      getPriceApi(
        serviceInfo.serviceId,
        formik?.values?.city?.value,
        date,
        formattedTime
      )
        .then((res) => {
          const price = Number(res.data.price);
          formik.setFieldValue("price", price);
        })
        .catch((err) => {
          console.log("err >>>", err);
        });
    } else if (
      formik?.values?.selection === "now" &&
      formik?.values?.city?.value
    ) {
      getPriceApi(
        serviceInfo.serviceId,
        formik?.values?.city?.value,
        date,
        time
      )
        .then((res) => {
          const price = Number(res.data.price);
          formik.setFieldValue("price", price);
        })
        .catch((err) => {
          console.log("err >>>", err);
        });
    }
  };

  useEffect(() => {
    getPriceDetails();
  }, [
    formik?.values?.city,
    formik?.values?.bookingDate,
    formik?.values?.bookingTime,
    formik?.values?.selection,
  ]);

  const handleDayClick = (day) => {
    formik.setFieldValue("bookingDate", day);
  };

  const handleTimeClick = (time) => {
    setSelectTime(time);
    formik.setFieldValue("bookingTime", time);
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("workingImage", "");
  };

  return (
    <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:mt-24 xl:mt-24 lg:mt-24 mt-24">
      <div className="flex items-center gap-2 my-3">
        <IconButton
          variant="outlined"
          size="sm"
          className="rounded-full border-[#B4B4B4]"
          onClick={(e) => navigate("/profile")}
        >
          <MdOutlineChevronLeft className="w-6 h-6" color="#071D33" />
        </IconButton>
      </div>
      <div className="py-5 text-center">
        <h1 className="2xl:text-4xl xl:text-4xl lg:text-3xl text-2xl font-medium uppercase">
          Book Your <b className="text-info font-medium">Services</b>
        </h1>
      </div>
      <div className="py-6">
        {pending ? (
          <div className="flex animate-pulse items-center gap-8">
            <div className="grid h-40 w-40 place-items-center rounded-lg bg-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-12 w-12 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </div>
            <div className="w-max">
              <Typography
                as="div"
                variant="h1"
                className="mb-4 h-3 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
              <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-2 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
              >
                &nbsp;
              </Typography>
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-6">
            <Avatar
              size="xxl"
              variant="rounded"
              src={
                serviceInfo.bookingto
                  ? IMAGE_URL + serviceInfo.bookingto.profilePic
                  : noImage
              }
              alt="profile"
              crossOrigin="anonymous"
            />
            <div className="flex w-full flex-col gap-0.5">
              <div className="flex items-center justify-between">
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className="font-medium text-lg"
                >
                  {`${
                    serviceInfo.bookingto
                      ? serviceInfo?.bookingto?.firstName
                      : "-"
                  } ${
                    serviceInfo.bookingto
                      ? serviceInfo?.bookingto?.lastName
                      : ""
                  }`}
                </Typography>
              </div>
              <div className="items-center mb-2">
                <div className="flex items-center">
                  <div className=" flex items-center gap-0">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <span
                        key={star}
                        className="cursor-pointer"
                        style={{
                          cursor: "pointer",
                          color:
                            serviceInfo?.review[0]?.reviewStar >= star
                              ? "#FFC107"
                              : "#D7D7D7",
                          fontSize: "25px",
                          padding: "0px",
                        }}
                      >
                        {" "}
                        ★{" "}
                      </span>
                    ))}
                  </div>
                  <p className="text-black ml-2">
                    {Number(serviceInfo?.review[0]?.reviewStar).toFixed(1)}
                  </p>
                </div>
                <div className="flex">
                  <img
                    src={locationicon}
                    alt=""
                    className="w-4.5 h-5 p-0.5"
                  ></img>
                  <p className="font-normal text-sm my-0.5 ml-1">
                    {serviceInfo?.bookingto?.streetAddress}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="py-8">
        {pending ? (
          <div className="flex animate-pulse items-center grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-5 md:gap-4 gap-4">
            <div className="col-span-12 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-4">
              <div className="2xl:w-[350px] 2xl:h-[350px] xl:w-[312px] xl:h-[312px] lg:w-[312px] lg:h-[312px] w-full h-full place-items-center rounded-lg bg-gray-300"></div>
            </div>
            <div className="col-span-12 2xl:col-span-9 xl:col-span-9 lg:col-span-9 md:col-span-8">
              <div className="w-max">
                <Typography
                  as="div"
                  variant="h1"
                  className="mb-4 h-3 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
                >
                  &nbsp;
                </Typography>
                <Typography
                  as="div"
                  variant="paragraph"
                  className="mb-2 h-2 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-full bg-gray-300"
                >
                  &nbsp;
                </Typography>
                <div className="py-3 grid grid-cols-12 gap-3">
                  <div className="col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer py-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto">
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-20 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-xl bg-gray-300"
                    >
                      &nbsp;
                    </Typography>
                  </div>
                  <div className="col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer py-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto">
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-20 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-xl bg-gray-300"
                    >
                      &nbsp;
                    </Typography>
                  </div>
                  <div className="col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer py-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto">
                    <Typography
                      as="div"
                      variant="paragraph"
                      className="mb-2 h-20 2xl:w-56 xl:w-52 lg:w-52 md:w-48 w-44 rounded-xl bg-gray-300"
                    >
                      &nbsp;
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-5 md:gap-4 gap-4">
            <div className="col-span-12 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-4">
              <div className="">
                <img
                  src={
                    serviceInfo.mainServicedata
                      ? IMAGE_URL + serviceInfo.mainServicedata.serviceImage
                      : noPicture
                  }
                  alt=""
                  className="2xl:w-[350px] 2xl:h-[350px] xl:w-[312px] xl:h-[312px] lg:w-[312px] lg:h-[312px] w-full h-full object-cover"
                  crossOrigin="anonymous"
                ></img>
              </div>
            </div>
            <div className="col-span-12 2xl:col-span-9 xl:col-span-9 lg:col-span-9 md:col-span-8">
              <div className="">
                <h3 className="text-primary 2xl:text-3xl xl:text-3xl lg:text-2xl md:text-2xl text-xl font-semibold">
                  {serviceInfo?.mainServicedata?.serviceName}
                </h3>
                <div className="py-2">
                  <p className="text-[#363636] tracking-wide">
                    {serviceInfo?.mainServicedata?.serviceDescription
                      ? he.decode(
                          he.decode(
                            serviceInfo?.mainServicedata?.serviceDescription
                          )
                        )
                      : "-"}
                  </p>
                </div>
                <div className="py-3 grid grid-cols-12 gap-3">
                  {serviceInfo?.subServices?.map((category, i) => {
                    return (
                      <div
                        key={i}
                        className={`bg-primary border-[#C2CDFF] border col-span-6 2xl:col-span-2 xl:col-span-3 lg:col-span-4 md:col-span-4 cursor-pointer p-3 flex justify-start items-center 2xl:h-[82px] xl:h-[82px] md:h-auto rounded-xl`}
                      >
                        <div className="bg-white p-1 rounded-md">
                          <img
                            alt="category_image"
                            src={
                              category?.servicedata?.serviceIcon
                                ? IMAGE_URL + category?.servicedata?.serviceIcon
                                : noPicture
                            }
                            className="2xl:max-w-[38px] 2xl:h-[40px] xl:max-w-10 xl:h-8 lg:max-w-10 md:max-w-8 max-w-8"
                            crossOrigin="anonymous"
                          ></img>
                        </div>
                        <h6 className="text-white text-sm font-medium ml-2 break-all">
                          {he.decode(
                            category?.servicedata
                              ? category?.servicedata?.serviceName
                              : ""
                          )}
                        </h6>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {serviceInfo?.mainServicedata?.serviceName?.startsWith(
          "Transport &"
        ) ? (
          <div className="mt-10 2xl:px-16 xl:px-16 lg:px-16 md:px-10 px-8 py-6 border border-[#DDDDDD] bg-[#0F4C8B] text-[#EEEEEE] rounded-[25px]">
            <div className="grid grid-cols-12 gap-3 my-6">
              <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                <label
                  className="leading-6 text-white mb-2"
                  htmlFor="regular-form-1"
                >
                  Pick up from
                </label>
                <div className="mt-1">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCe2wH4M3xveykS6FaY2egEbmVnYVKj4qg"
                    selectProps={{
                      placeholder: "Star typing address or postcode...",
                      isClearable: true,
                      styles: customGoogleplaceStyles,
                      name: "pickupAddress",
                      onChange: handleAddressChange("pickup"),
                    }}
                    className="w-full"
                  />
                  {formik.touched.pickupAddress &&
                    formik.errors.pickupAddress && (
                      <p className="text-danger text-sm mt-2">
                        {formik.errors.pickupAddress}
                      </p>
                    )}
                </div>
              </div>
              <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                <label
                  className="leading-6 text-white mb-2"
                  htmlFor="regular-form-1"
                >
                  Drop off to
                </label>
                <div className="mt-1">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCe2wH4M3xveykS6FaY2egEbmVnYVKj4qg"
                    selectProps={{
                      placeholder: "Star typing address or postcode...",
                      isClearable: true,
                      styles: customGoogleplaceStyles,
                      name: "dropoffAddress",
                      onChange: handleAddressChange("dropoff"),
                    }}
                    className="w-full"
                  />
                  {formik.touched.dropoffAddress &&
                    formik.errors.dropoffAddress && (
                      <p className="text-danger text-sm mt-2">
                        {formik.errors.dropoffAddress}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div>
              <h5 className="2xl:text-lg xl:text-lg lg:text-lg text-base">
                Which Vehicle Do You Need?
              </h5>
              <div className="grid grid-cols-12 gap-3 mt-6">
                {vehicleData.map((item, index) => {
                  return (
                    <div
                      className="col-span-6 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3"
                      key={index}
                    >
                      <label
                        htmlFor={`vehicle-input-${index}`}
                        className="cursor-pointer"
                      >
                        <div className="flex items-center space-x-2">
                          <input
                            id={`vehicle-input-${index}`}
                            name="vehicleId"
                            type="radio"
                            value={item.id}
                            checked={isVehicleSelected(item.id)}
                            className="w-4 h-4 w-5 h-5 border rounded-full border-blue-gray-200 cursor-pointer transition-all text-red-500 checked:border-red-500 checked:before:bg-red-500"
                            onChange={(e) => {
                              const selectedVan = vehicleData.find(
                                (item) => item.id === parseInt(e.target.value)
                              );
                              formik.setFieldValue(
                                "vehicleFee",
                                selectedVan.price
                              );
                              formik.setFieldValue("vehicleId", selectedVan.id);
                            }}
                          />
                          <span className="text-sm">{item.name}</span>
                        </div>
                        <img
                          src={IMAGE_URL + item.image}
                          alt=""
                          className="2xl:h-[160px] xl:h-[150px] lg:h-[140px] h-[100px]"
                          crossOrigin="anonymous"
                        ></img>
                        <span className="text-sm mt-2">
                          Price : £{item.price || 0}
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
              {formik.touched.vehicleId && formik.errors.vehicleId && (
                <p className="text-danger text-sm mt-2">
                  {formik.errors.vehicleId}
                </p>
              )}
            </div>
            <div className="mt-4">
              <h5 className="2xl:text-lg xl:text-lg lg:text-lg text-base">
                Will You Need Help With Loading And Unloading?
              </h5>
              <div className="grid grid-cols-12 gap-3 mt-6">
                {helperData.map((item, index) => {
                  return (
                    <div
                      className="col-span-6 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3"
                      key={index}
                    >
                      <label
                        htmlFor={`help-input-${index}`}
                        className="cursor-pointer"
                      >
                        <div className="flex items-start space-x-2">
                          <input
                            id={`help-input-${index}`}
                            name="help"
                            type="radio"
                            value={item.id}
                            checked={isHelperSelected(item.id)}
                            className="w-4 h-4 w-5 h-5 border rounded-full border-blue-gray-200 cursor-pointer transition-all text-red-500 checked:border-red-500 checked:before:bg-red-500"
                            onChange={(e) => {
                              const selectedVan = helperData.find(
                                (item) => item.id === parseInt(e.target.value)
                              );
                              formik.setFieldValue(
                                "helperFee",
                                selectedVan.price
                              );
                              formik.setFieldValue("helperId", selectedVan.id);
                            }}
                          />
                          <span className="text-sm">{item.name}</span>
                        </div>
                        <img
                          src={IMAGE_URL + item.image}
                          alt=""
                          className="2xl:h-[160px] xl:h-[150px] lg:h-[140px] h-[100px]"
                          crossOrigin="anonymous"
                        ></img>
                        <span className="text-sm mt-2">
                          Price : £{item.price || 0}
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
              {formik.touched.helperId && formik.errors.helperId && (
                <p className="text-danger text-sm mt-2">
                  {formik.errors.helperId}
                </p>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="2xl:py-8 xl:py-8 lg:py-6 py-4 w-auto">
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-6">
              <label
                className="block font-medium leading-6 text-gray-900 mb-2"
                htmlFor="regular-form-1"
              >
                Something else
              </label>
              <input
                id="regular-form-1"
                name="requiredJob"
                type="text"
                placeholder="Describe your required job"
                className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.requiredJob}
              ></input>
              {formik.touched.requiredJob && formik.errors.requiredJob ? (
                <div className="text-danger my-1 text-sm">
                  {formik.errors.requiredJob}
                </div>
              ) : null}
            </div>
            <div className="mt-3">
              <label
                className="block font-medium leading-6 text-gray-900 mb-2"
                htmlFor="regular-form-2"
              >
                Do you require materials
              </label>
              <textarea
                id="regular-form-2"
                name="requiredMaterials"
                type="text"
                placeholder="Enter here if materials are required"
                rows="6"
                className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.requiredMaterials}
              ></textarea>
              {formik.touched.requiredMaterials &&
              formik.errors.requiredMaterials ? (
                <div className="text-danger my-1 text-sm">
                  {formik.errors.requiredMaterials}
                </div>
              ) : null}
            </div>
            <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4 mt-3 w-auto">
              <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6">
                <label
                  className="block font-medium leading-6 text-gray-900 mb-2"
                  htmlFor="regular-form-12"
                >
                  Upload photos
                </label>
                <p className="text-sm my-2 text-info">
                  {formik?.values?.workingImage?.name || ""}
                </p>
                {formik.values.workingImage ? (
                  <div className="border border-dashed border-2 border-[#D9D9D9] rounded-md p-4 flex justify-center items-center">
                    {formik?.values.workingImage?.type?.split("/")?.pop() ===
                      "jpeg" ||
                    formik?.values.workingImage?.type?.split("/")?.pop() ===
                      "png" ||
                    formik?.values.workingImage?.type?.split("/")?.pop() ===
                      "svg" ||
                    formik?.values.workingImage?.type?.split("/")?.pop() ===
                      "png" ||
                    formik?.values.workingImage?.type?.split("/")?.pop() ===
                      "jpg" ||
                    formik?.values.workingImage?.type?.split("/")?.pop() ===
                      "gif" ? (
                      <div className="cursor-pointer image-fit">
                        <div className="m-2 relative">
                          <img
                            alt="document"
                            className="rounded-lg h-[300px] w-full"
                            src={
                              formik?.values?.workingImage
                                ? URL.createObjectURL(
                                    formik.values.workingImage
                                  )
                                : noImage
                            }
                          />
                          <button
                            className="absolute -right-2 -top-1 h-5 w-5 bg-[#fff] rounded-full flex justify-center items-center"
                            onClick={handleRemoveImage}
                            type="button"
                          >
                            <MdClose className="w-4 h-4 font-bold" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="cursor-pointer image-fit">
                        <div className="m-2 relative">
                          <img
                            alt="document"
                            className="rounded-lg h-20 w-20"
                            src={fileImg}
                          />
                          <button
                            className="absolute -right-2 -top-1 h-5 w-5 bg-[#fff] rounded-full flex justify-center items-center"
                            onClick={handleRemoveImage}
                            type="button"
                          >
                            <MdClose className="w-4 h-4 font-bold" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="h-52 rounded-md border border-dashed border-2 border-[#D9D9D9] text-center flex justify-center items-center">
                    <div>
                      <h6 className="font-bold text-sm">
                        Drag and Drop Image & Upload Image
                      </h6>
                      <div className="mt-6">
                        <label
                          htmlFor="workingImage"
                          className="py-2.5 px-5 text-sm rounded-md border border-dashed border-2 border-[#D9D9D9] text-secondary"
                        >
                          Select file
                          <input
                            id="workingImage"
                            type="file"
                            name="workingImage"
                            accept="image/*"
                            className="w-1 h-1 opacity-0"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "workingImage",
                                event.currentTarget.files[0]
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {formik.touched.workingImage && formik.errors.workingImage ? (
              <div className="text-danger my-1 text-sm">
                {formik.errors.workingImage}
              </div>
            ) : null}
            <div className="mt-3">
              <label
                className="block font-medium leading-6 text-gray-900 mb-2"
                htmlFor="regular-form-2"
              >
                Description
              </label>
              <textarea
                id="regular-form-2"
                name="description"
                type="text"
                placeholder="Brief description of required work"
                rows="4"
                className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              ></textarea>
              {formik.touched.description && formik.errors.description ? (
                <div className="text-danger my-1 text-sm">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
            <div className="mt-3">
              <label
                className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                htmlFor="regular-form-5"
              >
                City
              </label>
              <Select
                components={animatedComponents}
                options={city_options}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                name="city"
                value={formik.values.city}
                onChange={(value) => formik.setFieldValue("city", value)}
                onBlur={() => formik.setFieldTouched("city", true)}
                placeholder="Select City"
                className={`custom-select ${
                  formik.touched.city && formik.errors.city ? "invalid" : ""
                }`}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="text-danger my-1 text-sm">
                  {formik.errors.city}
                </div>
              ) : null}
            </div>
            <div className="mt-3">
              <label
                className="block font-medium leading-6 text-gray-900 mb-2"
                htmlFor="regular-form-2"
              >
                Address
              </label>
              <textarea
                id="regular-form-2"
                name="address"
                type="text"
                placeholder="Enter your address"
                rows="4"
                className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              ></textarea>
              {formik.touched.address && formik.errors.address ? (
                <div className="text-danger my-1 text-sm">
                  {formik.errors.address}
                </div>
              ) : null}
            </div>
            <div className="mt-3">
              <div className="flex gap-3">
                <Checkbox
                  id="horizontal-list-react1"
                  color="indigo"
                  name="selection"
                  value="now"
                  checked={formik.values.selection === "now"}
                  onChange={() => formik.setFieldValue("selection", "now")}
                  label={
                    <Typography color="black" className="flex font-medium">
                      Now
                    </Typography>
                  }
                />
                <Checkbox
                  id="horizontal-list-react2"
                  color="indigo"
                  name="selection"
                  value="later"
                  checked={formik.values.selection === "later"}
                  onChange={() => formik.setFieldValue("selection", "later")}
                  label={
                    <Typography color="black" className="flex font-medium">
                      Later
                    </Typography>
                  }
                />
              </div>
              {formik.errors.selection ? (
                <div className="text-danger my-1 text-sm">
                  {formik.errors.selection}
                </div>
              ) : null}
              {formik.values.selection === "later" ? (
                <p className="my-2 text-[#000000]">
                  Please select your time and date when you require the service
                  one of our Fixers will be with you on you selected time and
                  date
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="mt-3 font-medium text-primary">
              Estimate Cost : £
              {`${
                serviceInfo?.mainServicedata?.serviceName?.startsWith(
                  "Transport &"
                )
                  ? totalCharge
                  : formik?.values?.price || 0
              }`}
            </div>
            {formik.values.selection === "later" ? (
              <div className="2xl:py-6 xl:py-6 lg:py-6 py-4">
                <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4">
                  <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 w-full">
                    <div className="table-row-shadow 2xl:p-4 xl:p-4 lg:p-4 p-2 rounded-[20px] w-auto">
                      <DayPicker
                        mode="single"
                        disabled={disabledDays}
                        selected={formik.values.bookingDate}
                        onSelect={handleDayClick}
                        showOutsideDays
                        className="border-0"
                        required={true}
                        classNames={{
                          caption:
                            "flex justify-center py-4 mb-4 relative items-center border-b-2",
                          caption_label: "text-sm font-medium text-gray-900",
                          nav: "flex items-center",
                          nav_button:
                            "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-full transition-colors duration-300",
                          nav_button_previous: "absolute left-1.5",
                          nav_button_next: "absolute right-1.5",
                          table: "w-full border-collapse",
                          head_row: "flex font-medium text-gray-900",
                          head_cell:
                            "2xl:m-0.5 xl:m-0.5 lg:m-0.5 md:m-0.5 w-full font-normal text-sm",
                          row: "flex w-full mt-2",
                          cell: "text-gray-600 rounded-full 2xl:h-9 xl:h-9 lg:h-9 md:h-8 h-7 w-full text-center text-sm p-0 m-0.5 xl:m-0.5 lg:m-0.5 md:m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-transperent [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-transperent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                          day: "2xl:h-9 2xl:w-9 xl:h-9 xl:w-9 lg:h-9 lg:w-9 md:h-8 md:w-8 h-7 w-7 p-0 font-normal",
                          day_range_end: "day-range-end",
                          day_selected:
                            "rounded-full bg-primary text-white hover:bg-primary hover:text-white focus:bg-primary focus:text-white",
                          day_today:
                            "border border-primary text-black rounded-full",
                          day_outside:
                            "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                          day_disabled: "text-gray-500 opacity-50",
                          day_hidden: "invisible",
                        }}
                        components={{
                          IconLeft: ({ ...props }) => (
                            <FaCircleChevronLeft
                              {...props}
                              className="2xl:h-6 2xl:w-6 xl:h-6 xl:w-6 h-5 w-5 stroke-2"
                              color="#37489B"
                            />
                          ),
                          IconRight: ({ ...props }) => (
                            <FaCircleChevronRight
                              {...props}
                              className="2xl:h-6 2xl:w-6 xl:h-6 xl:w-6 h-5 w-5 stroke-2"
                              color="#37489B"
                            />
                          ),
                        }}
                      />
                    </div>
                    {formik.touched.bookingDate && formik.errors.bookingDate ? (
                      <div className="text-danger my-1 text-sm">
                        {formik.errors.bookingDate}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                    <div className="2xl:p-4 xl:p-4 lg:p-4 md:p-4 py-5">
                      <h5 className="text-lg">Select time</h5>
                      <div className="py-3 h-[323px] overflow-auto">
                        <div className="grid grid-cols-12 gap-2.5">
                          {time_arr.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="col-span-6 2xl:col-span-3 xl:col-span-4 lg:col-span-4 md:col-span-6"
                              >
                                <div
                                  onClick={(e) => handleTimeClick(item.value)}
                                  className={`2xl:text-base xl:text-base lg:text-base md:text-sm text-sm cursor-pointer rounded-lg text-center 2xl:py-3 xl:py-3 py-2 2xl:px-6 xl:px-6 px-5 ${
                                    selectTime === item.value
                                      ? "bg-primary text-white"
                                      : "border border-primary"
                                  }`}
                                >
                                  <h5 className="2xl:text-base xl:text-base lg:text-sm md:text-base text-sm">
                                    {item.name}
                                  </h5>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {formik.touched.bookingTime &&
                      formik.errors.bookingTime ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.bookingTime}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="2xl:py-5 xl:py-5 lg:py-5 md:py-3 py-2 flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-center items-center justify-center">
              <Button
                className="flex items-center justify-center capitalize bg-primary text-base font-normal 2xl:min-w-[380px] xl:min-w-[360px] lg:min-w-[340px] md:min-w-[320px] min-w-[260px]"
                type="submit"
                disabled={loader}
              >
                <span>Submit</span>{" "}
                {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        open={open}
        handler={handleOpen}
        size="sm"
        className="rounded-none 2xl:min-w-[35%] xl:min-w-[35%] xl:max-w-[40%] lg:min-w-[40%] md:min-w-[50%] min-w-[90%]"
      >
        <DialogBody className="">
          <div className="my-4 flex justify-center items-center">
            <Lottie options={defaultOptions} height={144} width={144} />
          </div>
          <div className="my-3">
            <h6 className="text-primary font-medium text-center 2xl:text-xl xl:text-xl lg:text-xl text-lg">
              Your Booking Request sent
            </h6>
            <h4 className="text-primary font-semibold text-center text-2xl py-3">
              Successful!
            </h4>
          </div>
          <div className="flex justify-center items-center py-5">
            <Button
              className="text-sm py-3 rounded-xl w-[50%] capitalize font-medium bg-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Done
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default BookAppointment;
