import React, { useState, useEffect } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Card,
  Button,
  Typography,
  CardBody,
  CardFooter,
  Avatar,
  Tooltip,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { listBookingServiceApi } from "../../services/ApiServices";
import moment from "moment";
import { IMAGE_URL } from "../../services/Api";
import noImage from "../../assets/images/no-image.png";
import he from "he";
import { IoChatbubblesOutline } from "react-icons/io5";

const TABLE_HEAD = ["ID", "Date", "Service", "Status", ""];
const TABLE_ALL_HEAD = ["ID", "Date", "Fixer", "Service", "Status", ""];

const MyBookings = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pending, setPending] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [pageCount, setPageCount] = useState();

  const getmyBookingList = () => {
    setPending(true);
    const params = {
      pageNo: pageNo,
      bookingStatus: activeTab,
    };
    listBookingServiceApi(params)
      .then((res) => {
        const data = res.data.info;
        const totalPages = res.data.totalPages;
        setPending(false);

        setBookingList(data);
        setPageCount(totalPages);
      })
      .catch((err) => {
        setPending(false);
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getmyBookingList();
  }, [pageNo, activeTab]);

  const handleNext = () => {
    if (pageCount !== pageNo) {
      setPageNo(pageNo + 1);
    }
  };

  const handleBack = () => {
    if (pageNo !== 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleChat = (data) => {
    const other_id = data.bookingTo;
    const obj = {
      other_id: other_id,
      chat_id: data.bookingChat?.chat_id,
      firstName: data?.bookingby?.firstName,
      lastName: data?.bookingby?.lastName,
      profilePic: data?.bookingby?.profilePic,
      bookingStatus: data?.bookingStatus,
      bookingId: data?.bookingId,
    };

    navigate("/messages", { state: { Data: obj } });
  };

  return (
    <div className="2xl:mt-28 xl:mt-28 lg:mt-28 mt-24 mb-8">
      <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
        <Tabs value={activeTab} className="">
          <TabsHeader
            className="mx-5 rounded-xl flex-wrap items-center bg-primary p-4 bg-opacity-100 z-0"
            indicatorProps={{
              className: "bg-primary shadow-none",
            }}
          >
            <Tab
              value={0}
              onClick={() => {
                setActiveTab(0);
                setPageNo(1);
                setBookingList([]);
              }}
              className={`w-fit mx-2 font-medium xl:text-base text-sm ${
                activeTab === 0 ? "text-[#22d4f5] " : "text-white"
              }`}
            >
              <div className="flex items-center gap-2">New</div>
            </Tab>
            <Tab
              value={1}
              onClick={() => {
                setActiveTab(1);
                setPageNo(1);
                setBookingList([]);
              }}
              className={`w-fit mx-2 font-medium xl:text-base text-sm ${
                activeTab === 1 ? "text-[#22d4f5] " : "text-white"
              }`}
            >
              <div className="flex items-center gap-2">In Progress</div>
            </Tab>
            <Tab
              value={2}
              onClick={() => {
                setActiveTab(2);
                setPageNo(1);
                setBookingList([]);
              }}
              className={`w-fit mx-2 font-medium xl:text-base text-sm ${
                activeTab === 2 ? "text-[#22d4f5] " : "text-white"
              }`}
            >
              <div className="flex items-center gap-2">Completed</div>
            </Tab>
            <Tab
              value={3}
              onClick={() => {
                setActiveTab(3);
                setPageNo(1);
                setBookingList([]);
              }}
              className={`w-fit mx-2 font-medium xl:text-base text-sm ${
                activeTab === 3 ? "text-[#22d4f5] " : "text-white"
              }`}
            >
              <div className="flex items-center gap-2">Canceled</div>
            </Tab>
          </TabsHeader>
          <TabsBody className="">
            <TabPanel value={0} className="p-0">
              <Card className="h-full w-full">
                <CardBody className="p-0 overflow-x-auto overflow-x-scroll">
                  {pending === true ? (
                    <div className="px-5 max-w-full animate-pulse border-separate border-spacing-y-4">
                      {[1, 2, 3, 4].map((star) => (
                        <Typography
                          key={star}
                          as="div"
                          variant="h1"
                          className="h-[80px] w-full rounded-lg bg-gray-300 my-3"
                        >
                          &nbsp;
                        </Typography>
                      ))}
                    </div>
                  ) : pending === false && bookingList.length ? (
                    <div className="grid grid-cols-12">
                      <div className="col-span-12 px-5 overflow-auto">
                        <table className="w-full table-auto text-left border-separate border-spacing-y-4">
                          <thead className="">
                            <tr className="table-row-shadow bg-white rounded-lg">
                              {TABLE_HEAD.map((head) => (
                                <th
                                  key={head}
                                  className="px-4 py-6 h-[79px] first:rounded-l-xl last:rounded-r-xl"
                                >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="text-[#000000] font-medium text-[15px] leading-none opacity-70 whitespace-nowrap"
                                  >
                                    {head}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {bookingList.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="h-[80px] table-row-shadow bg-white rounded-lg"
                                >
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black"
                                    >
                                      {item.bookingId}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black whitespace-nowrap"
                                    >
                                      {moment(item.bookingDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black whitespace-nowrap"
                                    >
                                      {he.decode(
                                        item?.mainServicedata?.serviceName ||
                                          "-"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-pending whitespace-nowrap"
                                    >
                                      {item.bookingStatus === 0
                                        ? "Waiting for Confirmation"
                                        : ""}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Tooltip content="View Details">
                                      <div
                                        className="text-primary text-sm font-medium underline underline-offset-2 cursor-pointer whitespace-nowrap"
                                        onClick={(e) =>
                                          navigate(
                                            `/bookingdetails/${item.bookingId}`
                                          )
                                        }
                                      >
                                        View Details
                                      </div>
                                    </Tooltip>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="py-5 text-center justify-center items-center flex h-96">
                      <h6 className="font-medium text-black">
                        No Booking found!!!
                      </h6>
                    </div>
                  )}
                </CardBody>
                {bookingList.length > 0 && pending === false ? (
                  <CardFooter className="flex items-center justify-end p-4">
                    <div className="flex gap-2">
                      <Button
                        variant="filled"
                        size="sm"
                        className="bg-[#37489B] capitalize font-normal text-sm tracking-wider"
                        onClick={(e) => handleBack(e)}
                        disabled={pageNo === 1 ? true : false}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outlined"
                        size="sm"
                        className="border border-[#37489B] text-[#37489B] capitalize font-medium text-sm tracking-wider"
                        onClick={(e) => handleNext(e)}
                        disabled={pageCount === pageNo}
                      >
                        Next
                      </Button>
                    </div>
                  </CardFooter>
                ) : null}
              </Card>
            </TabPanel>
            <TabPanel value={1} className="p-0">
              <Card className="h-full w-full">
                <CardBody className="p-0 py-4 overflow-x-auto overflow-x-scroll">
                  {pending === true ? (
                    <div className="px-5 max-w-full animate-pulse border-separate border-spacing-y-4">
                      {[1, 2, 3, 4].map((star) => (
                        <Typography
                          key={star}
                          as="div"
                          variant="h1"
                          className="h-[80px] w-full rounded-lg bg-gray-300 my-3"
                        >
                          &nbsp;
                        </Typography>
                      ))}
                    </div>
                  ) : pending === false && bookingList.length ? (
                    <div className="grid grid-cols-12">
                      <div className="col-span-12 px-5 overflow-auto">
                        <table className="w-full table-auto text-left border-separate border-spacing-y-4">
                          <thead className="">
                            <tr className="table-row-shadow bg-white rounded-lg">
                              {TABLE_ALL_HEAD.map((head) => (
                                <th
                                  key={head}
                                  className="2xl:px-4 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 h-[79px] first:rounded-l-xl last:rounded-r-xl"
                                >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="text-[#000000] font-medium text-[15px] leading-none opacity-70 whitespace-nowrap"
                                  >
                                    {head}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {bookingList.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="h-[80px] table-row-shadow bg-white rounded-lg"
                                >
                                  <td className="2xl:px-4 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4"
                                    >
                                      {item.bookingId}
                                    </Typography>
                                  </td>
                                  <td className="2xl:px-4 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4 whitespace-nowrap"
                                    >
                                      {moment(item.bookingDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className="2xl:px-4 min-w-[250px] 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 first:rounded-l-lg last:rounded-r-lg">
                                    <div className="flex items-center gap-2">
                                      <div className="w-14 h-14">
                                        <Avatar
                                          src={
                                            item?.bookingto?.profilePic
                                              ? IMAGE_URL +
                                                item?.bookingto?.profilePic
                                              : noImage
                                          }
                                          alt="avatar"
                                          crossOrigin="anonymous"
                                          className="w-full h-full"
                                          size="md"
                                        />
                                      </div>
                                      <div className="flex flex-col">
                                        <Typography
                                          variant="small"
                                          color="blue-gray"
                                          className="font-semibold break-words text-sm text-black leading-4"
                                        >
                                          {`${
                                            item?.bookingto?.firstName || "-"
                                          } ${
                                            item?.bookingto?.lastName || "-"
                                          }`}
                                        </Typography>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="2xl:px-4 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4 whitespace-nowrap"
                                    >
                                      {he.decode(
                                        item?.mainServicedata?.serviceName ||
                                          "-"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className="2xl:px-4 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-warning leading-4 whitespace-nowrap"
                                    >
                                      {item.bookingStatus === 1
                                        ? "In Progress"
                                        : ""}
                                    </Typography>
                                  </td>
                                  <td className="2xl:px-4 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 first:rounded-l-lg last:rounded-r-lg">
                                    <div className="flex items-center gap-4">
                                      <Tooltip content="View Details">
                                        <div
                                          className="text-primary text-sm font-medium underline underline-offset-2 cursor-pointer whitespace-nowrap"
                                          onClick={(e) =>
                                            navigate(
                                              `/bookingdetails/${item.bookingId}`
                                            )
                                          }
                                        >
                                          View Details
                                        </div>
                                      </Tooltip>
                                      <Tooltip content="Message">
                                        <div
                                          className="text-primary text-sm mx-3 font-medium underline underline-offset-2 cursor-pointer whitespace-nowrap"
                                          onClick={(e) => handleChat(item)}
                                        >
                                          <IoChatbubblesOutline
                                            className="h-6 w-6 text-black"
                                            color="#2b426e"
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="py-5 text-center justify-center items-center flex h-96">
                      <h6 className="font-medium text-black">
                        No Booking found!!!
                      </h6>
                    </div>
                  )}
                </CardBody>
                {bookingList.length > 0 && pending === false ? (
                  <CardFooter className="flex items-center justify-end p-4">
                    <div className="flex gap-2">
                      <Button
                        variant="filled"
                        size="sm"
                        className="bg-[#37489B] capitalize font-normal text-sm tracking-wider"
                        onClick={(e) => handleBack(e)}
                        disabled={pageNo === 1 ? true : false}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outlined"
                        size="sm"
                        className="border border-[#37489B] text-[#37489B] capitalize font-medium text-sm tracking-wider"
                        onClick={(e) => handleNext(e)}
                        disabled={pageCount === pageNo}
                      >
                        Next
                      </Button>
                    </div>
                  </CardFooter>
                ) : null}
              </Card>
            </TabPanel>
            <TabPanel value={2} className="p-0">
              <Card className="h-full w-full">
                <CardBody className="p-0 py-4 overflow-x-auto overflow-x-scroll">
                  {pending === true ? (
                    <div className="px-5 max-w-full animate-pulse border-separate border-spacing-y-4">
                      {[1, 2, 3, 4].map((star) => (
                        <Typography
                          key={star}
                          as="div"
                          variant="h1"
                          className="h-[80px] w-full rounded-lg bg-gray-300 my-3"
                        >
                          &nbsp;
                        </Typography>
                      ))}
                    </div>
                  ) : pending === false && bookingList.length ? (
                    <div className="grid grid-cols-12">
                      <div className="col-span-12 px-5 overflow-auto">
                        <table className="w-full table-auto text-left border-separate border-spacing-y-4">
                          <thead className="">
                            <tr className="table-row-shadow bg-white rounded-lg">
                              {TABLE_ALL_HEAD.map((head) => (
                                <th
                                  key={head}
                                  className="px-4 py-6 h-[79px] first:rounded-l-xl last:rounded-r-xl"
                                >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="text-[#000000] font-medium text-[15px] leading-none opacity-70 whitespace-nowrap"
                                  >
                                    {head}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {bookingList.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="h-[80px] table-row-shadow bg-white rounded-lg"
                                >
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4"
                                    >
                                      {item.bookingId}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4 whitespace-nowrap"
                                    >
                                      {moment(item.bookingDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className="2xl:px-4 min-w-[250px] 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 first:rounded-l-lg last:rounded-r-lg">
                                    <div className="flex items-center gap-2">
                                      <div className="w-14 h-14">
                                        <Avatar
                                          src={
                                            item?.bookingto?.profilePic
                                              ? IMAGE_URL +
                                                item?.bookingto?.profilePic
                                              : noImage
                                          }
                                          alt="avatar"
                                          crossOrigin="anonymous"
                                          className="w-full h-full"
                                          size="md"
                                        />
                                      </div>
                                      <div className="flex flex-col">
                                        <Typography
                                          variant="small"
                                          color="blue-gray"
                                          className="font-semibold break-words text-sm text-black leading-4"
                                        >
                                          {`${
                                            item?.bookingto?.firstName || "-"
                                          } ${
                                            item?.bookingto?.lastName || "-"
                                          }`}
                                        </Typography>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4 whitespace-nowrap"
                                    >
                                      {he.decode(
                                        item?.mainServicedata?.serviceName ||
                                          "-"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg ">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-success leading-4 whitespace-nowrap"
                                    >
                                      {item.bookingStatus === 2
                                        ? "Completed"
                                        : ""}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <div className="flex items-center gap-4">
                                      <Tooltip content="View Details">
                                        <div
                                          className="text-primary text-sm font-medium underline underline-offset-2 cursor-pointer whitespace-nowrap"
                                          onClick={(e) =>
                                            navigate(
                                              `/bookingdetails/${item.bookingId}`
                                            )
                                          }
                                        >
                                          View Details
                                        </div>
                                      </Tooltip>

                                      <Tooltip content="Message">
                                        <div
                                          className="text-primary text-sm mx-3 font-medium underline underline-offset-2 cursor-pointer whitespace-nowrap"
                                          onClick={(e) => handleChat(item)}
                                        >
                                          <IoChatbubblesOutline
                                            className="h-6 w-6 text-black"
                                            color="#2b426e"
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="py-5 text-center justify-center items-center flex h-96">
                      <h6 className="font-medium text-black">
                        No Booking found!!!
                      </h6>
                    </div>
                  )}
                </CardBody>
                {bookingList.length > 0 && pending === false ? (
                  <CardFooter className="flex items-center justify-end p-4">
                    <div className="flex gap-2">
                      <Button
                        variant="filled"
                        size="sm"
                        className="bg-[#37489B] capitalize font-normal text-sm tracking-wider"
                        onClick={(e) => handleBack(e)}
                        disabled={pageNo === 1 ? true : false}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outlined"
                        size="sm"
                        className="border border-[#37489B] text-[#37489B] capitalize font-medium text-sm tracking-wider"
                        onClick={(e) => handleNext(e)}
                        disabled={pageCount === pageNo}
                      >
                        Next
                      </Button>
                    </div>
                  </CardFooter>
                ) : null}
              </Card>
            </TabPanel>
            <TabPanel value={3} className="p-0">
              <Card className="h-full w-full">
                <CardBody className="px-0 p-4 overflow-x-auto">
                  {pending === true ? (
                    <div className="px-5 max-w-full animate-pulse border-separate border-spacing-y-4">
                      {[1, 2, 3, 4].map((star) => (
                        <Typography
                          key={star}
                          as="div"
                          variant="h1"
                          className="h-[80px] w-full rounded-lg bg-gray-300 my-3"
                        >
                          &nbsp;
                        </Typography>
                      ))}
                    </div>
                  ) : pending === false && bookingList.length ? (
                    <div className="grid grid-cols-12">
                      <div className="col-span-12 px-5 overflow-auto">
                        <table className="w-full table-auto text-left border-separate border-spacing-y-4">
                          <thead className="">
                            <tr className="table-row-shadow bg-white rounded-lg">
                              {TABLE_ALL_HEAD.map((head) => (
                                <th
                                  key={head}
                                  className="px-4 py-6 h-[79px] first:rounded-l-xl last:rounded-r-xl"
                                >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="text-[#000000] font-medium text-[15px] leading-none opacity-70 whitespace-nowrap"
                                  >
                                    {head}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {bookingList.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className="h-[80px] table-row-shadow bg-white rounded-lg"
                                >
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4"
                                    >
                                      {item.bookingId}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4 whitespace-nowrap"
                                    >
                                      {moment(item.bookingDate).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className="2xl:px-4 min-w-[250px] 2xl:py-6 xl:px-4 xl:py-6 lg:px-4 py-2 px-2 first:rounded-l-lg last:rounded-r-lg">
                                    <div className="flex items-center gap-2">
                                      <div className="w-14 h-14">
                                        <Avatar
                                          src={
                                            item?.bookingto?.profilePic
                                              ? IMAGE_URL +
                                                item?.bookingto?.profilePic
                                              : noImage
                                          }
                                          alt="avatar"
                                          crossOrigin="anonymous"
                                          className="w-full h-full"
                                          size="md"
                                        />
                                      </div>
                                      <div className="flex flex-col">
                                        <Typography
                                          variant="small"
                                          color="blue-gray"
                                          className="font-semibold break-words text-sm text-black leading-4"
                                        >
                                          {`${
                                            item?.bookingto?.firstName || "-"
                                          } ${
                                            item?.bookingto?.lastName || "-"
                                          }`}
                                        </Typography>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-black leading-4 whitespace-nowrap"
                                    >
                                      {he.decode(
                                        item?.mainServicedata?.serviceName ||
                                          "-"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-semibold text-sm text-danger leading-4 whitespace-nowrap"
                                    >
                                      {item.bookingStatus === 3
                                        ? "Canceled"
                                        : ""}
                                    </Typography>
                                  </td>
                                  <td className="px-4 py-6 first:rounded-l-lg last:rounded-r-lg">
                                    <Tooltip content="View Details">
                                      <div
                                        className="text-primary text-sm font-medium underline underline-offset-2 cursor-pointer whitespace-nowrap"
                                        onClick={(e) =>
                                          navigate(
                                            `/bookingdetails/${item.bookingId}`
                                          )
                                        }
                                      >
                                        View Details
                                      </div>
                                    </Tooltip>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="py-5 text-center justify-center items-center flex h-96">
                      <h6 className="font-medium text-black">
                        No Booking found!!!
                      </h6>
                    </div>
                  )}
                </CardBody>
                {bookingList.length > 0 && pending === false ? (
                  <CardFooter className="flex items-center justify-end p-4">
                    <div className="flex gap-2">
                      <Button
                        variant="filled"
                        size="sm"
                        className="bg-[#37489B] capitalize font-normal text-sm tracking-wider"
                        onClick={(e) => handleBack(e)}
                        disabled={pageNo === 1 ? true : false}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outlined"
                        size="sm"
                        className="border border-[#37489B] text-[#37489B] capitalize font-medium text-sm tracking-wider"
                        onClick={(e) => handleNext(e)}
                        disabled={pageCount === pageNo}
                      >
                        Next
                      </Button>
                    </div>
                  </CardFooter>
                ) : null}
              </Card>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
};

export default MyBookings;
