import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:mt-24 xl:mt-24 lg:mt-22 mt-20 py-8">
        <div className="w-full flex justify-center">
          <div className="p-4 rounded-lg w-[100%]">
            <h3 className="text-lg font-semibold">Terms & Conditions</h3>
            <div className="py-2.5">
              <p className="py-3 text-gray leading-8 text-sm">
                These Terms of Service serve as a legally binding agreement
                between you and Go Fix It Now, governing your utilization of the
                Go Fix It Now Platform. The Go Fix It Now Platform encompasses
                Go Fix It Now's website (www.gofixitnow.co.uk), mobile
                applications (referred to as the "Apps"), as well as associated
                services, information, and communications. It is imperative to
                note that all personal data you submit to the Go Fix It Now
                Platform, or that we collect about you, is subject to our
                Privacy Policy. A copy of our Privacy Policy can be found at
                [insert link].
              </p>
            </div>
            <div className="py-2.5">
              <p className="py-3 text-gray leading-8 text-sm">
                By utilizing the Go Fix It Now Platform, you acknowledge that
                you have reviewed the Privacy Policy. Your consent during
                registration and continued use of the Go Fix It Now Platform
                signifies your acceptance and agreement to all the terms and
                conditions outlined in these Terms of Service, the Privacy
                Policy, and any future amendments or additions to this Agreement
                that we may publish periodically. Should any future changes to
                this Agreement be deemed unacceptable to you or result in
                non-compliance, it is your responsibility to deactivate your
                account and cease all usage of the Go Fix It Now Platform.
              </p>
            </div>
            <div className="py-2.5">
              <p className="py-3 text-gray leading-8 text-sm">
                The Privacy Policy is incorporated by reference into these Terms
                of Service and, together, they form the "Agreement." If you do
                not agree to be bound by this Agreement and adhere to its terms,
                you are prohibited from using or accessing the Go Fix It Now
                Platform. Please be aware that Section [insert section number],
                which contains jurisdiction-specific provisions, outlines the
                resolution of disputes between users and Go Fix It Now. By
                acknowledging the Terms of Service and/or utilizing the Go Fix
                It Now Platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
