import React, { Fragment, useEffect, useRef, useState } from "react";
import NewHeader from "../../components/home/NewHeader";
import bg1 from "../../assets/images/home/bg-1.png";
import bg2 from "../../assets/images/booking/bg-1.png";
import bg3 from "../../assets/images/booking/bg-2.png";
import logo from "../../assets/images/home/logo-lg.png";
import footerbg1 from "../../assets/images/booking/footer1.png";
import footerbg2 from "../../assets/images/booking/footer2.png";
import { Transition } from "@headlessui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopBar from "../../layouts/Topbar/Topbar";
import checkIcon from "../../assets/images/home/check.png";
import Footer from "../../components/home/Footer";
import { useNavigate } from "react-router-dom";
import { MoveUpRight } from "lucide-react";
import {
  addBookingServiceApi,
  getAdminChargeApi,
  getPriceApi,
  getVehicleAndHelperApi,
  listAllCityApi,
  listMainServiceApi,
  listSubServiceApi,
  searchMainServiceApi,
} from "../../services/ApiServices";
import he from "he";
import { IMAGE_URL } from "../../services/Api";
import { useDebounce } from "use-debounce";
import AreaSection from "../../common/AreaSection/AreaSection";
import SameDayService from "../../common/SameDayService/SameDayService";
import PopularServices from "../../common/PopularServices/PopularServices";
import ClientsReview from "../../common/ClientsReview/ClientsReview";
import noPicture from "../../assets/images/no-picture.png";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { DayPicker } from "react-day-picker";
import { FaCircleChevronRight } from "react-icons/fa6";
import { FaCircleChevronLeft } from "react-icons/fa6";
import footerMobile from "../../assets/images/booking/footer-mobile.png";
import { useFormik } from "formik";
import * as yup from "yup";
import noImage from "../../assets/images/no-image.png";
import fileImg from "../../assets/images/icons/file.png";
import { MdClose } from "react-icons/md";
import moment from "moment";
import WhyChooseUs from "../../common/WhyChooseUs/WhyChooseUs";
import toast from "react-hot-toast";
import * as animationData from "../../assets/images/json/success.json";
import Lottie from "react-lottie";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { customGoogleplaceStyles } from "../../common/data";
import category1serv1 from "../../assets/images/white-services/c1s1.png";

const generateTimeOptions = () => {
  const interval = 30;
  const timeOptions = [];
  let id = 1;

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      const formattedHour = hour % 12 || 12;
      const formattedMinute =
        minute === 0 ? "00" : String(minute).padStart(2, "0");
      const ampm = hour < 12 ? "AM" : "PM";
      const timeString = `${formattedHour}:${formattedMinute} ${ampm}`;
      const timeValue = `${String(hour).padStart(
        2,
        "0"
      )}:${formattedMinute}:00`;

      timeOptions.push({
        id: id++,
        name: timeString,
        value: timeValue,
      });
    }
  }

  return timeOptions;
};

const NewHome = () => {
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const mainSliderRef = useRef(null);
  const token = localStorage.getItem("user_token");
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [serviceData, setServiceData] = useState([]);
  const [subServiceData, setSubServiceData] = useState([]);
  const [isCategoryLoader, setIsCategoryLoader] = useState(false);
  const [isServiceLoader, setIsServiceLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [value] = useDebounce(search, 1000);
  const [isActive, setIsActive] = useState(1);
  const [selectedService, setSelectedService] = useState({});

  //booking details
  const today = new Date();
  const disabledDays = { before: today };
  const date = moment(today).format("YYYY-MM-DD");
  const time = moment(today).format("hh:mm:ss");
  const time_arr = generateTimeOptions();
  const [selectTime, setSelectTime] = useState("");
  const [allCities, setAllCities] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [helperData, setHelperData] = useState([]);
  const [chargeInfo, setChargeInfo] = useState({});
  const [totalCharge, setTotalCharge] = useState(0);

  const handleOpen = () => setOpen(!open);

  const showSearchDropdown = () => {
    setSearchDropdown(true);
  };

  const hideSearchDropdown = () => {
    setSearchDropdown(false);
  };

  var settings = {
    dots: false,
    speed: 500,
    infinite: true,
    centerPadding: "5px",
    slidesToShow: 9,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const handleActive = (serviceId, item) => {
    setActiveCategory(serviceId);
    setSelectedCategory(item);
    setIsActive(1);
    const newServiceData = [
      item,
      ...serviceData.filter((service) => service.serviceId !== serviceId),
    ];

    setServiceData(newServiceData);

    if (mainSliderRef.current) {
      mainSliderRef.current.slickGoTo(0);
    }
  };

  const getServiceList = () => {
    setIsCategoryLoader(true);

    listMainServiceApi(search)
      .then((res) => {
        const data = res.data.info;
        setActiveCategory(data[0]?.serviceId);
        setSelectedCategory(data[0]);
        setServiceData(data);
        setIsCategoryLoader(false);
      })
      .catch((err) => {
        setIsCategoryLoader(false);
        console.log("err >>>", err);
      });
  };

  const getSearchSevicesList = () => {
    if (value) {
      searchMainServiceApi(value)
        .then((res) => {
          const data = res.data.info;
          setSearchList(data);
        })
        .catch((err) => {
          console.log("err >>>", err);
        });
    } else {
      listMainServiceApi()
        .then((res) => {
          const data = res.data.info;
          setSearchList(data);
        })
        .catch((err) => {
          console.log("err >>>", err);
        });
    }
  };

  useEffect(() => {
    getServiceList();
  }, []);

  useEffect(() => {
    getSearchSevicesList();
  }, [value]);

  const handleSelectCategory = (serviceId, item) => {
    setActiveCategory(serviceId);
    setSelectedCategory(item);
    setIsActive(1);
    setSearch("");

    const newServiceData = [
      item,
      ...serviceData.filter((service) => service.serviceId !== serviceId),
    ];

    setServiceData(newServiceData);

    if (mainSliderRef.current) {
      mainSliderRef.current.slickGoTo(0);
    }
  };

  const listSubServiceInfo = () => {
    const params = {
      parentId: activeCategory,
    };
    setIsServiceLoader(true);

    listSubServiceApi(params)
      .then((res) => {
        const info = res.data.info;
        setSubServiceData(info);
        setIsServiceLoader(false);
      })
      .catch((err) => {
        setIsServiceLoader(false);
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    if (activeCategory !== null) {
      listSubServiceInfo();
    }
  }, [activeCategory]);

  const getAllCityInfo = () => {
    listAllCityApi()
      .then((res) => {
        const data = res.data.data;

        setAllCities(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const getVehicleInfo = () => {
    getVehicleAndHelperApi("vehicle")
      .then((res) => {
        const data = res.data.data;
        setVehicleData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const getHelperInfo = () => {
    getVehicleAndHelperApi("helper")
      .then((res) => {
        const data = res.data.data;
        setHelperData(data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const getChargetInfo = () => {
    getAdminChargeApi()
      .then((res) => {
        const data = res.data.data;
        setChargeInfo(data);
      })
      .catch((err) => {
        console.log("err >>>>", err);
      });
  };

  useEffect(() => {
    getAllCityInfo();
    getVehicleInfo();
    getHelperInfo();
    getChargetInfo();
  }, []);

  const city_options = allCities.map((item) => {
    return {
      label: item.city,
      value: item.cityId,
    };
  });

  const handleBookNow = (e, item) => {
    const obj = {
      ...selectedCategory,
      subServices: [item],
    };

    if (!token) {
      navigate("/signin");
    } else {
      setIsActive(2);
      setSelectedService(obj);
    }
  };

  const handleTimeClick = (time) => {
    setSelectTime(time);
    formik.setFieldValue("bookingTime", time);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    navigate("/mybooking");
  };

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.selection = "Values are undefined";
      return errors;
    }
    if (!values.selection) {
      errors.selection = "You must select either Now or Later";
    } else if (values.selection === "later") {
      if (!values.bookingDate) {
        errors.bookingDate = "Booking date is required";
      }
      if (!values.bookingTime) {
        errors.bookingTime = "Booking time is required";
      }
    }
    return errors;
  };

  const validationSchema = yup.object().shape({
    serviceType: yup.string().required("Please select a service type"),
    pickupAddress: yup.string().when("serviceType", {
      is: (value) => value === "Transport & Moving",
      then: (schema) =>
        schema.required(
          "Pick up address is required when service type is Transport & Moving"
        ),
    }),
    dropoffAddress: yup.string().when("serviceType", {
      is: (value) => value === "Transport & Moving",
      then: (schema) =>
        schema.required(
          "Drop off address is required when service type is Transport & Moving"
        ),
    }),
    vehicleId: yup
      .string()
      .nullable()
      .when("serviceType", {
        is: (value) => value === "Transport & Moving",
        then: (schema) => schema.required("Please specify if you need van"),
      }),
    vehicleFee: yup
      .number()
      .nullable()
      .when("van", {
        is: (value) => !!value,
        then: (schema) =>
          schema.required("Price is required for the selected van"),
      }),
    helperId: yup
      .string()
      .nullable()
      .when("serviceType", {
        is: (value) => value === "Transport & Moving",
        then: (schema) => schema.required("Please specify if you need help"),
      }),
    helperFee: yup
      .number()
      .nullable()
      .when("help", {
        is: (value) => !!value,
        then: (schema) =>
          schema.required("Price is required for the selected helper"),
      }),
    requiredJob: yup.string().required("Please Describe Your required job"),
    requiredMaterials: yup
      .string()
      .required("Enter a required materials")
      .max(500, "Materials Must be 500 characters or less"),
    workingImage: yup
      .mixed()
      .required("Working image is required")
      .test("fileFormat", "Only Image files are allowed", (value) => {
        if (value) {
          const supportedFormats = ["jpg", "jpeg", "png"];
          return supportedFormats.includes(
            value.name.split(".").pop().toLowerCase()
          );
        }
        return true;
      }),
    description: yup.string().required("Please Enter a brief description"),
    city: yup.object().shape({
      label: yup.string().required("City is required"),
      value: yup.string().required("City is required"),
    }),
    address: yup.string().required("Please Enter an address"),
    selection: yup.string().required("You must select either Now or Later"),
    bookingDate: yup.date().nullable(),
    bookingTime: yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceType: selectedService?.serviceName
        ? he.decode(he.decode(selectedService?.serviceName))
        : "",
      pickupAddress: "",
      pickupLat: "",
      pickupLng: "",
      dropoffAddress: "",
      dropoffLat: "",
      dropoffLng: "",
      vehicleId: "",
      vehicleFee: "",
      helperId: "",
      helperFee: "",
      requiredJob: "",
      requiredMaterials: "",
      workingImage: "",
      description: "",
      city: null,
      address: "",
      selection: "",
      bookingDate: null,
      bookingTime: "",
    },
    validationSchema: validationSchema,
    validate,
    onSubmit: (values, { resetForm }) => {
      const subservice = selectedService?.subServices
        .map((item) => item.serviceId)
        .toString();
      const serviceType = values.serviceType;

      if (values.selection === "later") {
        if (validate()) {
          const price =
            selectedService.serviceName === "Transport & Moving" ||
            selectedService.serviceName === "Transport &amp;amp;  Moving" ||
            selectedService.serviceName === "Transport &amp; Moving"
              ? totalCharge
              : values.price;

          setLoader(true);
          const date = moment(values.bookingDate).format("YYYY-MM-DD");
          var formdata = new FormData();
          formdata.append("serviceId", activeCategory);
          formdata.append("subServiceId", subservice);
          formdata.append("requiredJob", values.requiredJob);
          formdata.append("requiredMaterials", values.requiredMaterials);
          formdata.append("description", values.description);
          formdata.append("address", values.description);
          formdata.append("workingImage", values.workingImage);
          formdata.append("bookingDate", date);
          formdata.append("bookingTime", values.bookingTime);
          formdata.append("cityId", values.city.value);
          formdata.append("price", values.price);
          formdata.append(
            "totalAmount",
            selectedService.serviceName === "Transport & Moving" ||
              selectedService.serviceName === "Transport &amp;amp;  Moving" ||
              selectedService.serviceName === "Transport &amp; Moving"
              ? totalCharge
              : values.price
          );

          if (serviceType === "Transport & Moving") {
            formdata.append("pickupAddress", values.pickupAddress);
            formdata.append("pickupLat", values.pickupLat);
            formdata.append("pickupLong", values.pickupLng);
            formdata.append("dropoffAddress", values.dropoffAddress);
            formdata.append("dropoffLat", values.dropoffLat);
            formdata.append("dropoffLong", values.dropoffLng);
            formdata.append("vehicleId", values.vehicleId);
            formdata.append("vehicleFee", values.vehicleFee);
            formdata.append("helperId", values.helperId);
            formdata.append("helperFee", values.helperFee);
          }

          addBookingServiceApi(formdata)
            .then((res) => {
              const message = res.data.message;
              handleOpen();
              toast.success(message || "Create your account Successfully!!!");
              setLoader(false);
              resetForm();
            })
            .catch((err) => {
              const message = err.response.data.message;
              setLoader(false);
              console.log("err >>>", err);
              toast.error(message || "Somthing went wrong!!");
            });
        }
      } else {
        setLoader(true);

        var formdata = new FormData();
        formdata.append("serviceId", activeCategory);
        formdata.append("subServiceId", subservice);
        formdata.append("requiredJob", values.requiredJob);
        formdata.append("requiredMaterials", values.requiredMaterials);
        formdata.append("description", values.description);
        formdata.append("address", values.description);
        formdata.append("workingImage", values.workingImage);
        formdata.append("bookingDate", date);
        formdata.append("bookingTime", time);
        formdata.append("cityId", values.city.value);
        formdata.append("price", values.price);
        formdata.append(
          "totalAmount",
          selectedService.serviceName === "Transport & Moving" ||
            selectedService.serviceName === "Transport &amp;amp;  Moving" ||
            selectedService.serviceName === "Transport &amp; Moving"
            ? totalCharge
            : values.price
        );

        if (serviceType === "Transport & Moving") {
          formdata.append("pickupAddress", values.pickupAddress);
          formdata.append("pickupLat", values.pickupLat);
          formdata.append("pickupLong", values.pickupLng);
          formdata.append("dropoffAddress", values.dropoffAddress);
          formdata.append("dropoffLat", values.dropoffLat);
          formdata.append("dropoffLong", values.dropoffLng);
          formdata.append("vehicleId", values.vehicleId);
          formdata.append("vehicleFee", values.vehicleFee);
          formdata.append("helperId", values.helperId);
          formdata.append("helperFee", values.helperFee);
        }

        addBookingServiceApi(formdata)
          .then((res) => {
            const message = res.data.message;
            handleOpen();

            toast.success(message || "Create your account Successfully!!!");
            setLoader(false);
            resetForm();
          })
          .catch((err) => {
            const message = err.response.data.message;
            setLoader(false);
            console.log("err >>>", err);
            toast.error(message || "Somthing went wrong!!");
          });
      }
    },
  });

  function calculateDistance(lat1, lng1, lat2, lng2) {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 6371;
    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  const calculateTotalCharge = (distance) => {
    if (chargeInfo) {
      const hourlyRate = formik?.values?.vehicleFee || 0;
      const driverHelpCharge = formik?.values?.helperFee || 0;
      const mileageRate = chargeInfo.milestoneCharge || 0;

      const totalHourlyCharge = hourlyRate + driverHelpCharge;
      const mileageCharge = (distance / 10) * mileageRate;
      const total = totalHourlyCharge + mileageCharge;
      const final = total + formik?.values?.price;

      setTotalCharge(final.toFixed(2));
    }
  };

  useEffect(() => {
    if (
      formik?.values?.pickupLat &&
      formik?.values?.pickupLng &&
      formik?.values?.dropoffLat &&
      formik?.values?.dropoffLng &&
      formik?.values?.vehicleFee &&
      formik?.values?.helperFee &&
      formik?.values?.price
    ) {
      const calculatedDistance = calculateDistance(
        formik?.values?.pickupLat,
        formik?.values?.pickupLng,
        formik?.values?.dropoffLat,
        formik?.values?.dropoffLng
      );
      calculateTotalCharge(calculatedDistance);
    }
  }, [
    formik?.values?.pickupLat,
    formik?.values?.pickupLng,
    formik?.values?.dropoffLat,
    formik?.values?.dropoffLng,
    formik?.values?.vehicleFee,
    formik?.values?.helperFee,
    formik?.values?.price,
    chargeInfo,
  ]);

  const handleAddressChange = (fieldPrefix) => (value) => {
    if (value) {
      const placeId = value.value.place_id;
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          formik.setFieldValue(`${fieldPrefix}Address`, value.label);
          formik.setFieldValue(`${fieldPrefix}Lat`, lat);
          formik.setFieldValue(`${fieldPrefix}Lng`, lng);
        } else {
          console.error("Error fetching place details:", status);
        }
      });
    } else {
      formik.setFieldValue(`${fieldPrefix}Address`, "");
      formik.setFieldValue(`${fieldPrefix}Lat`, "");
      formik.setFieldValue(`${fieldPrefix}Lng`, "");
    }
  };

  const getPriceDetails = () => {
    if (
      formik?.values?.selection === "later" &&
      formik?.values?.bookingDate &&
      formik?.values?.bookingTime &&
      formik?.values?.city?.value
    ) {
      const date = moment(formik?.values?.bookingDate).format("YYYY-MM-DD");
      const formattedTime = formik?.values?.bookingTime;

      getPriceApi(
        activeCategory,
        formik?.values?.city?.value,
        date,
        formattedTime
      )
        .then((res) => {
          const price = Number(res.data.price);
          formik.setFieldValue("price", price);
        })
        .catch((err) => {
          console.log("err >>>", err);
        });
    } else if (
      formik?.values?.selection === "now" &&
      formik?.values?.city?.value
    ) {
      getPriceApi(activeCategory, formik?.values?.city?.value, date, time)
        .then((res) => {
          const price = Number(res.data.price);
          formik.setFieldValue("price", price);
        })
        .catch((err) => {
          console.log("err >>>", err);
        });
    }
  };

  useEffect(() => {
    getPriceDetails();
  }, [
    formik?.values?.city,
    formik?.values?.bookingDate,
    formik?.values?.bookingTime,
    formik?.values?.selection,
  ]);

  const handleDayClick = (day) => {
    formik.setFieldValue("bookingDate", day);
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("workingImage", "");
  };

  return (
    <>
      {token ? <TopBar /> : <NewHeader />}
      <section className="2xl:mt-16 xl:mt-20 lg:mt-[68px] mt-[65px]">
        <div
          //   className="relative bg-cover bg-center inset-0 2xl:h-[600px] xl:h-[500px] h-[500px] w-full"
          //   style={{ backgroundImage: `url(${bg1})` }}
          className="bg-gradient-to-r from-[#3B3B3B] to-[#A1A1A1] inset-0 2xl:h-[600px] xl:h-[500px] h-[500px] w-full"
        >
          {/* <div className="absolute inset-0 bg-gradient-to-b from-[#3B3B3B] to-[#A1A1A1] h-full opacity-50"></div> */}
          <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 h-full">
            <div className="flex justify-center items-center h-full text-center">
              <div className="w-full">
                <h1 className="2xl:text-[55px] xl:text-[50px] lg:text-[45px] md:text-[40px] text-[30px] text-white font-medium landing-title">
                  Same day home services
                </h1>
                <h5 className="text-white tracking-wider 2xl:text-xl xl:text-x lg:text-lg mt-4">
                  There is no waiting around with Taskbudy; it is all about
                  getting things done!
                </h5>
                <div className="mt-5 flex justify-center">
                  <div className="bg-[#FFFFFF66] 2xl:p-3 xl:p-3 lg:p-3 p-2 rounded-full 2xl:w-[700px] xl:w-[700px] lg:w-[600px] md:w-[500px] w-[300px]">
                    <div className="relative flex gap-2 bg-white items-center rounded-full px-2 py-1.5">
                      <div className="relative flex items-center gap-1 w-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8 text-black ml-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-4.35-4.35M16.5 10.5a6 6 0 11-12 0 6 6 0 0112 0z"
                          />
                        </svg>
                        <input
                          type="text"
                          name="search_text"
                          placeholder="What service do you need?"
                          className="w-full focus:outline-none ring-0 ml-1 placeholder-black"
                          onChange={(e) => setSearch(e.target.value)}
                          value={search || ""}
                          onFocus={showSearchDropdown}
                          onBlur={hideSearchDropdown}
                          autoComplete="off"
                        />
                      </div>
                      {/* <button
                        type="submit"
                        className="2xl:block xl:block lg:block md:block hidden bg-[#1976D2] text-white font-medium py-2 px-7 rounded-full tracking-wider"
                      >
                        Search
                      </button>
                      <button
                        type="submit"
                        className="2xl:hidden xl:hidden lg:hidden md:hidden block bg-[#1976D2] text-white font-medium p-2 rounded-full tracking-wider"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-4.35-4.35M16.5 10.5a6 6 0 11-12 0 6 6 0 0112 0z"
                          />
                        </svg>
                      </button> */}
                      <Transition
                        as={Fragment}
                        show={searchDropdown}
                        enter="transition-all ease-linear duration-150"
                        enterFrom="mt-5 invisible opacity-0 translate-y-1"
                        enterTo="mt-[3px] visible opacity-100 translate-y-0"
                        leave="transition-all ease-linear duration-150"
                        leaveFrom="mt-[3px] visible opacity-100 translate-y-0"
                        leaveTo="mt-5 invisible opacity-0 translate-y-1"
                      >
                        <div className="absolute left-0 top-[50px] z-10 mt-[3px] w-full">
                          <div className="p-4 box rounded-xl h-[300px] overflow-auto">
                            {searchList.length ? (
                              <>
                                {searchList.map((item, index) => {
                                  return (
                                    <div
                                      className="mb-1 p-1.5 flex justify-between items-center cursor-pointer"
                                      key={index}
                                      onClick={(e) =>
                                        handleSelectCategory(
                                          item.serviceId,
                                          item
                                        )
                                      }
                                    >
                                      <h5 className="font-medium text-start">
                                        {item.serviceName
                                          ? he.decode(
                                              he.decode(item.serviceName)
                                            )
                                          : "-"}
                                      </h5>
                                      <div>
                                        <MoveUpRight color="#1976d2" />
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <div className="h-48 mt-8">
                                <div className="flex justify-center items-center text-center h-full w-full">
                                  <h6 className="font-medium text-lg">
                                    Category Not Found
                                  </h6>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>
                <div className="mt-12">
                  {isCategoryLoader ? (
                    <div>
                      <div className="my-6 2xl:block xl:block lg:block md:block hidden">
                        <div className="max-w-full animate-pulse border-separate border-spacing-y-4 flex justify-center flex-wrap gap-4">
                          {[1, 2, 3, 4, 5].map((star) => (
                            <div
                              key={star}
                              className="service-card-shadow rounded-xl"
                            >
                              <div className="flex justify-center items-center">
                                <div className="bg-gray-300 rounded-lg 2xl:h-[160px] xl:h-[150px] lg:h-[130px] h-[120px] 2xl:w-[150px] xl:w-[120px] lg:w-[120px] md:w-[100px] w-[90px]"></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="my-6 2xl:hidden xl:hidden lg:hidden md:hidden block">
                        <div className="max-w-full animate-pulse border-separate border-spacing-y-4 flex justify-center flex-wrap gap-4">
                          {[1, 2, 3].map((star) => (
                            <div
                              key={star}
                              className="service-card-shadow rounded-xl"
                            >
                              <div className="flex justify-center items-center">
                                <div className="bg-gray-300 rounded-lg 2xl:h-[160px] xl:h-[150px] lg:h-[130px] h-[120px] 2xl:w-[150px] xl:w-[120px] lg:w-[120px] md:w-[100px] w-[90px]"></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : !isCategoryLoader && serviceData.length <= 0 ? (
                    <div className="h-48 mt-8">
                      <div className="flex justify-center items-center text-center h-full w-full">
                        <h6 className="font-medium text-lg text-white">
                          Category Not Found
                        </h6>
                      </div>
                    </div>
                  ) : (
                    <div className="px-5">
                      <Slider {...settings} ref={mainSliderRef}>
                        {serviceData.map((item, index) => {
                          return (
                            <div
                              className="flex justify-center items-center h-full"
                              key={index}
                            >
                              <div
                                className={`${
                                  activeCategory === item.serviceId
                                    ? "bg-[#1976D2]"
                                    : "bg-[#0C4AD8]"
                                } cursor-pointer border border-2 border-[#D9D9D9] 2xl:p-4 xl:p-3 lg:p-3 md:p-2.5 p-2 rounded-lg 2xl:h-[160px] xl:h-[150px] lg:h-[130px] h-[120px] 2xl:w-[150px] xl:w-[120px] lg:w-[120px] md:w-[100px] w-[90px]`}
                                onClick={(e) =>
                                  handleActive(item.serviceId, item)
                                }
                              >
                                <div className="h-full">
                                  <div className="flex justify-center items-center">
                                    <img
                                      src={IMAGE_URL + item.serviceIcon}
                                      alt="service"
                                      className="2xl:h-14 xl:h-14 lg:h-14 h-12 object-cover"
                                      crossOrigin="anonymous"
                                    />
                                  </div>
                                  <h5 className="text-white text-center font-normal 2xl:text-base xl:text-sm lg:text-sm text-xs 2xl:mt-5 mt-3">
                                    {item.serviceName
                                      ? he.decode(he.decode(item.serviceName))
                                      : "-"}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isActive === 1 ? (
        <>
          <section className="">
            <div className="bg-[#F2F2F2]">
              <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
                <div className="pt-10 pb-6">
                  <h5 className="text-[#20448C] font-semibold 2xl:text-3xl xl:text-3xl lg:text-2xl  md:text-2xl text-xl text-center">
                    Select your service
                  </h5>
                  {isServiceLoader ? (
                    <>
                      <div className="grid grid-cols-12 gap-6 mt-6">
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((star) => {
                          return (
                            <div
                              className="col-span-4 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-3"
                              key={star}
                            >
                              <div className="flex justify-center items-center">
                                <div className="2xl:p-3.5 xl:p-3 lg:p-3 p-2.5 bg-gray-300 rounded-full 2xl:h-20 2xl:w-20 xl:w-[70px] xl:h-[70px] lg:w-[70px] lg:h-[70px] h-14 w-14"></div>
                              </div>
                              <div className="2xl:mt-5 xl:mt-5 lg:mt-4 mt-3 flex justify-center items-center">
                                <h5 className="bg-gray-300 2xl:h-4 xl:h-4 lg:h-3 h-3 2xl:min-w-[85%] xl:min-w-[85%] lg:min-w-[85%] md:min-w-[85%] min-w-full rounded-full"></h5>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : !isServiceLoader && subServiceData.length <= 0 ? (
                    <div className="h-48 mt-8">
                      <div className="flex justify-center items-center text-center h-full w-full">
                        <h6 className="font-medium text-lg">
                          Service Not Found
                        </h6>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-12 gap-6 mt-6">
                      {subServiceData.map((item, index) => {
                        return (
                          <div
                            className="h-full col-span-4 2xl:col-span-2 xl:col-span-2 lg:col-span-2 md:col-span-3"
                            key={index}
                          >
                            <div className="flex justify-center items-center">
                              <div className="2xl:p-3 xl:p-3 lg:p-3 p-2.5 bg-[#0C4AD8] rounded-full 2xl:h-20 2xl:w-20 xl:w-[70px] xl:h-[70px] lg:w-[70px] lg:h-[70px] h-14 w-14">
                                <img
                                  alt=""
                                  crossOrigin="anonymous"
                                  className="h-full w-full"
                                  src={IMAGE_URL + item.serviceIcon}
                                />
                              </div>
                            </div>
                            <div className="2xl:mt-5 xl:mt-5 lg:mt-4 mt-3 text-center">
                              <h5 className="font-medium 2xl:text-base xl:text-base lg:text-sm text-xs">
                                {item.serviceName
                                  ? he.decode(he.decode(item.serviceName))
                                  : "-"}
                              </h5>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="2xl:py-10 xl:py-10 py-8">
            <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
              <div className="flex 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center">
                <div className="flex items-center text-[#20448C] px-4 py-3">
                  <img
                    src={checkIcon}
                    alt="check-icon"
                    className="2xl:h-[70px] xl:h-16 h-14"
                  ></img>
                  <div>
                    <h6 className="2xl:text-xl xl:text-xl text-lg 2xl:font-semibold xl:font-semibold font-medium">
                      Local
                    </h6>
                    <h4 className="2xl:font-bold xl:font-bold font-semibold 2xl:text-3xl xl:text-2xl lg:text-xl text-xl">
                      Independent Fixers
                    </h4>
                  </div>
                </div>
                <div className="flex items-center justify-center text-[#20448C] px-4 py-3">
                  <img
                    src={checkIcon}
                    alt="check-icon"
                    className="2xl:h-[70px] xl:h-16 h-14"
                  ></img>
                  <div>
                    <h6 className="2xl:text-xl xl:text-xl text-lg 2xl:font-semibold xl:font-semibold font-medium">
                      Fully
                    </h6>
                    <h4 className="2xl:font-bold xl:font-bold font-semibold 2xl:text-3xl xl:text-2xl lg:text-xl text-xl">
                      Insured & Qualified
                    </h4>
                  </div>
                </div>
                <div className="flex items-center justify-end text-[#20448C] px-4 py-3">
                  <img
                    src={checkIcon}
                    alt="check-icon"
                    className="2xl:h-[70px] xl:h-16 h-14"
                  ></img>
                  <div>
                    <h6 className="2xl:text-xl xl:text-xl text-lg 2xl:font-semibold xl:font-semibold font-medium">
                      Quality
                    </h6>
                    <h4 className="2xl:font-bold xl:font-bold font-semibold 2xl:text-3xl xl:text-2xl lg:text-xl text-xl">
                      Work Guaranteed
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SameDayService
            isCategoryLoader={isCategoryLoader}
            serviceData={serviceData}
          />
          <PopularServices />
          <WhyChooseUs />
          <ClientsReview />
          <AreaSection />
        </>
      ) : (
        <>
          {selectedService.serviceName === "Transport & Moving" ||
          selectedService.serviceName === "Transport &amp;amp;  Moving" ||
          selectedService.serviceName === "Transport &amp; Moving" ? (
            <>
              <div
                className="bg-cover bg-center inset-0 h-full w-full"
                style={{ backgroundImage: `url(${bg2})` }}
              >
                <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:py-16 xl:py-16 lg:py-12 py-10">
                  <div className="flex items-center gap-2">
                    <img
                      src={
                        selectedService.subServices[0].serviceIcon
                          ? IMAGE_URL +
                            selectedService.subServices[0].serviceIcon
                          : noPicture
                      }
                      alt=""
                      crossOrigin="anonymous"
                      className="2xl:w-16 2xl:h-16 xl:h-16 xl:w-16 h-14 w-14 object-center"
                    ></img>
                    <h5 className="text-xl font-medium">
                      {selectedService?.subServices[0]?.serviceName
                        ? he.decode(
                            selectedService?.subServices[0]?.serviceName
                          )
                        : "-"}
                    </h5>
                  </div>
                  <div className="my-10 2xl:px-16 xl:px-16 lg:px-16 md:px-10 px-8 py-6 border border-[#DDDDDD] bg-[#0F4C8B] text-[#EEEEEE] rounded-[25px]">
                    <div className="grid grid-cols-12 gap-3 my-6">
                      <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                        <label
                          className="leading-6 text-white mb-2"
                          htmlFor="regular-form-1"
                        >
                          Pick up from
                        </label>
                        <div className="mt-1">
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyDDRstmXiKSYRkjheY669sIc4G1p4utX9w"
                            selectProps={{
                              placeholder: "Star typing address or postcode...",
                              isClearable: true,
                              styles: customGoogleplaceStyles,
                              name: "pickupAddress",
                              onChange: handleAddressChange("pickup"),
                            }}
                            className="w-full"
                          />
                          {formik.touched.pickupAddress &&
                            formik.errors.pickupAddress && (
                              <p className="text-danger text-sm mt-2">
                                {formik.errors.pickupAddress}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                        <label
                          className="leading-6 text-white mb-2"
                          htmlFor="regular-form-1"
                        >
                          Drop off to
                        </label>
                        <div className="mt-1">
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyDDRstmXiKSYRkjheY669sIc4G1p4utX9w"
                            selectProps={{
                              placeholder: "Star typing address or postcode...",
                              isClearable: true,
                              styles: customGoogleplaceStyles,
                              name: "dropoffAddress",
                              onChange: handleAddressChange("dropoff"),
                            }}
                            className="w-full"
                          />
                          {formik.touched.dropoffAddress &&
                            formik.errors.dropoffAddress && (
                              <p className="text-danger text-sm mt-2">
                                {formik.errors.dropoffAddress}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="2xl:text-lg xl:text-lg lg:text-lg text-base">
                        Which Vehicle Do You Need?
                      </h5>
                      <div className="grid grid-cols-12 gap-3 mt-6">
                        {vehicleData.map((item, index) => {
                          return (
                            <div
                              className="col-span-6 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3"
                              key={index}
                            >
                              <label
                                htmlFor={`vehicle-input-${index}`}
                                className="cursor-pointer"
                              >
                                <div className="flex items-center space-x-2">
                                  <input
                                    id={`vehicle-input-${index}`}
                                    name="vehicleId"
                                    type="radio"
                                    value={item.id}
                                    className="w-4 h-4 w-5 h-5 border rounded-full border-blue-gray-200 cursor-pointer transition-all text-red-500 checked:border-red-500 checked:before:bg-red-500"
                                    onChange={(e) => {
                                      const selectedVan = vehicleData.find(
                                        (item) =>
                                          item.id === parseInt(e.target.value)
                                      );
                                      formik.setFieldValue(
                                        "vehicleFee",
                                        selectedVan.price
                                      );
                                      formik.setFieldValue(
                                        "vehicleId",
                                        selectedVan.id
                                      );
                                    }}
                                  />
                                  <span className="text-sm">{item.name}</span>
                                </div>

                                <img
                                  src={IMAGE_URL + item.image}
                                  alt=""
                                  className="2xl:h-[160px] xl:h-[150px] lg:h-[140px] h-[100px]"
                                  crossOrigin="anonymous"
                                ></img>
                                <span className="text-sm mt-2">
                                  Price : £{item.price || 0}
                                </span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      {formik.touched.vehicleId && formik.errors.vehicleId && (
                        <p className="text-danger text-sm mt-2">
                          {formik.errors.vehicleId}
                        </p>
                      )}
                    </div>
                    <div className="mt-4">
                      <h5 className="2xl:text-lg xl:text-lg lg:text-lg text-base">
                        Will You Need Help With Loading And Unloading?
                      </h5>
                      <div className="grid grid-cols-12 gap-3 mt-6">
                        {helperData.map((item, index) => {
                          return (
                            <div
                              className="col-span-6 2xl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3"
                              key={index}
                            >
                              <label
                                htmlFor={`help-input-${index}`}
                                className="cursor-pointer"
                              >
                                <div className="flex items-start space-x-2">
                                  <input
                                    id={`help-input-${index}`}
                                    name="help"
                                    type="radio"
                                    value={item.id}
                                    className="w-4 h-4 w-5 h-5 border rounded-full border-blue-gray-200 cursor-pointer transition-all text-red-500 checked:border-red-500 checked:before:bg-red-500"
                                    onChange={(e) => {
                                      const selectedVan = helperData.find(
                                        (item) =>
                                          item.id === parseInt(e.target.value)
                                      );
                                      formik.setFieldValue(
                                        "helperFee",
                                        selectedVan.price
                                      );
                                      formik.setFieldValue(
                                        "helperId",
                                        selectedVan.id
                                      );
                                    }}
                                  />
                                  <span className="text-sm">{item.name}</span>
                                </div>
                                <img
                                  src={IMAGE_URL + item.image}
                                  alt=""
                                  className="2xl:h-[160px] xl:h-[150px] lg:h-[140px] h-[100px]"
                                  crossOrigin="anonymous"
                                ></img>
                                <span className="text-sm mt-2">
                                  Price : £{item.price || 0}
                                </span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      {formik.touched.helperId && formik.errors.helperId && (
                        <p className="text-danger text-sm mt-2">
                          {formik.errors.helperId}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:py-14 xl:py-14 lg:py-12 py-10">
                <div className="2xl:-mt-[120px] xl:-mt-[120px] lg:-mt-[120px] -mt-[100px] bg-white shadow-md rounded-[25px]">
                  <div className="rounded-[25px] xl:py-10 2xl:p-10 xl:p-10 lg:p-8 md:p-6 p-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-1"
                        >
                          Something else
                        </label>
                        <input
                          id="regular-form-1"
                          name="requiredJob"
                          type="text"
                          placeholder="Describe your required job"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.requiredJob}
                        ></input>
                        {formik.touched.requiredJob &&
                        formik.errors.requiredJob ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.requiredJob}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-2"
                        >
                          Do you require materials
                        </label>
                        <textarea
                          id="regular-form-2"
                          name="requiredMaterials"
                          type="text"
                          placeholder="Enter here if materials are required"
                          rows="2"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.requiredMaterials}
                        ></textarea>
                        {formik.touched.requiredMaterials &&
                        formik.errors.requiredMaterials ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.requiredMaterials}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-2"
                        >
                          Description
                        </label>
                        <textarea
                          id="regular-form-2"
                          name="description"
                          type="text"
                          placeholder="Brief description of required work"
                          rows="2"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                        ></textarea>
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-2"
                        >
                          Enter Site Address
                        </label>
                        <textarea
                          id="regular-form-2"
                          name="address"
                          type="text"
                          placeholder="Star typing address or postcode..."
                          rows="2"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        ></textarea>
                        {formik.touched.address && formik.errors.address ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-5"
                        >
                          City
                        </label>
                        <Select
                          components={animatedComponents}
                          options={city_options}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          name="city"
                          value={formik.values.city}
                          onChange={(value) =>
                            formik.setFieldValue("city", value)
                          }
                          onBlur={() => formik.setFieldTouched("city", true)}
                          placeholder="Select City"
                        />
                        {formik.touched.city && formik.errors.city ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.city}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4 mt-3 w-auto">
                          <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6">
                            <label
                              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                              htmlFor="regular-form-2"
                            >
                              Upload photos
                            </label>
                            <p className="text-sm my-2 text-info">
                              {formik?.values?.workingImage?.name || ""}
                            </p>
                            {formik.values.workingImage ? (
                              <div className="border border-dashed border-2 border-[#D9D9D9] rounded-md p-4 flex justify-center items-center">
                                {formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "jpeg" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "png" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "svg" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "png" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "jpg" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "gif" ? (
                                  <div className="cursor-pointer image-fit">
                                    <div className="m-2 relative">
                                      <img
                                        alt="document"
                                        className="rounded-lg h-[300px] w-full"
                                        src={
                                          formik?.values?.workingImage
                                            ? URL.createObjectURL(
                                                formik.values.workingImage
                                              )
                                            : noImage
                                        }
                                      />
                                      <button
                                        className="absolute -right-2 -top-1 h-5 w-5 bg-[#fff] rounded-full flex justify-center items-center"
                                        onClick={handleRemoveImage}
                                        type="button"
                                      >
                                        <MdClose className="w-4 h-4 font-bold" />
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="cursor-pointer image-fit">
                                    <div className="m-2 relative">
                                      <img
                                        alt="document"
                                        className="rounded-lg h-20 w-20"
                                        src={fileImg}
                                      />
                                      <button
                                        className="absolute -right-2 -top-1 h-5 w-5 bg-[#fff] rounded-full flex justify-center items-center"
                                        onClick={handleRemoveImage}
                                        type="button"
                                      >
                                        <MdClose className="w-4 h-4 font-bold" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="h-52 rounded-md border border-dashed border-2 border-[#D9D9D9] text-center flex justify-center items-center">
                                <div>
                                  <h6 className="font-bold text-sm">
                                    Drag and Drop Image & Upload Image
                                  </h6>
                                  <div className="mt-6">
                                    <label
                                      htmlFor="workingImage"
                                      className="py-2.5 px-5 text-sm rounded-md border border-dashed border-2 border-[#D9D9D9] text-secondary"
                                    >
                                      Select file
                                      <input
                                        id="workingImage"
                                        type="file"
                                        name="workingImage"
                                        accept="image/*"
                                        className="w-1 h-1 opacity-0"
                                        onChange={(event) => {
                                          formik.setFieldValue(
                                            "workingImage",
                                            event.currentTarget.files[0]
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {formik.touched.workingImage &&
                        formik.errors.workingImage ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.workingImage}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-3">
                        <div className="flex gap-3">
                          <Checkbox
                            id="horizontal-list-react1"
                            color="indigo"
                            name="selection"
                            value="now"
                            checked={formik.values.selection === "now"}
                            onChange={() =>
                              formik.setFieldValue("selection", "now")
                            }
                            label={
                              <Typography
                                color="black"
                                className="flex font-medium"
                              >
                                Now
                              </Typography>
                            }
                          />
                          <Checkbox
                            id="horizontal-list-react2"
                            color="indigo"
                            name="selection"
                            value="later"
                            checked={formik.values.selection === "later"}
                            onChange={() =>
                              formik.setFieldValue("selection", "later")
                            }
                            label={
                              <Typography
                                color="black"
                                className="flex font-medium"
                              >
                                Later
                              </Typography>
                            }
                          />
                        </div>
                        {formik.errors.selection ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.selection}
                          </div>
                        ) : null}
                        {formik.values.selection === "later" ? (
                          <p className="my-2 text-[#000000]">
                            Please select your time and date when you require
                            the service one of our Fixers will be with you on
                            you selected time and date
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mt-3 font-medium text-primary">
                        Estimate Cost : £
                        {`${
                          selectedService.serviceName ===
                            "Transport & Moving" ||
                          selectedService.serviceName ===
                            "Transport &amp;amp;  Moving" ||
                          selectedService.serviceName ===
                            "Transport &amp; Moving"
                            ? totalCharge
                            : formik?.values?.price || 0
                        }`}
                      </div>
                      {formik.values.selection === "later" ? (
                        <div className="2xl:py-6 xl:py-6 lg:py-6 py-4">
                          <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 gap-4">
                            <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 w-full">
                              <div className="table-row-shadow 2xl:p-4 xl:p-4 lg:p-4 p-2 rounded-[20px] w-auto">
                                <DayPicker
                                  mode="single"
                                  showOutsideDays
                                  disabled={disabledDays}
                                  selected={formik.values.bookingDate}
                                  onSelect={handleDayClick}
                                  className="border-0"
                                  required={true}
                                  classNames={{
                                    caption:
                                      "flex justify-center py-4 mb-4 relative items-center border-b-2",
                                    caption_label:
                                      "text-sm font-medium text-gray-900",
                                    nav: "flex items-center",
                                    nav_button:
                                      "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-full transition-colors duration-300",
                                    nav_button_previous: "absolute left-1.5",
                                    nav_button_next: "absolute right-1.5",
                                    table: "w-full border-collapse",
                                    head_row: "flex font-medium text-gray-900",
                                    head_cell:
                                      "2xl:m-0.5 xl:m-0.5 lg:m-0.5 md:m-0.5 w-full font-normal text-sm",
                                    row: "flex w-full mt-2",
                                    cell: "text-gray-600 rounded-full 2xl:h-9 xl:h-9 lg:h-9 md:h-8 h-7 w-full text-center text-sm p-0 m-0.5 xl:m-0.5 lg:m-0.5 md:m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-transperent [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-transperent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                    day: "2xl:h-9 2xl:w-9 xl:h-9 xl:w-9 lg:h-9 lg:w-9 md:h-8 md:w-8 h-7 w-7 p-0 font-normal",
                                    day_range_end: "day-range-end",
                                    day_selected:
                                      "rounded-full bg-primary text-white hover:bg-primary hover:text-white focus:bg-primary focus:text-white",
                                    day_today:
                                      "border border-primary text-black rounded-full",
                                    day_outside:
                                      "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                    day_disabled: "text-gray-500 opacity-50",
                                    day_hidden: "invisible",
                                  }}
                                  components={{
                                    IconLeft: ({ ...props }) => (
                                      <FaCircleChevronLeft
                                        {...props}
                                        className="2xl:h-6 2xl:w-6 xl:h-6 xl:w-6 h-5 w-5 stroke-2"
                                        color="#37489B"
                                      />
                                    ),
                                    IconRight: ({ ...props }) => (
                                      <FaCircleChevronRight
                                        {...props}
                                        className="2xl:h-6 2xl:w-6 xl:h-6 xl:w-6 h-5 w-5 stroke-2"
                                        color="#37489B"
                                      />
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                              <div className="">
                                <h5 className="text-lg">Select time</h5>
                                <div className="py-3 2xl:h-[323px] xl:h-[323px] lg:h-[315px] h-[300px] overflow-auto">
                                  <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4 gap-3">
                                    {time_arr.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-span-6 2xl:col-span-3 xl:col-span-4 lg:col-span-4 md:col-span-6"
                                        >
                                          <div
                                            onClick={(e) =>
                                              handleTimeClick(item.value)
                                            }
                                            className={`2xl:text-base xl:text-base lg:text-base md:text-sm text-sm cursor-pointer rounded-lg text-center 2xl:py-3 xl:py-3 py-2 2xl:px-6 xl:px-6 px-5 ${
                                              selectTime === item.value
                                                ? "bg-primary text-white"
                                                : "border border-primary"
                                            }`}
                                          >
                                            <h5 className="2xl:text-base xl:text-base lg:text-sm md:text-base text-sm">
                                              {item.name}
                                            </h5>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                {formik.touched.bookingTime &&
                                formik.errors.bookingTime ? (
                                  <div className="text-danger my-1 text-sm">
                                    {formik.errors.bookingTime}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="2xl:py-5 xl:py-5 lg:py-5 md:py-3 py-2 flex justify-center items-center">
                        <Button
                          className="flex items-center justify-center capitalize bg-primary text-base font-normal 2xl:min-w-[380px] xl:min-w-[360px] lg:min-w-[340px] md:min-w-[320px] min-w-[260px]"
                          type="submit"
                          disabled={loader}
                        >
                          <span>Submit</span>{" "}
                          {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="bg-cover bg-center 2xl:h-[700px] xl:h-[480px] lg:h-[400px] md:h-[300px] h-[250px] w-full"
                style={{
                  backgroundImage: `url(${footerbg1})`,
                }}
              >
                <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 pt-4">
                  <div className="flex justify-between 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap gap-5">
                    <div className="flex-1 2xl:mt-14 xl:mt-14 lg:mt-14 md:mt-12 mt-10">
                      <img
                        src={logo}
                        alt="logo"
                        className="2xl:h-[105px] xl:h-[90px] lg:h-22 md:h-14 h-14 w-auto"
                      ></img>
                      <h4 className="2xl:text-[38px] xl:text-3xl lg:text-2xl text-xl text-white font-medium 2xl:mt-5 xl:mt-5 mt-3 tracking-wide">
                        We get things done!
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="bg-cover bg-center inset-0 2xl:h-[800px] xl:h-[750px] lg:h-[650px] md:h-[600px] sm:h-[550px] h-[500px] w-full"
                style={{ backgroundImage: `url(${bg3})` }}
              >
                <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:py-16 xl:py-16 lg:py-14 2xl:py-12 xl:py-12 lg:py-12 py-12">
                  <div className="flex items-center gap-2">
                    <img
                      src={
                        selectedService.subServices[0].serviceIcon
                          ? IMAGE_URL +
                            selectedService.subServices[0].serviceIcon
                          : noPicture
                      }
                      alt=""
                      crossOrigin="anonymous"
                      className="2xl:w-16 2xl:h-16 xl:h-16 xl:w-16 h-14 w-14 object-center"
                    ></img>
                    <h5 className="2xl:text-xl xl:text-xl lg:text-lg font-medium">
                      {selectedService?.subServices[0]?.serviceName
                        ? he.decode(
                            selectedService?.subServices[0]?.serviceName
                          )
                        : "-"}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 2xl:py-8 xl:py-8 lg:py-8 md:py-6">
                <div className="2xl:-mt-[45%] xl:-mt-[50%] lg:-mt-[55%] md:-mt-[70%] sm:-mt-[70%] xs:-mt-[75%] -mt-[80%] bg-white rounded-[25px]">
                  <div className="rounded-[25px] xl:py-10 2xl:p-10 xl:p-10 lg:p-10 md:p-6 p-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-1"
                        >
                          Something else
                        </label>
                        <input
                          id="regular-form-1"
                          name="requiredJob"
                          type="text"
                          placeholder="Describe your required job"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.requiredJob}
                        ></input>
                        {formik.touched.requiredJob &&
                        formik.errors.requiredJob ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.requiredJob}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-2"
                        >
                          Do you require materials
                        </label>
                        <textarea
                          id="regular-form-2"
                          name="requiredMaterials"
                          type="text"
                          placeholder="Enter here if materials are required"
                          rows="2"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.requiredMaterials}
                        ></textarea>
                        {formik.touched.requiredMaterials &&
                        formik.errors.requiredMaterials ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.requiredMaterials}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-2"
                        >
                          Description
                        </label>
                        <textarea
                          id="regular-form-2"
                          name="description"
                          type="text"
                          placeholder="Brief description of required work"
                          rows="2"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                        ></textarea>
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-2"
                        >
                          Enter Site Address
                        </label>
                        <textarea
                          id="regular-form-2"
                          name="address"
                          type="text"
                          placeholder="Star typing address or postcode..."
                          rows="2"
                          className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                        ></textarea>
                        {formik.touched.address && formik.errors.address ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <label
                          className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          htmlFor="regular-form-5"
                        >
                          City
                        </label>
                        <Select
                          components={animatedComponents}
                          options={city_options}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          name="city"
                          value={formik.values.city}
                          onChange={(value) =>
                            formik.setFieldValue("city", value)
                          }
                          onBlur={() => formik.setFieldTouched("city", true)}
                          placeholder="Select City"
                        />
                        {formik.touched.city && formik.errors.city ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.city}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4 mt-3 w-auto">
                          <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6">
                            <label
                              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                              htmlFor="regular-form-2"
                            >
                              Upload photos
                            </label>
                            <p className="text-sm my-2 text-info">
                              {formik?.values?.workingImage?.name || ""}
                            </p>
                            {formik.values.workingImage ? (
                              <div className="border border-dashed border-2 border-[#D9D9D9] rounded-md p-4 flex justify-center items-center">
                                {formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "jpeg" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "png" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "svg" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "png" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "jpg" ||
                                formik?.values.workingImage?.type
                                  ?.split("/")
                                  ?.pop() === "gif" ? (
                                  <div className="cursor-pointer image-fit">
                                    <div className="m-2 relative">
                                      <img
                                        alt="document"
                                        className="rounded-lg h-[300px] w-full"
                                        src={
                                          formik?.values?.workingImage
                                            ? URL.createObjectURL(
                                                formik.values.workingImage
                                              )
                                            : noImage
                                        }
                                      />
                                      <button
                                        className="absolute -right-2 -top-1 h-5 w-5 bg-[#fff] rounded-full flex justify-center items-center"
                                        onClick={handleRemoveImage}
                                        type="button"
                                      >
                                        <MdClose className="w-4 h-4 font-bold" />
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="cursor-pointer image-fit">
                                    <div className="m-2 relative">
                                      <img
                                        alt="document"
                                        className="rounded-lg h-20 w-20"
                                        src={fileImg}
                                      />
                                      <button
                                        className="absolute -right-2 -top-1 h-5 w-5 bg-[#fff] rounded-full flex justify-center items-center"
                                        onClick={handleRemoveImage}
                                        type="button"
                                      >
                                        <MdClose className="w-4 h-4 font-bold" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="h-52 rounded-md border border-dashed border-2 border-[#D9D9D9] text-center flex justify-center items-center">
                                <div>
                                  <h6 className="font-bold text-sm">
                                    Drag and Drop Image & Upload Image
                                  </h6>
                                  <div className="mt-6">
                                    <label
                                      htmlFor="workingImage"
                                      className="py-2.5 px-5 text-sm rounded-md border border-dashed border-2 border-[#D9D9D9] text-secondary"
                                    >
                                      Select file
                                      <input
                                        id="workingImage"
                                        type="file"
                                        name="workingImage"
                                        accept="image/*"
                                        className="w-1 h-1 opacity-0"
                                        onChange={(event) => {
                                          formik.setFieldValue(
                                            "workingImage",
                                            event.currentTarget.files[0]
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {formik.touched.workingImage &&
                        formik.errors.workingImage ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.workingImage}
                          </div>
                        ) : null}
                      </div>
                      <div className="mt-3">
                        <div className="flex gap-3">
                          <Checkbox
                            id="horizontal-list-react1"
                            color="indigo"
                            name="selection"
                            value="now"
                            checked={formik.values.selection === "now"}
                            onChange={() =>
                              formik.setFieldValue("selection", "now")
                            }
                            label={
                              <Typography
                                color="black"
                                className="flex font-medium"
                              >
                                Now
                              </Typography>
                            }
                          />
                          <Checkbox
                            id="horizontal-list-react2"
                            color="indigo"
                            name="selection"
                            value="later"
                            checked={formik.values.selection === "later"}
                            onChange={() =>
                              formik.setFieldValue("selection", "later")
                            }
                            label={
                              <Typography
                                color="black"
                                className="flex font-medium"
                              >
                                Later
                              </Typography>
                            }
                          />
                        </div>
                        {formik.errors.selection ? (
                          <div className="text-danger my-1 text-sm">
                            {formik.errors.selection}
                          </div>
                        ) : null}
                        {formik.values.selection === "later" ? (
                          <p className="my-2 text-[#000000]">
                            Please select your time and date when you require
                            the service one of our Fixers will be with you on
                            you selected time and date
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mt-3 font-medium text-primary">
                        Estimate Cost : £{formik?.values?.price || 0}
                      </div>
                      {formik.values.selection === "later" ? (
                        <div className="2xl:py-6 xl:py-6 lg:py-6 py-4">
                          <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 gap-4">
                            <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 w-full">
                              <div className="table-row-shadow 2xl:p-4 xl:p-4 lg:p-4 p-2 rounded-[20px] w-auto">
                                <DayPicker
                                  mode="single"
                                  showOutsideDays
                                  disabled={disabledDays}
                                  selected={formik.values.bookingDate}
                                  onSelect={handleDayClick}
                                  className="border-0"
                                  required={true}
                                  classNames={{
                                    caption:
                                      "flex justify-center py-4 mb-4 relative items-center border-b-2",
                                    caption_label:
                                      "text-sm font-medium text-gray-900",
                                    nav: "flex items-center",
                                    nav_button:
                                      "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-full transition-colors duration-300",
                                    nav_button_previous: "absolute left-1.5",
                                    nav_button_next: "absolute right-1.5",
                                    table: "w-full border-collapse",
                                    head_row: "flex font-medium text-gray-900",
                                    head_cell:
                                      "2xl:m-0.5 xl:m-0.5 lg:m-0.5 md:m-0.5 w-full font-normal text-sm",
                                    row: "flex w-full mt-2",
                                    cell: "text-gray-600 rounded-full 2xl:h-9 xl:h-9 lg:h-9 md:h-8 h-7 w-full text-center text-sm p-0 m-0.5 xl:m-0.5 lg:m-0.5 md:m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-transperent [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-transperent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                                    day: "2xl:h-9 2xl:w-9 xl:h-9 xl:w-9 lg:h-9 lg:w-9 md:h-8 md:w-8 h-7 w-7 p-0 font-normal",
                                    day_range_end: "day-range-end",
                                    day_selected:
                                      "rounded-full bg-primary text-white hover:bg-primary hover:text-white focus:bg-primary focus:text-white",
                                    day_today:
                                      "border border-primary text-black rounded-full",
                                    day_outside:
                                      "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                                    day_disabled: "text-gray-500 opacity-50",
                                    day_hidden: "invisible",
                                  }}
                                  components={{
                                    IconLeft: ({ ...props }) => (
                                      <FaCircleChevronLeft
                                        {...props}
                                        className="2xl:h-6 2xl:w-6 xl:h-6 xl:w-6 h-5 w-5 stroke-2"
                                        color="#37489B"
                                      />
                                    ),
                                    IconRight: ({ ...props }) => (
                                      <FaCircleChevronRight
                                        {...props}
                                        className="2xl:h-6 2xl:w-6 xl:h-6 xl:w-6 h-5 w-5 stroke-2"
                                        color="#37489B"
                                      />
                                    ),
                                  }}
                                />
                              </div>
                              {formik.touched.bookingDate &&
                              formik.errors.bookingDate ? (
                                <div className="text-danger my-1 text-sm">
                                  {formik.errors.bookingDate}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
                              <div className="">
                                <h5 className="text-lg">Select time</h5>
                                <div className="py-3 2xl:h-[323px] xl:h-[323px] lg:h-[315px] h-[300px] overflow-auto">
                                  <div className="grid grid-cols-12 2xl:gap-6 xl:gap-6 lg:gap-6 md:gap-4 gap-3">
                                    {time_arr.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-span-6 2xl:col-span-3 xl:col-span-4 lg:col-span-4 md:col-span-6"
                                        >
                                          <div
                                            onClick={(e) =>
                                              handleTimeClick(item.value)
                                            }
                                            className={`2xl:text-base xl:text-base lg:text-base md:text-sm text-sm cursor-pointer rounded-lg text-center 2xl:py-3 xl:py-3 py-2 2xl:px-6 xl:px-6 px-5 ${
                                              selectTime === item.value
                                                ? "bg-primary text-white"
                                                : "border border-primary"
                                            }`}
                                          >
                                            <h5 className="2xl:text-base xl:text-base lg:text-sm md:text-base text-sm">
                                              {item.name}
                                            </h5>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                {formik.touched.bookingTime &&
                                formik.errors.bookingTime ? (
                                  <div className="text-danger my-1 text-sm">
                                    {formik.errors.bookingTime}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="2xl:py-5 xl:py-5 lg:py-5 md:py-3 py-2 flex justify-center items-center">
                        <Button
                          className="flex items-center justify-center capitalize bg-primary text-base font-normal 2xl:min-w-[380px] xl:min-w-[360px] lg:min-w-[340px] md:min-w-[320px] min-w-[260px]"
                          type="submit"
                          disabled={loader}
                        >
                          <span>Submit</span>{" "}
                          {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="bg-cover bg-center 2xl:h-[700px] xl:h-[490px] lg:h-[400px] md:h-[300px] h-[250px] w-full 2xl:block xl:block lg:block md:block hidden"
                style={{
                  backgroundImage: `url(${footerbg2})`,
                }}
              >
                <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 pt-4">
                  <div className="flex justify-between 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap gap-5">
                    <div className="flex-1 2xl:mt-14 xl:mt-14 lg:mt-14 md:mt-12 mt-10">
                      <img
                        src={logo}
                        alt="logo"
                        className="2xl:h-[105px] xl:h-[90px] lg:h-22 md:h-14 h-14 w-auto"
                      ></img>
                      <h4 className="2xl:text-[38px] xl:text-3xl lg:text-2xl text-xl text-white font-medium 2xl:mt-5 xl:mt-5 mt-3 tracking-wide">
                        We get things done!
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-full w-full mt-8 2xl:hidden xl:hidden lg:hidden md:hidden block">
                <img src={footerMobile} alt="" className="w-full h-full"></img>
              </div>
            </>
          )}
        </>
      )}
      <Footer />

      <Dialog
        open={open}
        handler={handleOpen}
        size="sm"
        className="rounded-none 2xl:min-w-[35%] xl:min-w-[35%] xl:max-w-[40%] lg:min-w-[40%] md:min-w-[50%] min-w-[90%]"
      >
        <DialogBody>
          <div className="my-4 flex justify-center items-center">
            <Lottie options={defaultOptions} height={144} width={144} />
          </div>
          <div className="my-3">
            <h6 className="text-primary font-medium text-center 2xl:text-xl xl:text-xl lg:text-xl text-lg">
              Your Booking Request sent
            </h6>
            <h4 className="text-primary font-semibold text-center text-2xl py-3">
              Successful!
            </h4>
          </div>
          <div className="flex justify-center items-center py-5">
            <Button
              className="text-sm py-3 rounded-xl w-[50%] capitalize font-medium bg-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Done
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default NewHome;
