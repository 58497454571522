import React, { Fragment, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  IconButton,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { MdOutlineChevronLeft } from "react-icons/md";
import locationicon from "../../assets/images/pages/location.png";
import { useNavigate, useParams } from "react-router-dom";
import { MdClose } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import Lottie from "react-lottie";
import * as animationData from "../../assets/images/json/payment.json";
import * as paymentfailed from "../../assets/images/json/paymentfailed.json";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  addBookingReviewApi,
  addFavouriteBookingApi,
  cancelServiceApi,
  createPaymentIntentApi,
  getPricingApi,
  getServiceDetailsApi,
  paymentSuccessApi,
} from "../../services/ApiServices";
import { IMAGE_URL } from "../../services/Api";
import noImage from "../../assets/images/no-image.png";
import moment from "moment";
import he from "he";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import StripeProvider from "./StripeProvider";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { X } from "lucide-react";
import { FaDownload } from "react-icons/fa6";
import html2pdf from "html2pdf.js";
import logo from "../../assets/images/home/logo-lg.png";
import { help_arr, van_arr } from "../../common/data";
import van1 from "../../assets/images/booking/van-1.png";
import helpImg1 from "../../assets/images/booking/help-1.png";

const StarRating = ({ value, onChange }) => {
  const handleClick = (index) => {
    onChange(index + 1);
  };

  return (
    <div>
      {[...Array(5)].map((star, index) => (
        <span
          key={index}
          style={{
            cursor: "pointer",
            fontSize: "35px",
            color: index < value ? "#FFC107" : "#D7D7D7",
          }}
          onClick={() => handleClick(index)}
        >
          ★
        </span>
      ))}
    </div>
  );
};

const BookingDetailView = () => {
  const { id } = useParams();
  const token = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [cartModal, setCartModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [serviceInfo, setServiceInfo] = useState([]);
  const [loader, setLoader] = useState(false);
  const [paymentFailedModal, setPaymentFailedModal] = useState(false);
  const [intentId, setIntentId] = useState("");
  const [chargeId, setChargeId] = useState("");
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [priceData, setPriceData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [isPaymentIntent, setIsPaymentIntent] = useState(false);
  const [tipAmount, setTipAmount] = useState(0);

  //success payment modal
  const handleOpenSuccessModal = (e) => setSuccessModal(true);
  const handleCloseSuccessModal = (e) => setSuccessModal(false);

  //tip modal
  const [tipModal, setTipModal] = useState(false);

  //cancel order modal
  const handleOpen = () => setOpen(!open);

  //review modal
  const handleCloseReviewModal = (e) => {
    setReviewModal(false);
    formik.resetForm();
  };

  //close cart modal
  const closeCartModal = () => {
    setCartModal(false);
  };

  //open cart modal
  const openCartModal = () => {
    setCartModal(true);
  };

  //tip modal
  const handleOpenTip = () => {
    setTipModal(true);
  };

  const handleCloseTip = () => {
    setTipModal(false);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const handleOpenPaymentModal = () => {
    setOpenPaymentModal(true);
  };

  const handleOpenFailedModal = () => {
    setPaymentFailedModal(true);
  };

  const handleCloseFailedModal = () => {
    setPaymentFailedModal(false);
  };

  const handleSuccessPayment = () => {
    handleClosePaymentModal();
    navigate("/mybooking");
  };

  const getServiceDetails = () => {
    setPending(true);
    getServiceDetailsApi(id)
      .then((res) => {
        const data = res.data.info;
        const service_name = data?.mainServicedata?.serviceName
          ? he.decode(he.decode(data?.mainServicedata?.serviceName))
          : "";
        setServiceInfo(data);
        setPending(false);

        if (service_name === "Transport & Moving") {
          const vehicle_info = van_arr.find((van) => van.name === data.vehicle);
          const helper_info = help_arr.find(
            (help) => help.name === data.helper
          );
        }
      })
      .catch((err) => {
        setPending(false);
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getServiceDetails();
  }, []);

  const handleAddToFavourite = () => {
    const params = {
      bookingId: id,
    };
    addFavouriteBookingApi(params)
      .then((res) => {
        const message = res.data.message;

        toast.success(
          message || "Booking details added to favourite collection!!!"
        );
        getServiceDetails();
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const handleCancel = () => {
    const params = {
      bookingId: id,
    };
    cancelServiceApi(params)
      .then((res) => {
        const message = res.data.message;
        setOpen(false);

        toast.success(message || "Canceled booking Successfully!!!");
        navigate("/mybooking");
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  const handleCheckout = () => {
    handleOpenTip();
  };

  const handleCreateIntent = (e) => {
    e.preventDefault();
    const final_amount = Number(totalCount) + Number(tipAmount);

    const obj = {
      amount: Number(final_amount),
    };

    setIsPaymentIntent(true);
    createPaymentIntentApi(obj)
      .then((res) => {
        const clientSecret = res.data.clientSecret;
        const chargeId = res.data.chargeId;
        setIntentId(clientSecret);
        setChargeId(chargeId);
        openCartModal();
        handleCloseTip();
        setIsPaymentIntent(false);
      })
      .catch((err) => {
        setIsPaymentIntent(false);
        console.log("err >>>", err);
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const paymentFailedOptions = {
    loop: true,
    autoplay: true,
    animationData: paymentfailed,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const validationSchema = Yup.object({
    rating: Yup.number()
      .min(1, "Rating must be at least 1 star")
      .max(5, "Rating cannot exceed 5 stars")
      .required("Rating is required!!!"),
    reviewText: Yup.string().required("Rating Text is required !!!"),
  });

  const formik = useFormik({
    initialValues: {
      rating: 0,
      reviewText: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const params = {
        bookingId: id,
        reviewTo: serviceInfo.bookingTo,
        reviewStar: values.rating,
        reviewText: values.reviewText,
      };
      addBookingReviewApi(params)
        .then((res) => {
          const message = res.data.message;
          setLoader(false);

          toast.success(message || "Review submited Successfully!!!");
          formik.resetForm();
          setReviewModal(false);
          getServiceDetails();
        })
        .catch((err) => {
          const message = err.response.data.message;
          setLoader(false);
          console.log("err >>>", err);

          toast.error(message || "Somthing went wrong!!");
        });
    },
  });

  const getPriceInfo = () => {
    getPricingApi(id)
      .then((res) => {
        const data = res.data.data;
        const adminCharge = res.data.adminCharge;
        const final_data = { ...data, ...adminCharge };

        setPriceData(final_data);
      })
      .catch((err) => {
        console.log("err >>>", err);
      });
  };

  useEffect(() => {
    getPriceInfo();
  }, []);

  const calculatePercentage = (serviceCharge, total) => {
    if (total > 0) {
      const count = (serviceCharge * total) / 100;
      const final_price = count.toFixed(2);
      return final_price;
    }
    return 0;
  };

  useEffect(() => {
    if (priceData?.finalPrice && priceData?.admin_charge) {
      const percentage = calculatePercentage(
        priceData.admin_charge,
        Number(priceData?.finalPrice)
      );
      const newTotal =
        parseFloat(priceData?.finalPrice) + parseFloat(percentage);
      const final_price = newTotal.toFixed(2);
      setTotalCount(final_price);
    }
  }, [priceData?.finalPrice, priceData?.admin_charge]);

  const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState("");
    const [loader, setLoader] = useState(false);

    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoader(true);

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: name,
        },
      });

      if (error) {
        if (error.message) {
          toast.error(error || "Somthing went wrong!!");
        }
        console.error("error >>>", error.message);
      } else {
        const { error: confirmError, paymentIntent } =
          await stripe.confirmCardPayment(props.intentId, {
            payment_method: paymentMethod.id,
          });

        if (confirmError) {
          setLoader(false);
          handleOpenFailedModal();
          console.error("confirmError >>>", confirmError);
        } else {
          const final_amount = Number(totalCount) + Number(tipAmount);

          const params = {
            transactionAmount: Number(final_amount),
            chargeId: chargeId,
            bookingId: Number(id),
            tipAmount: tipAmount,
            transactionTo: serviceInfo?.bookingTo,
          };

          paymentSuccessApi(params)
            .then((res) => {
              const data = res.data.info;
              const status = res.data.status;
              const message = res.data.message;
              setLoader(false);

              if (status === 1) {
                handleOpenPaymentModal();
                setCartModal(false);
              } else {
                toast.error(message || "Somthing went wrong!!");
                setCartModal(false);
              }
            })
            .catch((err) => {
              const message = err.response.data.message;
              console.log("err >>>", err);
              setLoader(false);
              setCartModal(false);
              toast.error(message || "Somthing went wrong!!");
            });
        }
      }
    };

    const cardStyle = {
      style: {
        base: {
          fontSize: "16px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
          padding: "10px",
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="mt-2">
            <label>
              Card information
              <div className="shadow-sm ring-1 ring-inset ring-[#D9D9D9] py-3 px-4 rounded-xl">
                <CardElement options={cardStyle} />
              </div>
            </label>
          </div>
          <div className="mt-3">
            <label className="mb-2">Cardholder name</label>
            <input
              type="text"
              placeholder="Enter Cardholder name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="focus:outline-none py-3 px-4 block min-w-full w-full rounded-xl text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#aab7c4] focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              required
            />
          </div>
          <div className="flex justify-center items-center py-5">
            <Button
              type="submit"
              disabled={!stripe}
              className="flex items-center justify-center w-[70%] text-sm py-3 rounded-xl capitalize font-medium bg-primary"
            >
              Pay {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
            </Button>
          </div>
        </form>
      </>
    );
  };

  const handleDownloadInvoce = async () => {
    setIsLoading(true);

    const invoice_id = serviceInfo.bookingTransaction[0].transactionsId;
    const invoice_date = moment(serviceInfo.bookingDate).format("YYYY-MM-DD");
    const customer_name = `${serviceInfo.bookingby.firstName} ${serviceInfo.bookingby.lastName}`;
    const customer_email = serviceInfo.bookingby.emailId;
    const service_name = he.decode(
      serviceInfo?.mainServicedata?.serviceName || ""
    );
    const service_price = `£${serviceInfo.price}`;
    const material_arr = serviceInfo.materialDetails;
    const subtotal = `£${Number(priceData?.finalPrice)?.toFixed(2) || 0}`;
    const service_charge = priceData?.admin_charge;
    const service_charge_amount = calculatePercentage(
      priceData?.admin_charge,
      Number(priceData?.finalPrice)
    );
    const total_amount = `£${serviceInfo.bookingTransaction[0].transactionAmount}`;

    const content = `<div class="container" style="margin-top: 30px; border-radius: 10px; border: 1px solid #2b426e; padding: 20px;">
        <div class="invoice-header" style="background-color: #e3e8ec; padding: 1rem; border-radius: 0.5rem; font-size:32px: font-weight:bold">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <p style="margin: 0; font-size:32px; font-weight:bold; position:relative; top:-15px">Invoice</p>
               <div><img alt="logo" src="${logo}" width="80" /></div>
            </div>
        </div>
        <div class="invoice-details" style="border-radius: 10px; background-color: #e3e8ec; padding: 10px 0; margin-top:30px">
            <table style="width: 100%; border-collapse: collapse;">
                <tr>
                    <th style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: left;">Invoice No:</th>
                    <td style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: right;">${invoice_id}</td>
                </tr>
                <tr>
                    <th style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: left;">Invoice Date:</th>
                    <td style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: right;">${invoice_date}</td>
                </tr>
                <tr>
                    <th style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: left;">Customer Name:</th>
                    <td style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: right;">${customer_name}</td>
                </tr>
                <tr>
                    <th style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: left;">Email:</th>
                    <td style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: right;">${customer_email}</td>
                </tr>
                <tr>
                    <th style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: left;">Service Name:</th>
                    <td style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: right;">${service_name}</td>
                </tr>
                <tr>
                    <th style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: left;">Service Price:</th>
                    <td style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: right;">${service_price}</td>
                </tr>
            </table>
        </div>
        <div class="product-details" style="margin-bottom:20px">
            <h4 style="margin: 10px 0px; font-weight:bold">Raw Material Details</h4>
            ${
              material_arr.length
                ? `  <table style="width: 100%; border-collapse: collapse; margin-top:20px">
                  <tr style="background-color: #e3e8ec; border-radius: 10px">
                    <th style="border-radius: 10px 0px 0px 10px; padding: 10px 15px; position:relative; top:-10px; text-align: left; border-radius: 10px 0px 0px 10px;">
                      Material
                    </th>
                    <th style="border-radius: 0px 10px 10px 0px; padding: 10px 15px; position:relative; top:-10px; text-align: end; border-radius: 0px 10px 10px 0px;">
                      Price
                    </th>
                  </tr>
                  ${material_arr.map((item, index) => {
                    return `
                        <tr key=${index}>
                          <td style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px;">
                            <b>${item.materialName || "-"}</b>
                          </td>
                          <td style="padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px; text-align: end;">
                            £${item.materialPrice || 0}
                          </td>
                        </tr>
                        `;
                  })}
                </table>`
                : `<div style="text-center">
                  <h5 style="font-size: 24px font-weight:semibold"></h5>
                </div>`
            }
        </div>
        <div class="total-section" style="border-top: 1px solid #2b426e; padding-top: 20px; text-align: right;">
            <div style="display: flex; justify-content: end; align-items: center;">
                <div>
                    <div style="display: flex; gap: 0.75rem; align-items: center;">
                        <h4 style="margin: 0px; width: 100px; font-weight:bold">Subtotal:</h4>
                        <span>${subtotal || 0}</span>
                    </div>
                    <div style="display: flex; gap: 0.75rem; align-items: center; margin-top: 15px;">
                        <h4 style="margin: 0px; width: 100px; font-weight:bold">Tax (${service_charge}%):</h4>
                        <span>${service_charge_amount}</span>
                    </div>
                    <div style="display: flex; gap: 0.75rem; align-items: center; margin-top: 15px;">
                        <h4 style="margin: 0px; width: 100px; font-weight:bold">Total:</h4>
                        <span>${total_amount}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>`;

    const tempElement = document.createElement("div");
    tempElement.innerHTML = content;

    const options = {
      margin: 10,
      filename: `Booking-Invoice-${invoice_id}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .set(options)
      .from(tempElement)
      .save()
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("PDF generation failed:", error);
        setIsLoading(false);
      });
  };

  return (
    <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5 my-10 2xl:mt-28 xl:mt-28 lg:mt-24 mt-24">
      <div className="flex items-center gap-2 my-5">
        <IconButton
          variant="outlined"
          size="sm"
          className="rounded-full border-[#B4B4B4]"
          onClick={(e) => navigate("/mybooking")}
        >
          <MdOutlineChevronLeft className="w-6 h-6" color="#071D33" />
        </IconButton>
      </div>
      <div className="rounded-lg table-row-shadow p-4">
        {pending ? (
          <div className="grid grid-cols-12 animate-pulse">
            <div className="col-span-12 2xl:col-span-3 xl:col-span-3 lg:col-span-3">
              <div className="2xl:mx-4 xl:mx-4 lg:mx-4">
                <div className="py-2 flex justify-center items-center 2xl:justify-start xl:justify-start lg:justify-start md:justify-start">
                  <div className="w-[86px] h-[86px] rounded-full bg-gray-300">
                    <div className="flex justify-center items-center h-full w-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-10 w-10 text-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <h6 className="h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-full"></h6>
                  <div className="flex py-2">
                    <h6 className="text-lg text-black h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-full"></h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 2xl:col-span-9 xl:col-span-9 lg:col-span-9">
              <div className="flex flex-wrap py-2">
                <div className="flex">
                  <div className="my-2">
                    <h6 className="my-0.5 h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-32"></h6>
                    <h6 className="h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-32 my-2"></h6>
                  </div>
                  <div className="my-2 ml-14">
                    <h6 className="my-0.5 h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-32"></h6>
                    <h6 className="my-2 h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-32"></h6>
                  </div>
                  <div className="2xl:px-10 px-3 2xl:block xl:block lg:block md:block hidden">
                    <div className="ml-[0.20rem] border-l border-[#37489B] 2xl:h-5 xl:h-5 lg:h-5 h-7"></div>
                    <div className="flex justify-center items-center">
                      <div className="w-2 h-2 mt-1 rounded-full bg-[#37489B]"></div>
                    </div>
                    <div className="mt-1 ml-[0.20rem] border-l border-[#37489B] 2xl:h-5 xl:h-5 lg:h-5 h-7"></div>
                  </div>
                </div>
                <div className="flex">
                  <div className="my-2">
                    <h6 className="my-0.5 h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-32"></h6>
                    <h6 className="my-2 h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-32"></h6>
                  </div>
                  <div className="my-2 ml-14">
                    <h6 className="my-0.5 h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-32"></h6>
                    <h6 className="my-2 h-5 bg-gray-300 rounded-xl 2xl:w-40 xl:w-40 lg:w-40 md:w-40 w-32"></h6>
                  </div>
                </div>
              </div>
              <div>
                {[1, 2, 3, 4, 5, 6].map((star) => (
                  <div className="py-1" key={star}>
                    <h6 className="my-2 h-6 bg-gray-300 rounded-xl 2xl:w-[70%] xl:w-[70%] lg:w-[70%] md:w-[70%] w-full"></h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12">
            <div className="col-span-12 2xl:col-span-3 xl:col-span-3 lg:col-span-3">
              <div className="2xl:mx-4 xl:mx-4 lg:mx-4">
                <div className="my-4">
                  <h5 className="font-medium text-black">Fixer</h5>
                </div>
                <div className="my-2 flex 2xl:justify-start xl:justify-start lg:justify-start md:justify-start justify-center items-center">
                  <Avatar
                    src={
                      serviceInfo?.bookingto
                        ? IMAGE_URL + serviceInfo?.bookingto?.profilePic
                        : noImage
                    }
                    alt="avatar"
                    size="xl"
                    className="w-[86px] h-[86px]"
                    crossOrigin="anonymous"
                  />
                </div>
                <div className="my-4 2xl:text-start xl:text-start lg:text-start md:text-start text-center">
                  <h6 className="font-medium text-base text-black">
                    {" "}
                    {`${serviceInfo?.bookingto?.firstName || "-"} ${
                      serviceInfo?.bookingto?.lastName || ""
                    }`}
                  </h6>
                  <div className="flex 2xl:justify-start xl:justify-start lg:justify-start md:justify-start justify-center items-center 2xl:my-4 xl:my-4 lg:my-3 my-1">
                    <img
                      src={locationicon}
                      alt=""
                      className="w-4.5 h-5 p-0.5"
                    ></img>
                    <p className="text-xs font-normal my-0.5 ml-1">
                      {serviceInfo.bookingto
                        ? serviceInfo.bookingto.streetAddress
                        : ""}
                    </p>
                  </div>
                </div>
                {serviceInfo.bookingStatus === 2 &&
                serviceInfo?.paymentStatus === "done" ? (
                  <div className="my-5 2xl:justify-start xl:justify-start lg:justify-start md:justify-start justify-center flex items-center">
                    <Button
                      className="bg-primary font-normal capitalize flex items-center gap-2 px-4"
                      onClick={handleDownloadInvoce}
                    >
                      <FaDownload fontSize={18} />
                      Download Invoce{" "}
                      {isLoading ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-span-12 2xl:col-span-9 xl:col-span-9 lg:col-span-9">
              <div className="flex flex-wrap my-3">
                <div className="flex">
                  <div className="my-2">
                    <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                      Booking ID
                    </p>
                    <h6 className="font-medium text-sm text-black my-2">
                      {serviceInfo.bookingId}
                    </h6>
                  </div>
                  <div className="my-2 ml-14">
                    <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                      Date
                    </p>
                    <h6 className="font-medium text-sm text-black my-2">
                      {moment(serviceInfo.bookingDate).format("DD MMMM, YYYY")}
                    </h6>
                  </div>
                  <div className="xl:px-10 px-3">
                    <div className="ml-[0.20rem] border-l border-[#37489B] 2xl:h-5 xl:h-5 lg:h-5 h-10"></div>
                    <div className="flex justify-center items-center">
                      <div className="w-2 h-2 mt-1 rounded-full bg-[#37489B]"></div>
                    </div>
                    <div className="mt-1 ml-[0.20rem] border-l border-[#37489B] 2xl:h-5 xl:h-5 lg:h-5 h-10"></div>
                  </div>
                </div>
                <div className="flex">
                  <div className="my-2">
                    <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                      Time
                    </p>
                    <h6 className="font-medium text-sm text-black my-2">
                      {serviceInfo.bookingTime}
                    </h6>
                  </div>
                  <div className="my-2 ml-14">
                    <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                      Status
                    </p>
                    {serviceInfo.bookingStatus === 0 ? (
                      <h6 className="font-medium text-sm text-pending my-2">
                        Waiting for Confirmation
                      </h6>
                    ) : serviceInfo.bookingStatus === 1 ? (
                      <h6 className="font-medium text-sm text-warning my-2">
                        In Progress
                      </h6>
                    ) : serviceInfo.bookingStatus === 2 ? (
                      <h6 className="font-medium text-sm text-success my-2">
                        Completed
                      </h6>
                    ) : serviceInfo.bookingStatus === 3 ? (
                      <h6 className="font-medium text-sm text-danger my-2">
                        Canceled
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {serviceInfo?.mainServicedata?.serviceName?.startsWith(
                "Transport &"
              ) ? (
                <div className="my-6">
                  <div className="flex items-center gap-6">
                    <div className="">
                      <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                        Vehicle Need
                      </p>
                      <div className="my-2">
                        <h6 className="font-medium text-sm text-black my-2">
                          {serviceInfo?.vehicleInfo?.name
                            ? serviceInfo?.vehicleInfo?.name
                            : "-"}
                        </h6>
                        <img
                          src={
                            serviceInfo?.vehicleInfo?.image
                              ? IMAGE_URL + serviceInfo?.vehicleInfo?.image
                              : noImage
                          }
                          alt=""
                          className="w-[83px] h-[83px] rounded-md"
                          crossOrigin="anonymous"
                        ></img>
                      </div>
                    </div>
                    <div className="">
                      <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                        Need Help With Loading And Unloading
                      </p>
                      <div className="my-2">
                        <h6 className="font-medium text-sm text-black my-2">
                          {serviceInfo?.helperInfo?.name
                            ? serviceInfo?.helperInfo?.name
                            : "-"}
                        </h6>
                        <div className="p-3 bg-primary w-[83px] h-[83px] rounded-md">
                          <img
                            src={
                              serviceInfo?.helperInfo?.image
                                ? IMAGE_URL + serviceInfo?.helperInfo?.image
                                : noImage
                            }
                            alt=""
                            className="rounded-md h-full w-full"
                            crossOrigin="anonymous"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="my-6">
                <div className="">
                  <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                    Service
                  </p>
                  <h6 className="font-medium text-sm text-black my-2">
                    {he.decode(serviceInfo?.mainServicedata?.serviceName || "")}
                  </h6>
                </div>
              </div>
              <div className="py-2">
                <div className="">
                  <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                    Service Trades
                  </p>
                  {serviceInfo?.subServices?.map((items, index) => {
                    return (
                      <h6
                        key={index}
                        className="font-medium text-sm text-black my-2"
                      >
                        {items?.servicedata
                          ? he.decode(
                              he.decode(items?.servicedata?.serviceName)
                            )
                          : "-"}
                      </h6>
                    );
                  })}
                </div>
              </div>
              <div className="my-6">
                <div className="">
                  <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                    Street Address
                  </p>
                  <h6 className="font-medium text-sm text-black my-2">
                    {serviceInfo?.address}
                  </h6>
                </div>
              </div>
              <div className="my-6">
                <div className="">
                  <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                    Details of Job
                  </p>
                  <h6 className="font-medium text-sm text-black my-2">
                    {serviceInfo?.requiredJob}
                  </h6>
                </div>
              </div>
              <div className="my-6">
                <div className="">
                  <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                    Require materials
                  </p>
                  <h6 className="font-medium text-sm text-black my-2">
                    {serviceInfo?.requiredMaterials}
                  </h6>
                </div>
              </div>
              {serviceInfo.bookingStatus === 0 ? (
                <>
                  <div className="my-6">
                    <div className="">
                      <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                        Image of job
                      </p>
                      <div className="my-4">
                        <img
                          src={IMAGE_URL + serviceInfo?.workingImage}
                          alt=""
                          className="w-[83px] h-[83px] rounded-md object-cover"
                          crossOrigin="anonymous"
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="my-6 flex justify-end items-end">
                    <Button
                      variant="outlined"
                      color="red"
                      className="capitalize 2xl:px-16 xl:px-16 lg:px-16 px-10"
                      onClick={handleOpen}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : serviceInfo.bookingStatus === 1 ? (
                <>
                  <div className="mt-6">
                    <div className="">
                      <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                        Image of job
                      </p>
                      <div className="my-4">
                        <img
                          src={IMAGE_URL + serviceInfo?.workingImage}
                          alt=""
                          className="w-[83px] h-[83px] rounded-md"
                          crossOrigin="anonymous"
                        ></img>
                      </div>
                      <div className="my-3">
                        <div className="flex flex-wrap gap-3">
                          <div>
                            <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                              Working hour
                            </h6>
                            <p className="text-black text-sm font-medium tracking-wide my-1">
                              {serviceInfo.bookingworkingHour}
                            </p>
                          </div>
                        </div>
                      </div>
                      {serviceInfo.bookingworkingHour !== "00:00:00" ? (
                        <>
                          <div className="flex flex-wrap gap-3">
                            <div className="">
                              <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                                Estimat Cost
                              </h6>
                              <p className="font-medium text-sm tracking-wide my-1">
                                £
                                {Number(priceData?.finalPrice)?.toFixed(2) || 0}
                              </p>
                            </div>
                            <div className="">
                              <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                                Service Charge ({priceData?.admin_charge}%)
                              </h6>
                              <p className="font-medium text-sm tracking-wide my-1">
                                £
                                {calculatePercentage(
                                  priceData?.admin_charge,
                                  Number(priceData?.finalPrice)
                                )}
                              </p>
                            </div>
                            <div className="">
                              <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                                Total Amount
                              </h6>
                              <p className="text-success text-sm font-medium tracking-wide my-1">
                                £{totalCount || 0}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : serviceInfo.bookingStatus === 2 ? (
                <>
                  <div className="my-6">
                    <div className="">
                      <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                        Image of job
                      </p>
                      <div className="my-4">
                        <img
                          src={IMAGE_URL + serviceInfo?.workingImage}
                          alt=""
                          className="w-[83px] h-[83px] rounded-md"
                          crossOrigin="anonymous"
                        ></img>
                      </div>
                      <div className="gap-4 mb-4">
                        <div className="flex">
                          <div>
                            <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                              Working hour
                            </h6>
                            <p className="text-black text-sm font-medium tracking-wide my-1">
                              {serviceInfo.bookingworkingHour}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-3 my-3">
                          <div className="">
                            <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                              Estimat Cost
                            </h6>
                            <p className="font-medium text-sm tracking-wide my-1">
                              £{Number(priceData?.finalPrice)?.toFixed(2) || 0}
                            </p>
                          </div>
                          <div className="">
                            <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                              Service Charge ({priceData?.admin_charge}%)
                            </h6>
                            <p className="font-medium text-sm tracking-wide my-1">
                              £
                              {calculatePercentage(
                                priceData?.admin_charge,
                                Number(priceData?.finalPrice)
                              )}
                            </p>
                          </div>
                          <div className="">
                            <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                              Total Amount
                            </h6>
                            <p className="text-success text-sm font-medium tracking-wide my-1">
                              £{totalCount || 0}
                            </p>
                          </div>
                          <div className="">
                            <h6 className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                              Payment Status
                            </h6>
                            {serviceInfo?.paymentStatus === "pending" ? (
                              <p className="text-pending text-sm font-medium tracking-wide my-1 capitalize">
                                {serviceInfo?.paymentStatus || "-"}
                              </p>
                            ) : (
                              <p className="text-success text-sm font-medium tracking-wide my-1 capitalize">
                                Paid
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-end items-center">
                          {serviceInfo?.paymentStatus === "pending" ? (
                            <Button
                              variant="filled"
                              className="bg-primary capitalize 2xl:px-16 xl:px-16 lg:px-16 px-10 font-normal text-sm flex justify-center items-center"
                              onClick={(e) => handleCheckout(e)}
                            >
                              Make a Payment
                            </Button>
                          ) : (
                            <>
                              {serviceInfo.bookingto.isFavouriteByMe !== 1 ? (
                                <Button
                                  variant="outlined"
                                  color="indigo"
                                  className="capitalize 2xl:px-10 xl:px-10 lg:px-10 px-4 font-normal 2xl:text-sm xl:text-sm lg:text-sm md:text-sm text-xs"
                                  onClick={(e) => handleAddToFavourite()}
                                >
                                  Add to favorite
                                </Button>
                              ) : (
                                ""
                              )}
                              {!serviceInfo?.review?.length ? (
                                <Button
                                  variant="outlined"
                                  color="indigo"
                                  className="ml-3 capitalize 2xl:px-10 xl:px-10 lg:px-10 px-4 font-normal 2xl:text-sm xl:text-sm lg:text-sm md:text-sm text-xs"
                                  onClick={(e) => setReviewModal(true)}
                                >
                                  Write a Review
                                </Button>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {serviceInfo?.review?.length ? (
                        <>
                          <hr />
                          <div className="my-4">
                            <h6 className="font-medium text-sm text-black my-2">
                              My Review
                            </h6>
                            <div className="grid grid-cols-12 gap-6 mt-3">
                              {serviceInfo?.review &&
                                serviceInfo?.review?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6"
                                    >
                                      <Card
                                        color="transparent"
                                        shadow={false}
                                        className="p-4 border border-[#D8D8D8] rounded-lg"
                                      >
                                        <CardHeader
                                          color="transparent"
                                          floated={false}
                                          shadow={false}
                                          className="mx-0 flex items-center gap-4 mt-0"
                                        >
                                          <Avatar
                                            size="md"
                                            variant="circular"
                                            src={
                                              serviceInfo.bookingby.profilePic
                                                ? IMAGE_URL +
                                                  serviceInfo.bookingby
                                                    .profilePic
                                                : noImage
                                            }
                                            alt="userprofile"
                                            crossOrigin="anonymous"
                                          />
                                          <div className="flex w-full flex-col gap-0.5">
                                            <div className="flex items-center justify-between">
                                              <Typography
                                                variant="h5"
                                                color="blue-gray"
                                                className="font-medium"
                                              >
                                                {`${serviceInfo?.bookingby?.firstName} ${serviceInfo?.bookingby?.lastName}`}
                                              </Typography>
                                            </div>
                                          </div>
                                        </CardHeader>
                                        <CardBody className="p-0 my-3">
                                          <div className="flex items-center mb-2">
                                            <div className=" flex items-center gap-0">
                                              {[1, 2, 3, 4, 5].map((star) => (
                                                <span
                                                  key={star}
                                                  className="cursor-pointer"
                                                  style={{
                                                    cursor: "pointer",
                                                    color:
                                                      item.reviewStar >= star
                                                        ? "#FFC107"
                                                        : "#D7D7D7",
                                                    fontSize: "25px",
                                                    padding: "0px",
                                                  }}
                                                >
                                                  {" "}
                                                  ★{" "}
                                                </span>
                                              ))}
                                            </div>
                                            <p className="text-black ml-2">
                                              {Number(item.reviewStar).toFixed(
                                                1
                                              )}
                                            </p>
                                          </div>
                                          <Typography className="text-black font-normal">
                                            {item.reviewText || "-"}
                                          </Typography>
                                        </CardBody>
                                      </Card>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : serviceInfo.bookingStatus === 3 ? (
                <div className="my-6">
                  <div className="">
                    <p className="text-[#757575] text-xs font-medium my-0.5 tracking-wide">
                      Image of job
                    </p>
                    <div className="my-4">
                      <img
                        src={IMAGE_URL + serviceInfo?.workingImage}
                        alt=""
                        className="w-[83px] h-[83px] rounded-md"
                        crossOrigin="anonymous"
                      ></img>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>

      {/* tip modal  */}
      <Transition appear show={tipModal} as={Fragment} className="">
        <Dialog as="div" className="relative z-10" onClose={handleCloseTip}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all">
                  <div className="p-4">
                    <div className="flex justify-between items-center">
                      <h5 className="text-primary font-medium text-lg">
                        Add a Tip
                      </h5>
                      <IconButton
                        color="blue-gray"
                        size="sm"
                        variant="text"
                        onClick={handleCloseTip}
                      >
                        <X />
                      </IconButton>
                    </div>
                    <form onSubmit={(e) => handleCreateIntent(e)}>
                      <div className="mt-6">
                        <label
                          className="leading-6 text-gray-900 text-sm font-medium mb-2"
                          htmlFor="regular-form-1"
                        >
                          Tip
                        </label>
                        <div className="mt-1">
                          <input
                            id="regular-form-1"
                            name="requiredJob"
                            type="number"
                            placeholder="Enter Amount"
                            className="focus:outline-none py-3 px-4 block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                            onChange={(e) => setTipAmount(e.target.value)}
                            required
                          ></input>
                        </div>
                      </div>
                      <div className="flex justify-end items-center gap-3 mt-6">
                        <Button
                          variant="outlined"
                          color="red"
                          onClick={(e) => handleCreateIntent(e)}
                          className="capitalize font-normal text-base px-10 py-2"
                        >
                          Skip
                        </Button>
                        <Button
                          variant="filled"
                          color="indigo"
                          className="capitalize font-normal text-base flex justify-center items-center px-10 py-2"
                          type="submit"
                        >
                          Next{" "}
                          {/* {isPaymentIntent ? (
                            <Spinner className="h-4 w-4 ml-2" />
                          ) : (
                            ""
                          )} */}
                        </Button>
                      </div>
                    </form>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={open} as={Fragment} className="">
        <Dialog as="div" className="relative z-10" onClose={handleOpen}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-end p-1">
                    <IconButton
                      color="blue-gray"
                      size="sm"
                      variant="text"
                      onClick={handleOpen}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-5 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </IconButton>
                  </div>
                  <div className="p-4">
                    <div className="pb-6 pt-4">
                      <h5 className="text-primary font-medium text-center text-lg">
                        Are you sure want to cancel this booking?
                      </h5>
                    </div>
                    <div className="flex justify-center items-center py-5">
                      <Button
                        variant="outlined"
                        color="red"
                        onClick={handleOpen}
                        className="mr-1 capitalize font-normal py-2.5 px-8"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="filled"
                        color="indigo"
                        onClick={handleCancel}
                        className="capitalize font-normal px-10 ml-3 py-2.5"
                      >
                        Yes
                      </Button>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={cartModal} as={Fragment} className="">
        <Dialog as="div" className="relative z-10" onClose={openCartModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-center">
                    <h4>Complete Your Payment</h4>
                    <X
                      color="#bdbdbd"
                      size={18}
                      className="cursor-pointer"
                      onClick={(e) => closeCartModal()}
                    />
                  </div>
                  <div className="p-3">
                    <StripeProvider>
                      <CheckoutForm intentId={intentId} />
                    </StripeProvider>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={successModal} as={Fragment} className="">
        <Dialog
          as="div"
          className="relative z-10"
          onClose={handleOpenSuccessModal}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-end items-end p-4">
                    <div
                      className="flex cursor-pointer"
                      onClick={(e) => handleCloseSuccessModal()}
                    >
                      <MdClose color="#bdbdbd" size={18} />
                    </div>
                  </div>
                  <div className="p-6">
                    <div className="my-2 flex justify-center items-center">
                      <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                      />
                    </div>
                    <div className="my-2">
                      <h6 className="text-primary font-medium text-center text-xl">
                        Payment
                      </h6>
                      <div className="my-2">
                        <h3 className="text-primary font-medium text-center text-4xl">
                          Successful!
                        </h3>
                      </div>
                    </div>
                    <div className="flex justify-center items-center py-5">
                      <Button
                        className="text-sm py-3 rounded-xl w-[70%] bg-primary capitalize font-normal"
                        onClick={(e) => {
                          handleCloseSuccessModal(e);
                          navigate("/mybooking");
                        }}
                      >
                        Done
                      </Button>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={reviewModal} as={Fragment} className="">
        <Dialog
          as="div"
          className="relative z-10"
          onClose={handleCloseReviewModal}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all">
                  <div
                    className="flex justify-end items-end cursor-pointer p-2"
                    onClick={(e) => handleCloseReviewModal()}
                  >
                    <MdClose color="#bdbdbd" size={18} />
                  </div>
                  <form onSubmit={formik.handleSubmit} className="p-6">
                    <div className="mb-2 text-center">
                      <h5 className="font-medium text-black text-center text-xl">
                        Give a review to the service
                      </h5>
                      <div className="py-2">
                        <h6 className="text-base text-black">
                          Please rate your experience with the fixer
                        </h6>
                      </div>
                      <div className="py-2">
                        <StarRating
                          value={formik.values.rating}
                          onChange={(value) =>
                            formik.setFieldValue("rating", value)
                          }
                        />
                        {formik.errors.rating && formik.touched.rating && (
                          <div style={{ color: "red" }}>
                            {formik.errors.rating}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-3">
                      <label
                        className="block font-medium leading-6 text-gray-900 mb-2"
                        htmlFor="regular-form-1"
                      >
                        Write a Review
                      </label>
                      <textarea
                        id="regular-form-1"
                        name="reviewText"
                        type="text"
                        placeholder="Type here..."
                        rows="6"
                        className="focus:outline-none py-3 px-4 block min-w-full xl:min-w-[350px] w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#D9D9D9] placeholder:text-[#5A5A5A] placeholder:text-sm focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.reviewText}
                      ></textarea>
                      {formik.touched.reviewText && formik.errors.reviewText ? (
                        <div className="text-danger my-1 text-sm">
                          {formik.errors.reviewText}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex justify-center items-center py-5">
                      <Button
                        type="submit"
                        className="flex items-center justify-center text-sm py-3 rounded-xl w-[70%] bg-primary capitalize font-normal"
                        disabled={loader}
                      >
                        <span>Save</span>{" "}
                        {loader ? <Spinner className="h-4 w-4 ml-2" /> : ""}
                      </Button>
                    </div>
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={openPaymentModal} as={Fragment} className="">
        <Dialog
          as="div"
          className="relative z-10"
          onClose={handleOpenPaymentModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div
                    className="flex justify-end items-end cursor-pointer"
                    onClick={(e) => {
                      handleClosePaymentModal();
                      navigate("/mybooking");
                    }}
                  >
                    <MdClose color="#bdbdbd" size={18} />
                  </div>
                  <div className="my-2 flex justify-center items-center">
                    <Lottie options={defaultOptions} height={150} width={150} />
                  </div>
                  <div className="my-2">
                    <h6 className="text-primary font-medium text-center text-xl">
                      Payment
                    </h6>
                    <div className="my-2">
                      <h3 className="text-primary font-medium text-center text-4xl">
                        Successful!
                      </h3>
                    </div>
                  </div>
                  <div className="flex justify-center items-center py-5">
                    <Button
                      className="text-sm py-3 rounded-xl w-[70%] bg-primary capitalize font-normal"
                      onClick={(e) => handleSuccessPayment(e)}
                    >
                      Done
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={paymentFailedModal} as={Fragment} className="">
        <Dialog
          as="div"
          className="relative z-10"
          onClose={handleCloseFailedModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div
                    className="flex justify-end items-end cursor-pointer"
                    onClick={(e) => handleCloseFailedModal()}
                  >
                    <MdClose color="#bdbdbd" size={18} />
                  </div>
                  <div className="my-2 flex justify-center items-center">
                    <Lottie
                      options={paymentFailedOptions}
                      height={150}
                      width={150}
                    />
                  </div>
                  <div className="my-2">
                    <h6 className="text-primary font-medium text-center text-xl">
                      Payment
                    </h6>
                    <div className="my-2">
                      <h3 className="text-primary font-medium text-center text-4xl">
                        Failed!
                      </h3>
                    </div>
                  </div>
                  <div className="flex justify-center items-center py-5">
                    <Button
                      className="text-sm py-3 rounded-xl w-[70%] bg-primary capitalize font-normal"
                      onClick={(e) => {
                        handleCloseFailedModal();
                        closeCartModal();
                      }}
                    >
                      Okay
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default BookingDetailView;
