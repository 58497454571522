import React from "react";
import whyChoose from "../../assets/images/home/why-choose-img.png";
import img1 from "../../assets/images/home/img1.png";
import img2 from "../../assets/images/home/img2.png";
import img3 from "../../assets/images/home/img3.png";
import img4 from "../../assets/images/home/img4.png";
import icon1 from "../../assets/images/home/mobile.png";
import icon2 from "../../assets/images/home/client.png";
import icon3 from "../../assets/images/home/users.png";

const WhyChooseUs = () => {
  const statsData = [
    {
      imgSrc: icon1,
      label: "Total Orders",
      value: "2018002",
    },
    {
      imgSrc: icon2,
      label: "Active Clients",
      value: "718002",
    },
    {
      imgSrc: icon3,
      label: "Team Members",
      value: "21089",
    },
  ];

  return (
    <>
      <section className="mt-6 bg-[#0C4AD8] text-white">
        <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
          <div className="grid grid-cols-12 2xl:gap-x-6 xl:gap-x-6 gap-x-3 h-full">
            <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 my-6 h-auto 2xl:mx-0 xl:mx-0 lg:mx-0 md:mx-0 mx-4">
              <div className="flex items-center justify-center h-full">
                <div>
                  <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl  md:text-2xl text-xl font-medium">
                    Why Choose Us
                  </h2>
                  <div className="grid grid-cols-12 2xl:gap-x-6 xl:gap-4 gap-x-3 2xl:gap-y-8 xl:gap-y-8 lg:gap-y-8 gap-y-5 mt-8">
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-12 md:col-span-12">
                      <div className="flex gap-2">
                        <img
                          src={img1}
                          alt=""
                          className="2xl:h-24 xl:h-20 lg:h-20 h-16"
                        ></img>
                        <div>
                          <h5 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg">
                            ID-checked Fixers
                          </h5>
                          <h6 className="mt-1 text-sm font-normal">
                            Fixers are always identity-checked before joining
                            the platform
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-12 md:col-span-12">
                      <div className="flex gap-2">
                        <img
                          src={img2}
                          alt=""
                          className="2xl:h-24 xl:h-20 lg:h-20 h-16"
                        ></img>
                        <div>
                          <h5 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg">
                            Dedicated Support
                          </h5>
                          <h6 className="mt-1 text-sm font-normal">
                            Friendly service when you need us every day of the
                            week.
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-12 md:col-span-12">
                      <div className="flex gap-2">
                        <img
                          src={img3}
                          alt=""
                          className="2xl:h-24 xl:h-20 lg:h-20 h-16"
                        ></img>
                        <div>
                          <h5 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg">
                            Same-Day Service
                          </h5>
                          <h6 className="mt-1 text-sm font-normal">
                            Fixers are always identitychecked before joining the
                            platform.
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-12 md:col-span-12">
                      <div className="flex gap-2">
                        <img
                          src={img4}
                          alt=""
                          className="2xl:h-24 xl:h-20 lg:h-20 h-16"
                        ></img>
                        <div>
                          <h5 className="font-medium 2xl:text-lg xl:text-lg lg:text-lg">
                            Health & Safety Assured
                          </h5>
                          <h6 className="mt-1 text-sm font-normal">
                            Fixers are always identitychecked before joining the
                            platform.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6">
              <img
                className="w-full 2xl:h-[550px] xl:h-[550px] lg:h-[550px] md:h-[500px] h-[400px] object-cover object-center"
                src={whyChoose}
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="2xl:py-24 xl:py-20 lg:py-16 md:py-14 py-10 bg-[#20448C]">
        <div className="container 2xl:px-7 xl:px-7 lg:px-7 px-5">
          <div className="flex 2xl:flex-nowrap xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center justify-between gap-y-8 gap-x-4">
            {statsData.map((stat, index) => (
              <div
                className="2xl:mx-0 xl:mx-0 lg:mx-0 md:mx-0 mx-[20%]"
                key={index}
              >
                <div className="flex items-center gap-x-6">
                  <img
                    src={stat.imgSrc}
                    alt={stat.label}
                    className="2xl:h-20 2xl:w-20 xl:h-20 xl:w-auto lg:h-18 lg:w-18 w-16"
                  />
                  <div className="text-white 2xl:text-center xl:text-center lg:text-center md:text-center text-start">
                    <h4 className="2xl:font-bold xl:font-bold lg:font-bold md:font-semibold font-medium 2xl:text-[40px] xl:text-[36px] lg:text-4xl md:text-2xl text-lg tracking-wider">
                      {stat.value}
                    </h4>
                    <div className="flex items-center 2xl:justify-center xl:justify-center lg:justify-center md:justify-center 2xl:my-4 xl:my-4 lg:my-3 my-2">
                      <p className="border-b border-white 2xl:w-14 xl:w-12 lg:w-12 md:w-12 w-12"></p>
                    </div>
                    <h6 className="2xl:font-semibold xl:font-semibold lg:font-semibold md:font-medium sm:font-medium font-light 2xl:text-[28px] xl:text-[24px] lg:text-[20px] md:text-base text-sm mt-2">
                      {stat.label}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUs;
